import React from "react";
import {
  Typography,
  TextField,
  Box,
  Chip,
  List,
  ListItem,
  Container,
} from "@mui/material";
import ChosenDataDisplay from "./ChosenDataDisplay";
import PersonIcon from "@mui/icons-material/Person";
import NoContactsError from "./NoContactsError";
import CardElement from "./CardElement";
import { useTranslation } from "react-i18next";

interface Props {
  projectName: string;
  inputError: boolean;
  suggestedProjectNames: string[];
  setProjectName: (projectName: string) => void;
  selectedContacts: string[];
  removeAssignee: (contact: string) => void;
  handleOnAssignContactsClicked: () => void;
  setInputError: (inputError: boolean) => void;
}

const ProjectNameStep = ({
  projectName,
  inputError,
  suggestedProjectNames,
  setProjectName,
  selectedContacts,
  removeAssignee,
  handleOnAssignContactsClicked,
  setInputError,
}: Props) => {
  const { t } = useTranslation();

  const hasProjectNameError = () => inputError || !projectName.trim();

  const getHelperText = () => {
    if (hasProjectNameError()) {
      if (!projectName.trim()) {
        return (
          <span data-testid="projectNameInputErrorText">
            {t("start_project.project_name_step.empty_name_error")}
          </span>
        );
      } else {
        return (
          <span data-testid="projectNameInputErrorText">
            {t("start_project.project_name_step.name_exists_error")}
          </span>
        );
      }
    }
    return "";
  };

  return (
    <>
      <CardElement headline={t("start_project.project_name_step.headline")}>
        <TextField
          placeholder={t("start_project.project_name_step.placeholder")}
          value={projectName || ""}
          error={hasProjectNameError()}
          helperText={getHelperText()}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setProjectName(event.target.value);
            setInputError(false);
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            const cleanedProjectName = event.target.value
              .replace(/\s+/g, " ")
              .trim();
            setProjectName(cleanedProjectName);
          }}
          sx={{ mb: 2, paddingTop: 1, width: "100%" }}
          autoFocus={true}
          data-testid="projectNameInput"
        />
        <Typography variant="subtitle2">
          {t("start_project.project_name_step.subtitle")}
        </Typography>
        <Box
          sx={{
            paddingTop: 1,
            overflow: "auto",
          }}
        >
          {suggestedProjectNames.map((suggestedProjectName) => (
            <Chip
              label={suggestedProjectName}
              onClick={() => {
                setProjectName(suggestedProjectName);
                setInputError(false);
              }}
              sx={{ mr: 1, mb: 1 }}
              clickable
              key={suggestedProjectName}
            />
          ))}
        </Box>
      </CardElement>
      <CardElement
        headline={t("start_project.project_name_step.selected_contacts")}
      >
        {selectedContacts.length === 0 && (
          <NoContactsError onButtonClick={handleOnAssignContactsClicked} />
        )}
        <List
          sx={{
            overflowY: "auto",
            backgroundColor: "background.paper",
            maxHeight: "300px",
            marginTop: "10px !important",
          }}
          disablePadding
        >
          {selectedContacts.map((assignee) => (
            <ListItem sx={{ width: "100%" }} disablePadding key={assignee}>
              <Container sx={{ pb: 1 }} maxWidth={false} disableGutters>
                <ChosenDataDisplay
                  onClick={() => removeAssignee(assignee)}
                  icon={<PersonIcon />}
                  primaryText={assignee}
                  secondaryText={t(
                    "start_project.project_name_step.selected_contact",
                  )}
                />
              </Container>
            </ListItem>
          ))}
        </List>
      </CardElement>
    </>
  );
};

export default ProjectNameStep;
