import React, { useState } from "react";
import {
  alpha,
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import ShareIcon from "@mui/icons-material/Share";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ShareDialog from "./ShareDialog";
import { useTranslation } from "react-i18next";
import { Icon } from "@fluentui/react";
import CreatePresentationDialog from "./CreatePresentationDialog";
import useOrganisatorId from "../hooks/useOrganisatorId";

interface ProjectListItemProps {
  id: string;
  name: string;
  organisatorId: string;
  slides: Slide[];
  visibility: "public" | "private";
  testId?: string;
}

interface Slide {
  title: string;
  name: string;
}

const ProjectListItem = (props: ProjectListItemProps) => {
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [openShareDialogUrl, setOpenShareDialogUrl] = useState("");
  const [templateDialog, setTemplateDialog] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const userId = useOrganisatorId();

  const { t } = useTranslation();
  const host = window.location.protocol + "//" + window.location.host;

  const visibilityIcon = (IconComponent: any, testId: string) => (
    <IconComponent
      data-testid={testId}
      sx={{
        color: "grey",
        fontSize: "1em",
        position: "relative",
        top: "0.2em",
        right: "-0.5em",
        width: "1.5em",
      }}
    />
  );

  function openDialog(url: string) {
    setOpenShareDialogUrl(url);
    setOpenShareDialog(true);
  }

  function isAssignedToProject() {
    if (!props) {
      return false;
    }

    return props.organisatorId !== userId && userId !== undefined;
  }

  return (
    <Box>
      <ListItem
        data-testid={props.testId}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.3)",
          backgroundColor: (theme) => alpha(theme.palette.background.paper, 1),
          mb: 1,
        }}
        secondaryAction={
          <>
            <Tooltip title={t("my_projects.create_presentation")}>
              <IconButton
                color="primary"
                onClick={() => {
                  setTemplateDialog(true);
                  setClicked(true);
                }}
              >
                <Icon iconName="PowerPointLogo" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("my_projects.share")}>
              <IconButton
                color="primary"
                onClick={() => openDialog(encodeURIComponent(props.name))}
              >
                <ShareIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            {props.visibility === "private"
              ? visibilityIcon(LockIcon, "privateIcon")
              : visibilityIcon(LockOpenIcon, "publicIcon")}
          </>
        }
        disablePadding
      >
        <ListItemButton>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isAssignedToProject() && (
              <Typography
                variant="caption"
                style={{
                  fontSize: "0.5em",
                  color: "grey",
                  marginBottom: 0,
                }}
              >
                {t("my_projects.shared")}
              </Typography>
            )}

            <Link
              to={`/assigneeProjectView?id=${props.id}`}
              style={{
                textDecoration: "none",
                color: "inherit",
                display: "flex",
                width: "100%",
                height: "100%",
              }}
            >
              <ListItemText sx={{ maxWidth: "85%", wordBreak: "break-all" }}>
                {props ? props.name : ""}
              </ListItemText>
            </Link>
          </Box>
        </ListItemButton>
      </ListItem>
      <CreatePresentationDialog
        open={templateDialog}
        clicked={clicked}
        slides={props.slides}
        onClose={() => {
          setTemplateDialog(false);
          setClicked(false);
        }}
        projectId={props.id}
        projectName={props.name}
      />
      <ShareDialog
        open={openShareDialog}
        onClose={() => setOpenShareDialog(false)}
        url={`${host}/assigneeProjectView?name=${openShareDialogUrl}&organisatorId=${props.organisatorId}`}
      ></ShareDialog>
    </Box>
  );
};

export default ProjectListItem;
