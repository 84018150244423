import React, { FormEvent, useEffect, useRef } from "react";
import { useEnterSubmit } from "../hooks/use-enter-submit";
import { Button } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { UseChatHelpers } from "../../core/types/ai-function";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./ui-components/loading-spinner";
import "./prompt-form.css";

export interface PromptProps
  extends Pick<UseChatHelpers, "input" | "setInput"> {
  onSubmit: (value: string) => Promise<void>;
  isLoading: boolean;
  styles?: "classic" | "for_dialog" | "for_feature_request";
  message?: string;
  isDefaultMessageSendInstantly?: boolean;
  setIsDefaultMessageSendInstantly?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setMessage?: (value: string) => void;
  setCounter?: React.Dispatch<React.SetStateAction<number>>;
  onRespond?: (step: number, success: boolean) => void;
  messageCount?: number;
  setInputsSendCount?: React.Dispatch<React.SetStateAction<number>>;
  readOnly?: boolean;
}

export function PromptForm({
  onSubmit,
  input,
  setInput,
  isLoading,
  styles,
  message,
  isDefaultMessageSendInstantly,
  setIsDefaultMessageSendInstantly,
  setMessage,
  onRespond,
  messageCount,
  setInputsSendCount,
  readOnly,
}: PromptProps) {
  const { formRef, onKeyDown } = useEnterSubmit();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (isDefaultMessageSendInstantly) {
      submitAsyncFunc();
    }
  }, []);

  async function submitAsyncFunc() {
    let submitValue = input;

    if (message && message.trim().length > 0) {
      submitValue = message;
    } else if (input?.trim().length === 0) {
      return;
    }

    setInput("");
    if (setMessage) setMessage("");

    try {
      await onSubmit(submitValue);
      if (setInputsSendCount) {
        setInputsSendCount((prev) => prev + 1);
        if (setIsDefaultMessageSendInstantly) {
          setIsDefaultMessageSendInstantly(false);
        }
      }
      if (onRespond && messageCount !== undefined) {
        onRespond(messageCount, true);
      }
    } catch (error) {
      if (onRespond && messageCount !== undefined) {
        onRespond(messageCount, false);
      }
    }
  }

  async function onSubmitAsyncFunc(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    await submitAsyncFunc();
  }

  useEffect(() => {
    if (inputRef.current != null) {
      inputRef.current.focus();
    }
  }, []);

  function getStyles() {
    switch (styles) {
      case "classic":
        return "classic";
      case "for_feature_request":
        return "for_feature_request";
      case "for_dialog":
        return "dialog";
    }
  }

  return (
    <div className={"copilot-prompt-form"}>
      <form onSubmit={onSubmitAsyncFunc} ref={formRef}>
        <div className={getStyles()}>
          <TextareaAutosize
            data-testid="copilotSidebarInput"
            ref={inputRef}
            readOnly={readOnly}
            maxRows={5}
            minRows={1}
            onKeyDown={onKeyDown}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            placeholder={t("copilot.sidebar.input_placeholder")}
            value={
              message !== undefined && message.trim().length > 0
                ? message
                : input
            }
            spellCheck={false}
            className={"copilot-prompt-textarea"}
          />
          <Button
            data-testid="copilotSidebarSubmit"
            type="submit"
            disabled={
              readOnly || ((isLoading || input === "") && message === undefined)
            }
            style={{
              minWidth: "39px",
              bottom: 1,
              paddingLeft: 11,
              paddingBottom: 5,
            }}
          >
            {isLoading ? <LoadingSpinner /> : <SendRoundedIcon />}
          </Button>
        </div>
      </form>
    </div>
  );
}
