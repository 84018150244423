import { useMsal } from "@azure/msal-react";
import { backendScope } from "../AuthModule";
import { io, Socket } from "socket.io-client";
import { useEffect } from "react";
import SocketEvents from "./SocketEvents";

const Websocket = () => {
  const { onConnect, onDisconnect, onPresentationEvent } = SocketEvents();
  const { instance } = useMsal();

  const getToken = async () => {
    const currentAccount = instance.getActiveAccount();
    if (!currentAccount) {
      throw new Error("No active account!");
    }
    const response = await instance.acquireTokenSilent({
      scopes: [backendScope],
      account: currentAccount,
    });
    return `Bearer ${response.accessToken}`;
  }

  const connectToSocket = async () => {
    const token = await getToken();
    return (io(process.env.REACT_APP_BACKEND_API!.replace('/api',''), {
      transports: ['websocket'],
      auth: {
        token: token
      }
    }));
  }

  useEffect(() => {
    let socket: Socket;

    connectToSocket().then((newSocket) => {
      socket = newSocket;
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on('presentation', onPresentationEvent);
    });

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('presentation', onPresentationEvent);
    };
  }, [])

  return null;
}

export default Websocket;
