export function isLocalAndAuthorizationDisabled(): boolean {
  let localAndAuthDisabled: boolean =
    process.env.REACT_APP_ENV === "local" &&
    process.env.REACT_APP_AUTH_DISABLED === "true";
  return localAndAuthDisabled;
}

export function getMockOrganistorId(): string {
  return "01234567-0123-0123-0123-012345678901";
}

export function getMockOrganisatorMail(): string {
  return "test@test.de";
}
