import React from "react";

function LoadingSpinner() {
  const keyframesStyle = `
    .loader {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: inline-block;
      border-top: 3px solid #22577a;
      border-right: 3px solid transparent;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
    
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    } 
    `;

  return (
    <div className="loader">
      <style>{keyframesStyle}</style>
    </div>
  );
}

export default LoadingSpinner;
