import { Task } from "../Utils/Types/Task";
import { Alert, Divider, Grow, List, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import TaskListItem from "./TaskListItem";
import Button from "@mui/material/Button";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import AuthenticatedTemplateWrapper from "./AuthenticatedTemplateWrapper";
import UnauthenticatedTemplateWrapper from "./UnauthenticatedTemplateWrapper";
import { useMakeCopilotActionable } from "../packages/copilotkit/core/hooks/use-make-copilot-actionable";
import { useMakeCopilotReadable } from "../packages/copilotkit/core/hooks/use-make-copilot-readable";
import { protectedResources } from "../AuthModule";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { Todo } from "../Utils/Types/Todo";

interface ProjectCreation {
  name: string;
  emails: string[];
  listType: "import" | "todo";
  tasks: Task[];
  visibility?: string;
}

const TaskList = (props: {
  tasks: Task[];
  tasksCollapsible: boolean;
  extended: boolean;
  readonly: boolean;
  showCkeckboxes: boolean;
  title?: string;
  visibility?: string;
}) => {
  const copilotPointer = useMakeCopilotReadable(props.title ?? "");

  const [tasksWithState, setTaskCollapseState] = useState(
    props.tasks.map((t) => ({ task: t, open: false })),
  );
  const [collapseState, setCollapseState] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    setTaskCollapseState((prevState) =>
      prevState.map((t) => ({ ...t, open: collapseState })),
    );
  }, [collapseState]);

  const updateIsListCollapsible = () => {
    // Tasks without content (i.e. notes or subtasks) cannot be expanded or collapsed anyways
    const tasksWithContent = tasksWithState.filter(
      (t) => t.task.note !== "" || t.task.checkListItems.length > 0,
    );
    const openTasksWithContent = tasksWithContent.filter((t) => t.open);
    const closedTasksWithContent = tasksWithContent.filter((t) => !t.open);

    if (tasksWithContent.length === openTasksWithContent.length) {
      setCollapseState(true);
    } else if (tasksWithContent.length === closedTasksWithContent.length) {
      setCollapseState(false);
    }
  };

  const handleTaskStateChange = (index: number, newState: boolean) => {
    setTaskCollapseState((prevState) => {
      const updatedTasks = [...prevState];
      updatedTasks[index].open = newState;
      return updatedTasks;
    });
  };

  function manageTaskMarking(
    tasksTitleToMark: string[],
    tasksTitleToUnmark: string[],
  ) {
    setTaskCollapseState((prevState) =>
      prevState.map((t) => {
        if (tasksTitleToMark.includes(t.task.title)) {
          t.task.isTitleMarked = true;
        } else if (tasksTitleToUnmark.includes(t.task.title)) {
          t.task.isTitleMarked = false;
        }

        return { ...t, task: t.task, open: collapseState };
      }),
    );
  }

  useMakeCopilotActionable(
    {
      name: "manageTasksMarking",
      description: "manage the marking of the provided tasks",
      argumentAnnotations: [
        {
          name: "tasksTitleToMark",
          type: "array",
          items: { type: "string" },
          description: "the titles of the tasks to mark",
          required: true,
        },
        {
          name: "tasksTitleToUnmark",
          type: "array",
          items: { type: "string" },
          description: "the titles of the tasks to unmark",
          required: true,
        },
      ],
      implementation: async (tasksTitleToMark = [], tasksTitleToUnmark = []) =>
        manageTaskMarking(tasksTitleToMark, tasksTitleToUnmark),
    },
    [],
  );

  type sortRule = "alphabetical" | "importance";

  function manageSortTasks(tasksSortRule: string) {
    if (tasksSortRule === "alphabetical") {
      const newSortTasks = tasksWithState
        .sort((a, b) => a.task.title.localeCompare(b.task.title))
        .map((t) => ({ task: t.task, open: t.open }));

      setTaskCollapseState(newSortTasks);
    } else if (tasksSortRule === "importance") {
      const importanceMapping: { [key: string]: number } = {
        high: 0,
        normal: 1,
        low: 2,
      };

      const importanceMapper = (importance: string): number => {
        return importanceMapping[importance];
      };

      const newSortTasks = tasksWithState
        .sort((a, b) => {
          return (
            importanceMapper(a.task.importance.toLowerCase()) -
            importanceMapper(b.task.importance.toLowerCase())
          );
        })
        .map((t) => ({ task: t.task, open: t.open }));

      setTaskCollapseState(newSortTasks);

      console.log(newSortTasks);
    } else {
      throw new Error("not Implemented");
    }
  }

  useMakeCopilotActionable(
    {
      name: "manageTasksSort",
      description: "manage the sorting of the provided tasks",
      argumentAnnotations: [
        {
          name: "tasksSortRule",
          type: "string",
          description:
            "ordering scheme for sorting. Valid options: alphabetical, importance",
          required: true,
        },
      ],
      implementation: async (tasksSortRule: sortRule = "alphabetical") =>
        manageSortTasks(tasksSortRule),
    },
    [],
  );

  return (
    <Stack data-testid="task-list">
      {props.tasksCollapsible && (
        <Stack
          justifyContent={"flex-end"}
          style={{ position: "absolute", right: 0, top: "-36px" }}
          direction={"row"}
        >
          <Button
            style={{ paddingBottom: "1px" }}
            onClick={() => setCollapseState(!collapseState)}
            title={collapseState ? "Einklappen" : "Ausklappen"}
          >
            {collapseState ? (
              <>
                <UnfoldLess /> {t("view_project.collapse")}
              </>
            ) : (
              <>
                <UnfoldMore /> {t("view_project.expand")}
              </>
            )}
          </Button>
        </Stack>
      )}
      <Grow in={true}>
        <List>
          {tasksWithState.length > 0 ? (
            tasksWithState.map((t, index) => (
              <React.Fragment key={index}>
                <TaskListItem
                  showCheckboxes={props.showCkeckboxes}
                  collapsible={props.tasksCollapsible}
                  readonly={true}
                  task={t.task}
                  open={t.open}
                  extended={props.extended}
                  parentCopilotPointer={copilotPointer}
                  updateIsListCollapsible={updateIsListCollapsible}
                  onTaskStateChange={(newState) =>
                    handleTaskStateChange(index, newState)
                  }
                />
                <Divider />
              </React.Fragment>
            ))
          ) : (
            <Alert severity="info">
              {t("start_project.task_list_step.alert")}
            </Alert>
          )}
        </List>
      </Grow>
    </Stack>
  );
};

export function TaskItem(props: {
  task: Task;
  tasksCollapsible: boolean;
  extended: boolean;
  readonly: boolean;
  showCkeckboxes: boolean;
  title?: string;
  visibility?: string;
  parentCopilotPointer?: string | undefined
}) {
  return <>
    <TaskListItem
      showCheckboxes={props.showCkeckboxes}
      collapsible={props.tasksCollapsible}
      readonly={true}
      task={props.task.task}
      open={props.task.open}
      extended={props.extended}
      parentCopilotPointer={props.parentCopilotPointer}
    />
    <Divider />
  </>
}

export default TaskList;
function execute(arg0: string, endpoint: string) {
  throw new Error("Function not implemented.");
}
