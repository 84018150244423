import Dialog from "@mui/material/Dialog";
import { DialogTitle, Paper } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import UrlCopyField from "./UrlCopyField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React from "react";
import { useTranslation } from "react-i18next";

const ShareDialog = (props: {
  open: boolean;
  onClose: () => void;
  url: string;
}) => {
  const { t } = useTranslation();
  // remove all whitespace and newlines from the url for it to be cleaner
  let cleanedUrl = props.url.replace(/[\s]+/g, '');

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle>{t("my_projects.share_dialog.share")}</DialogTitle>
      <DialogContent>
        <Paper sx={{ width: "100%" }} elevation={0}>
          
          <UrlCopyField url={cleanedUrl} />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>
          {t("my_projects.share_dialog.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;
