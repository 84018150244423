import {
  Box,
  Autocomplete,
  TextField,
  List,
  Grow,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import "./TaskListStep.css";
import React, { useEffect, useState } from "react";
import { Task } from "../Utils/Types/Task";
import CardElement from "./CardElement";
import { useTranslation } from "react-i18next";
import { TodoList } from "../Model/TodoList";
import { TaskListPreview } from "./TaskListPreview";

interface TaskListStepProps {
  selectedList: TodoList | null;
  handleListChange: (event: any, value: TodoList | null) => void;
  handleTasksChange: (event: any) => void;
  handleSwapItems: (currentPos: number, newPos: number) => void;
  todoLists: TodoList[];
  inputError: boolean;
  tasks: Task[];
  isTasksLoading: boolean;
  checked: boolean;
}

const TaskListStep = ({
  selectedList,
  handleListChange,
  handleTasksChange,
  handleSwapItems,
  todoLists,
  inputError,
  tasks,
  isTasksLoading,
  checked,
}: TaskListStepProps) => {
  const { t } = useTranslation();
  const [isTasksEmpty, setIsTasksEmpty] = useState<boolean>(tasks.length === 0);

  useEffect(() => {
    setIsTasksEmpty(tasks.length === 0);
  }, [tasks]);

  // Diese Funktion gibt den passenden CSS-Klassennamen für den Task-Status zurück
  function getStatusClassName(task: Task): string {
    return `status-${task.status.toLowerCase()}`;
  }

  return (
    <>
      <CardElement headline={t("start_project.task_list_step.headline")}>
        <Autocomplete
          id="input-tasklist"
          value={selectedList}
          onChange={handleListChange}
          options={todoLists ? todoLists : []}
          getOptionLabel={(list) => list.displayName}
          data-testid="tasklistAutocomplete"
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                ...params.InputLabelProps,
                children: undefined,
              }}
              variant="outlined"
              error={inputError}
              helperText={
                inputError ? (
                  <span data-testid="tasklistErrorText">
                    {t("start_project.task_list_step.helper_text")}
                  </span>
                ) : (
                  ""
                )
              }
              placeholder={t("start_project.task_list_step.placeholder")}
              className="autocomplete-input"
              autoFocus
            />
          )}
        />
      </CardElement>

      {selectedList && (
        <Grow in={true}>
          <Box>
            <CardElement
              headline={t(
                "start_project.task_list_step.selected_list_headline",
              )}
            >
              <List disablePadding>
                <div key={tasks.toString()}>
                  {isTasksLoading ? (
                    <Grow in={true}>
                      <CircularProgress />
                    </Grow>
                  ) : (
                    <>
                      {isTasksEmpty ? (
                        <></>
                      ) : (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={handleTasksChange}
                            />
                          }
                          label={t(
                            "start_project.task_list_step.only_checked_tasks",
                          )}
                        />
                      )}
                      <div
                        className={`task-list-wrapper ${
                          checked ? "only-not-started" : ""
                        }`}
                      >
                        <TaskListPreview
                          handleSwapItems={handleSwapItems}
                          classes={{ task: getStatusClassName }}
                          showCkeckboxes={false}
                          readonly={true}
                          tasksCollapsible={false}
                          tasks={tasks}
                          extended={false}
                        />
                      </div>
                    </>
                  )}
                </div>
              </List>
            </CardElement>
          </Box>
        </Grow>
      )}
    </>
  );
};

export function isFeatureEnabledDragAndDropNT() {
  return (
    (process.env.REACT_APP_ENABLE_DRAG_DROP_NT ?? "").toLowerCase() === "true"
  );
}
export function isFeatureEnabledMockFetch() {
  return (
    (process.env.REACT_APP_ENABLE_MOCKED_FETCH ?? "").toLowerCase() === "true"
  );
}

export default TaskListStep;
