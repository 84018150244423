import { Box, Slide, Typography } from "@mui/material";
import React, { useState } from "react";

interface TextCarouselProps {
  texts: string[];
}

const TextCarousel = (props: TextCarouselProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [randomNumber, setRandomNumber] = useState<number>(0);
  const containerRef = React.useRef(null);

  const generateRandomNumber = () => {
    setRandomNumber(Math.floor(Math.random() * props.texts.length));
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "50px",
        overflow: "hidden",
        backgroundColor: (theme) =>
          theme.palette.mode === "light" ? "grey.100" : "grey.900",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
      }}
      ref={containerRef}
    >
      <Slide
        in={open}
        direction="up"
        container={containerRef.current}
        onEntered={() => {
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }}
        onExited={() => {
          setTimeout(() => {
            generateRandomNumber();
            setOpen(true);
          }, 500);
        }}
      >
        <Typography>{props.texts[randomNumber]}</Typography>
      </Slide>
    </Box>
  );
};

export default TextCarousel;
