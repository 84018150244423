import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Container from "@mui/material/Container";
import CardComponent from "../Components/CardComponent";
import CardHeader from "../Components/CardHeader";
import { useTranslation } from "react-i18next";

const TutorialVideoPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [videoData, setVideoData] = useState({ key: "", url: "" });
  const [isFullScreen, setIsFullScreen] = useState(true);

  useEffect(() => {
    if (location.state) {
      const { key, url } = location.state;
      setVideoData({ key, url });
    }
  }, [location.state]);

  useEffect(() => {
    if (videoData.url) {
      const videoElement = document.querySelector("video");
      if (videoElement) {
        videoElement.load();
      }
    }
  }, [videoData.url]);

  const handleFullScreenToggle = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <Box
      sx={{
        position: isFullScreen ? "fixed" : "relative",
        top: isFullScreen ? 0 : "auto",
        left: isFullScreen ? 0 : "auto",
        width: isFullScreen ? "100%" : "auto",
        height: isFullScreen ? "100%" : "auto",
        backgroundColor: isFullScreen ? "black" : "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: isFullScreen ? 1300 : "auto", // to ensure it's above other elements
      }}
    >
      {isFullScreen && (
        <Button
          onClick={handleFullScreenToggle}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            color: "white",
            zIndex: 1400, // ensure button is above everything else
          }}
        >
          <CloseIcon />
        </Button>
      )}
      <Container maxWidth="md" sx={{ mt: 3 }}>
        <CardComponent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CardHeader
              headline={t(`header.menu.tutorials.${videoData.key}`)}
            />
            {!isFullScreen && (
              <IconButton onClick={handleFullScreenToggle} color="primary">
                <FullscreenIcon />
              </IconButton>
            )}
          </Box>
          <Box sx={{ mt: 2, position: "relative" }}>
            <video width="100%" controls>
              <source src={videoData.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </CardComponent>
      </Container>
    </Box>
  );
};

export default TutorialVideoPage;
