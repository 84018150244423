import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";

interface CardElementProps {
  headline: String;
  children: ReactNode;
}

const CardElement: React.FC<CardElementProps> = ({ children, headline }) => {
  return (
    <>
      <Box>
        <Typography
          variant="overline"
          style={{ fontWeight: "bold", color: "#22577a" }}
        >
          {headline}
        </Typography>
        {children}
      </Box>
    </>
  );
};

export default CardElement;
