import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    background: {
      default: "#e5e5e5",
    },
    primary: {
      main: "#22577a",
      light: "#4e7894",
      dark: "#173c55",
    },
    secondary: {
      main: "#57cc99",
      dark: "#3c8e6b",
      light: "#78d6ad",
    },
  },
  direction: "rtl",
});

export default theme;
