import { Box, Checkbox, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { ChecklistItem } from "../Utils/Types/Task";
import useTasksApi from "../Api/TasksApi";

const ChecklistItemCheckBox = (props: {
  checklistItem: ChecklistItem;
  showCheckboxes: boolean;
}) => {
  const { completeChecklistItem, openChecklistItem } = useTasksApi();

  const handleCheck = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    const checklistItem = props.checklistItem;
    if (checklistItem.isChecked) {
      checklistItem.isChecked = false;
      await openChecklistItem(props.checklistItem.id);
    } else {
      checklistItem.isChecked = true;
      await completeChecklistItem(props.checklistItem.id);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ListItemIcon>
        <Box>
          {props.showCheckboxes && props.checklistItem.isChecked ? (
            <Checkbox
              defaultChecked
              disableRipple
              onClick={(e) => handleCheck(e)}
            />
          ) : (
            <Checkbox disableRipple onClick={(e) => handleCheck(e)} />
          )}
        </Box>
      </ListItemIcon>
      <ListItemText>{props.checklistItem.displayName}</ListItemText>
    </div>
  );
};

export default ChecklistItemCheckBox;
