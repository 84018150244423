import React, { useEffect } from "react";
import AuthenticatedTemplateWrapper from "../Components/AuthenticatedTemplateWrapper";
import UnauthenticatedTemplateWrapper from "../Components/UnauthenticatedTemplateWrapper";
import Login from "./Login";
import MyProjectsView from "../views/MyProjectsView";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ViewMyProjects = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("header.menu.my_projects");
  }, [i18next.language]);

  return (
    <>
      <AuthenticatedTemplateWrapper>
        <MyProjectsView />
      </AuthenticatedTemplateWrapper>
      <UnauthenticatedTemplateWrapper>
        <Login />
      </UnauthenticatedTemplateWrapper>
    </>
  );
};

export default ViewMyProjects;
