import React, { ReactNode } from "react";
import { Stack, Typography } from "@mui/material";

interface CardHeaderProps {
  headline: String;
  children?: ReactNode;
}

const CardHeader: React.FC<CardHeaderProps> = (props) => {
  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h4">{props.headline}</Typography>
        {props.children}
      </Stack>
    </>
  );
};

export default CardHeader;
