import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../AuthModule";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { AccountInfo, InteractionType } from "@azure/msal-browser";

const useUsersApi = () => {
  const { instance } = useMsal();
  const msalResult = useMsalAuthentication(InteractionType.Redirect, {
    ...{ scopes: ["User.ReadBasic.All", "Tasks.ReadWrite"] },
    account: instance.getActiveAccount() as AccountInfo,
    redirectUri: "/redirect",
  });
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.apiUsers.scopes.read,
  });

  const getUsersFromGraph = async () => {
    const headers = new Headers();

    let token = msalResult.result?.accessToken;
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    let options = {
      headers: headers,
    };
    let response = await fetch(
      "https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'mey')",
      options,
    );
    return await response.json();
  };

  const getUsers = () =>
    execute("GET", `https://graph.microsoft.com/v1.0/users`);
  const getFilteredByDisplayNameUsers = (startsWith: string) =>
    execute(
      "GET",
      `https://graph.microsoft.com/v1.0/users?$filter=startswith(givenName, '${startsWith}') or startswith(surname, '${startsWith}') or startswith(mail, '${startsWith}')`,
    );

  return { getUsers, getUsersFromGraph, getFilteredByDisplayNameUsers };
};

export default useUsersApi;
