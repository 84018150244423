import React, {useEffect, useState} from "react";
import AuthenticatedTemplateWrapper from "../Components/AuthenticatedTemplateWrapper";
import UnauthenticatedTemplateWrapper from "../Components/UnauthenticatedTemplateWrapper";
import Login from "./Login";
import { useTranslation } from "react-i18next";
import {protectedResources} from "../AuthModule";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import CardComponent from "../Components/CardComponent";
import {
    CircularProgress,
    Grid,
    IconButton,
    Stack, Tooltip, Typography,
} from "@mui/material";
import CardHeader from "../Components/CardHeader";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from '@mui/icons-material/Download';
import DownloadingIcon from '@mui/icons-material/Downloading';
import PendingIcon from '@mui/icons-material/Pending';
import Container from "@mui/material/Container";

interface Presentation {
    presentationId: number,
    userId: string,
    displayName: string,
    createdDateTime: string,
    base64File?: string
}

const ViewMyPresentations = () => {

    const [presentations, setPresentations] = useState<Presentation[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDownloading, setIsDownloading] = useState<Map<number, boolean>>(new Map());
    const [isDeleting, setIsDeleting] = useState<Map<number, boolean>>(new Map());

    const { t } = useTranslation();

    const { execute } = useFetchWithMsal({
        scopes: protectedResources.apiProject.scopes.write,
    });

    useEffect(() => {
        setIsLoading(true);
        execute("GET", protectedResources.apiPresentations.endpoint).then(
            (response) => {
                if (response?.body !== undefined) {
                    setPresentations(response.body);
                }
                setIsLoading(false);
            }
        );
    },[execute]);

    const handleDownload = (id: number) => {
        setIsDownloading(isDownloading.set(id, true));
        execute("GET", `${protectedResources.apiPresentations.endpoint}?presentationId=${id}`).then(
            (response) => {
                if (response?.body !== undefined) {
                    const presentation = response.body as Presentation;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = `data:file/pptx;base64,${presentation.base64File}`;
                    downloadLink.download = `${presentation.displayName}.ppt`;
                    downloadLink.click();
                }
                setIsDownloading(isDownloading.set(id, false));
            }
        );
    }

    const handleDelete = (id: number) => {
        setIsDeleting(isDeleting.set(id, true));
        execute("DELETE", `${protectedResources.apiPresentations.endpoint}?presentationId=${id}`).then(
            (response) => {
                if (response?.status === 200) {
                    setPresentations(presentations.filter(presentation => presentation.presentationId !== id));
                }
                else {
                    console.log("Error deleting presentation");
                }
                setIsDeleting(isDeleting.set(id, false));
            }
        );
    }

    const LoadingPresentations = ()  => {
        return (
            <>
                <CircularProgress/>
                <Typography variant="h5">
                    {t("presentations.loading")}
                </Typography>
            </>
        )
    }

    const NoPresentations = () => {
        return (
            <>
                <Typography variant="h5">
                    {t("presentations.no_presentations_message")}
                </Typography>
            </>
        )
    }

    const Presentations = () => {
        return (
            <>
                <CardHeader headline={t("presentations.headline")}/>
                <Stack spacing={2}>
                    {presentations.map((presentation) => (
                        <Grid container alignItems="center"
                              sx={{
                                  boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.3)",
                                  pl: 1
                              }}>
                            <Grid item xs>
                                {presentation.displayName}
                            </Grid>
                            <Grid item xs>
                                {presentation.createdDateTime}
                            </Grid>
                            <Grid container item xs={2} justifyContent="flex-end">
                                <Tooltip title={t("presentations.download_button_text")}>
                                    <IconButton color="primary" onClick={() => handleDownload(presentation.presentationId)}>
                                        {isDownloading.get(presentation.presentationId) === true ? <DownloadingIcon fontSize="small"/> : <DownloadIcon fontSize="small"/>}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t("presentations.delete_button_text")}>
                                    <IconButton color="primary" onClick={() => handleDelete(presentation.presentationId)}>
                                        {isDeleting.get(presentation.presentationId) === true ? <PendingIcon fontSize="small"/> : <DeleteIcon fontSize="small"/>}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ))}
                </Stack>
            </>
        )
    }

    return (
      <>
          <AuthenticatedTemplateWrapper>
              <Container maxWidth="md" sx={{ mt: 3 }}>
                  <CardComponent testid="myPresentationsViewCard">
                      {isLoading ? <LoadingPresentations/> : presentations.length === 0 ? <NoPresentations/> : <Presentations/>}
                  </CardComponent>
              </Container>
          </AuthenticatedTemplateWrapper>
          <UnauthenticatedTemplateWrapper>
              <Login />
          </UnauthenticatedTemplateWrapper>
      </>
    );
};

export default ViewMyPresentations;
