import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { isLocalAndAuthorizationDisabled } from "./../Utils/environment";

const AuthenticatedTemplateWrapper: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return isLocalAndAuthorizationDisabled() ? (
    <>{children ?? ""}</>
  ) : (
    <AuthenticatedTemplate>{children ?? ""}</AuthenticatedTemplate>
  );
};

export default AuthenticatedTemplateWrapper;
