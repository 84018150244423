import { useContext, useMemo } from "react";
import { CopilotContext } from "../context/copilot-context";
import {
  ChatRequestOptions,
  CreateMessage,
  Message,
  UseChatOptions,
} from "../types/ai-function";
import { useMainChat } from "./use-main-chat";

export interface UseCopilotChatOptions extends UseChatOptions {
  makeSystemMessage?: (contextString: string) => string;
}

export interface UseCopilotChatReturn {
  visibleMessages: Message[];
  append: (
    message: Message | CreateMessage,
    chatRequestOptions?: ChatRequestOptions,
  ) => Promise<string | null | undefined>;
  reload: (
    chatRequestOptions?: ChatRequestOptions,
  ) => Promise<string | null | undefined>;
  stop: () => void;
  isLoading: boolean;
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
}

export function useMainCopilotChat({
  ...options
}: UseCopilotChatOptions): UseCopilotChatReturn {
  const {
    getChatCompletionFunctionDescriptions,
    getFunctionCallHandler,
  } = useContext(CopilotContext);
  const functionDescriptions = useMemo(() => {
    return getChatCompletionFunctionDescriptions();
  }, [getChatCompletionFunctionDescriptions]);

  const { messages, append, reload, stop, isLoading, input, setInput } =
    useMainChat({
      id: options.id,
      experimental_onFunctionCall: getFunctionCallHandler(),
      body: {
        id: options.id,
        previewToken,
        copilotkit_manually_passed_function_descriptions: functionDescriptions,
      },
    });

  const visibleMessages = messages.filter(
    (message) => message.role === "user" || message.role === "assistant",
  );

  return {
    visibleMessages,
    append,
    reload,
    stop,
    isLoading,
    input,
    setInput,
  };
}

const previewToken = "TODO123";
