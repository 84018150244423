import { ListItemIcon } from "@mui/material";
import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Task } from "../Utils/Types/Task";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

export function ItemContent({
  task,
  showCheckboxes,
  showExpandIcon,
  openNote,
  TaskCheckBoxComponent,
}: {
  showExpandIcon: boolean;
  openNote: boolean;
  showCheckboxes: boolean;
  TaskCheckBoxComponent: (props: {
    task: Task;
    showCheckboxes: boolean;
  }) => JSX.Element;
  task: Task;
}) {
  return (
    <>
      {!showCheckboxes && (
        <ListItemIcon>
          <AssignmentOutlinedIcon color="primary" />
        </ListItemIcon>
      )}
      <TaskCheckBoxComponent task={task} showCheckboxes={showCheckboxes} />
      {showExpandIcon && (openNote ? <ExpandLess /> : <ExpandMore />)}
    </>
  );
}
