import React, { type ReactNode, useCallback, useState } from "react";
import { CopilotSidebarContext } from "./sidebar-context";
import { CopilotSidebar } from "./copilot-sidebar";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import AuthenticatedTemplateWrapper from "../../../../Components/AuthenticatedTemplateWrapper";

export interface CopilotSidebarUIProviderProps {
  children: ReactNode;
  sidebarOpen: boolean;
  setSidebarOpen: (
    newState: boolean | ((prevState: boolean) => boolean),
  ) => void;
}

export function CopilotSidebarUIProvider({
  children,
  setSidebarOpen,
  sidebarOpen,
}: CopilotSidebarUIProviderProps) {
  const { t } = useTranslation();
  //const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = useCallback(() => {
    setSidebarOpen((prevState) => !prevState);
  }, []);

  return (
    <CopilotSidebarContext.Provider
      value={{ isSidebarOpen: sidebarOpen, toggleSidebar }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            position: "relative",
            width: sidebarOpen ? "50vw" : "100%",
            transform: sidebarOpen ? "translateX(-40%)" : "translateX(0)",
            transition: "transform 0.3s ease-in-out, width 0.5s",
          }}
        >
          <AuthenticatedTemplateWrapper>
            <Button
              style={{ position: "absolute", top: 0, right: 0, margin: 60 }}
              onClick={toggleSidebar}
            >
              {sidebarOpen
                ? t("copilot.sidebar.close_button")
                : t("copilot.sidebar.open_button")}
            </Button>
          </AuthenticatedTemplateWrapper>
          {children}
        </div>

        <AuthenticatedTemplateWrapper>
          <div
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              width: "40%",
              backgroundColor: "white",
              zIndex: 1,
              transform: sidebarOpen ? "translateX(0%)" : "translateX(100%)",
              transition: "transform 0.3s ease-in-out",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
              overflowX: "hidden",
            }}
          >
            <CopilotSidebar
              setSidebarOpen={toggleSidebar}
              sidebarOpen={sidebarOpen}
            />
          </div>
        </AuthenticatedTemplateWrapper>
      </div>
    </CopilotSidebarContext.Provider>
  );
}
