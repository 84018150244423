import React from "react";
import { Drawer, lighten } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CopilotChat } from "../chat-components/copilot-chat";

export interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}

export function CopilotSidebar(props: SidebarProps): JSX.Element {
  return (
    <div
      className="shadow-lg bg-gray-50 flex flex-col border-l-2"
      style={{ width: "100%", height: "100%" }}
    >
      <Drawer
        data-testid="copilotDrawer"
        variant="persistent"
        anchor="right"
        open={props.sidebarOpen}
        PaperProps={{
          sx: {
            backgroundColor: (theme) =>
              lighten(theme.palette.background.default, 0.6),
            width: "100%",
          },
        }}
        //#124 here
        ModalProps={{
          //disableScrollLock: true,
          keepMounted: true,
          onBackdropClick: () => props.setSidebarOpen(false),
        }}
        SlideProps={{ timeout: 0 }}
        slotProps={{ backdrop: { invisible: true } }}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            minWidth: "100%",
            boxSizing: "border-box",
            p: 5,
            pt: 15,
          },
        }}
      >
        <CloseIcon
          cursor={"pointer"}
          onClick={() => props.setSidebarOpen(false)}
        />
        <CopilotChat />
      </Drawer>
    </div>
  );
}
