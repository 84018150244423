import { useInView } from "react-intersection-observer";
import React, { useEffect } from "react";

interface ChatScrollAnchorProps {
  trackVisibility?: boolean;
}

export function ChatScrollAnchor({ trackVisibility }: ChatScrollAnchorProps) {
  const { ref, entry, inView } = useInView({
    trackVisibility,
    delay: 100,
    rootMargin: "0px 0px -50px 0px",
  });

  useEffect(() => {
    if (!trackVisibility && !inView) {
      entry?.target.scrollIntoView({
        block: "start",
        behavior: "smooth", //makes scrolling less janky
      });
    }
  }, [inView, entry, trackVisibility]);

  return <div ref={ref} data-testid="scroll-anchor" className="h-px w-full" />;
}
