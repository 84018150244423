import './chat-components.css';
import { ChatList } from "./chat-list";
import { type EmptyScreenProps } from "./default-empty-screen";
import React, { type FC } from "react";
import { UseCopilotChatOptions } from "../../core/hooks/use-copilot-chat";
import { ChatPanel } from "./chat-panel";
import { ChatScrollAnchor } from "./chat-scroll-anchor";
import { MainDefaultEmptyScreen } from "./main-default-empty-screen";
import { useMainCopilotChat } from "../../core/hooks/use-main-copilot-chat";
import { useMakeCopilotActionable } from "../../core/hooks/use-make-copilot-actionable";

interface ChatComponentInjectionsProps {
  EmptyScreen?: FC<EmptyScreenProps>;
}

interface CopilotChatProps
  extends UseCopilotChatOptions,
    ChatComponentInjectionsProps {}

export function MainCopilotChat({
  id,
  initialMessages,
  makeSystemMessage,
  EmptyScreen = MainDefaultEmptyScreen,
}: CopilotChatProps) {
  function stopDanielifySong() {
    if (document.getElementById("danify-stop-icon")) {
      document.getElementById("danify-play-btn")!.click();
    }
  }

  function playDanifySong() {
    const playButton = document.getElementById("danify-play-btn");
    let stopIcon = document.getElementById("danify-stop-icon");

    playButton!.click();

    function playAgain() {
      stopIcon = document.getElementById("danify-stop-icon");

      if (stopIcon) {
        setTimeout(playAgain, 500);
      } else {
        playButton!.click();
      }
    }
    if (stopIcon) {
      playAgain();
    }
  }

  useMakeCopilotActionable(
    {
      name: "playDanifySong",
      description: "play the Danify song",
      argumentAnnotations: [],
      implementation: async () => playDanifySong(),
    },
    [],
  );

  useMakeCopilotActionable(
    {
      name: "stopDanifySong",
      description: "stop the Danify song",
      argumentAnnotations: [],
      implementation: async () => stopDanielifySong(),
    },
    [],
  );

  const { visibleMessages, append, reload, stop, isLoading, input, setInput } =
    useMainCopilotChat({ id, initialMessages, makeSystemMessage });

  return (
    <div className="w-full h-full flex flex-col overflow-hidden box-border items-start">
      <div className="pt-5 px-5 overflow-y-auto overflow-x-hidden w-full flex-grow">
        {visibleMessages.length > 0 ? (
          <div className="pl-0 pr-6 copilot-chat-list">
            <ChatList messages={visibleMessages} />
            <ChatScrollAnchor trackVisibility={isLoading} />
          </div>
        ) : (
          <EmptyScreen setInput={setInput} />
        )}
      </div>

      <div
        className="flex-shrink-0 w-full copilot-chat-panel"
      >
        <ChatPanel
          id={id}
          isLoading={isLoading}
          stop={stop}
          append={append}
          reload={reload}
          messages={visibleMessages}
          input={input}
          setInput={setInput}
          styles={"classic"}
        />
      </div>
    </div>
  );
}
