import { Typography, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface ErrorMessageProps {
  onButtonClick: () => void;
}

const NoContactsError = ({ onButtonClick }: ErrorMessageProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        sx={{ color: "red", marginTop: "10px !important" }}
        variant="subtitle2"
        data-testid="errorText"
      >
        {t("contacts_step.contacts_select_error")}
      </Typography>
      <Button
        size="small"
        sx={{
          color: "black",
          alignSelf: "flex-start",
          marginTop: "10px !important",
          border: "1px solid gray",
          textTransform: "none",
        }}
        onClick={onButtonClick}
      >
        Kontakte zuweisen
      </Button>
    </>
  );
};

export default NoContactsError;
