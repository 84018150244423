import React, { useState, useEffect } from "react";
import { CheckCircle, ErrorOutline } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

interface PopupProps {
  status: boolean;
}

const Popup = ({ status }: PopupProps) => {
  const [saveMessage, setSaveMessage] = useState("");

  const { t } = useTranslation();

  let errorMessage = t("import.error_message");
  let successMessage = t("import.success_message");

  useEffect(() => {
    status ? setSaveMessage(successMessage) : setSaveMessage(errorMessage);
  }, [errorMessage, status, successMessage]);

  return (
    <div className="popup" color="green">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {status ? (
          <CheckCircle sx={{ marginRight: 1 }} />
        ) : (
          <ErrorOutline sx={{ marginRight: 1 }} />
        )}
        <p>{saveMessage}</p>
      </Box>
    </div>
  );
};

export default Popup;
