import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../AuthModule";

const useTasksApi = () => {
  let { execute } = useFetchWithMsal({
    scopes: protectedResources.apiTasklists.scopes.read,
  });

  const completeTask = (id: string) => {
    return execute(
      "PUT",
      `${protectedResources.apiTasks.endpoint}/tasks/${id}/complete`,
    );
  };
  const openTask = (id: string) => {
    return execute(
      "PUT",
      `${protectedResources.apiTasks.endpoint}/tasks/${id}/open`,
    );
  };

  const completeChecklistItem = (id: string) => {
    return execute(
      "PUT",
      `${protectedResources.apiTasks.endpoint}/checklistItems/${id}/complete`,
    );
  };

  const openChecklistItem = (id: string) => {
    return execute(
      "PUT",
      `${protectedResources.apiTasks.endpoint}/checklistItems/${id}/open`,
    );
  };

  return { completeTask, openTask, completeChecklistItem, openChecklistItem };
};

export default useTasksApi;
