import {
  AppBar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import NavigationMenu from "./NavigationMenu";
import UserMenu from "./UserMenu";
import DevInfoDialog from "./DevInfoDialog";
import EnviromentButton from "./EnviromentButton";
import AuthenticatedTemplateWrapper from "./AuthenticatedTemplateWrapper";
import UnauthenticatedTemplateWrapper from "./UnauthenticatedTemplateWrapper";
import UnauthenticatedUserMenu from "./UnauthenticatedUserMenu";
import TutorialMenu from "./TutorialMenu";
import CardButton from "./CardButton";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { useTranslation } from "react-i18next";
import navigateOrReload  from "../Utils/navigationUtils";

const Header = () => {
  const [isTutorialMenuOpen, setTutorialMenuOpen] = useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const { t } = useTranslation();

  const handleUserMenuClick = () => {
    setUserMenuOpen(!isUserMenuOpen);
    setTutorialMenuOpen(false);
  };
  const handleTutorialMenuClick = () => {
    setTutorialMenuOpen(!isTutorialMenuOpen);
    setUserMenuOpen(false);
  };
  const handleMenuClose = () => {
    setTutorialMenuOpen(false);
    setUserMenuOpen(false);
  };
  //DATA
  const navigate = useNavigate();
  return (
    <AppBar
      position="sticky"
      elevation={0}
      color="transparent"
      sx={{
        zIndex: 999,
        backgroundColor: "#ffffff",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        disableGutters
      >
        <EnviromentButton />

        <List sx={{ ml: 5, mr: 5 }}>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ height: "80px" }}
              onClick={() => {
                handleMenuClose();
                navigateOrReload(navigate, "/");
              }}
            >
              <ListItemAvatar>
                <ListAltIcon fontSize="large" />
              </ListItemAvatar>
              <ListItemText>
                <Typography variant="h4" sx={{ fontFamily: "monospace" }}>
                  Danify
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>

        {/* Navigation wird nur im Angeledeten Zustand angezeigt */}
        <AuthenticatedTemplateWrapper>
          <Divider orientation="vertical" flexItem />
          <NavigationMenu
            onClick={handleMenuClose}
            isSubMenuOpen={isTutorialMenuOpen || isUserMenuOpen}
          />
          <Stack>
            <CardButton
              title={t("header.menu.feature_request")}
              description={t("header.menu.feature_request_description")}
              icon={<ReviewsIcon sx={{ fontSize: 30 }} />}
              onClick={() => {
                if (isUserMenuOpen || isTutorialMenuOpen) handleMenuClose();
                navigateOrReload(navigate, "/featureRequest");
              }}
            />
          </Stack>
          <TutorialMenu
            isOpen={isTutorialMenuOpen}
            handleOnClick={handleTutorialMenuClick}
          />
          <DevInfoDialog />
          <Divider orientation="vertical" flexItem />
          <UserMenu
            isOpen={isUserMenuOpen}
            handleOnClick={handleUserMenuClick}
          />
        </AuthenticatedTemplateWrapper>

        <UnauthenticatedTemplateWrapper>
          <UnauthenticatedUserMenu />
        </UnauthenticatedTemplateWrapper>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
