import React from "react";
import { UnauthenticatedTemplate } from "@azure/msal-react";
import { isLocalAndAuthorizationDisabled } from "./../Utils/environment";

const UnauthenticatedTemplateWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return isLocalAndAuthorizationDisabled() ? (
    <div />
  ) : (
    <UnauthenticatedTemplate>{children}</UnauthenticatedTemplate>
  );
};

export default UnauthenticatedTemplateWrapper;
