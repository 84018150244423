import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Task } from "../Utils/Types/Task";
import ListHoverItem from "./ListHoverItem";
import TaskCheckBox from "./TaskCheckBox";
import ReadonlyTaskCheckBox from "./ReadonlyTaskCheckBox";
import remarkGfm from "remark-gfm";
import { useMakeCopilotReadable } from "../packages/copilotkit/core/hooks/use-make-copilot-readable";
import ReadonlyChecklistItemCheckBox from "./ReadonlyChecklistItemCheckBox";
import ChecklistItemCheckBox from "./ChecklistItemCheckBox";
import CardElement from "./CardElement";
import { useTranslation } from "react-i18next";
import { ItemContent } from "./ItemContent";

const TaskListItem = (props: {
  task: Task;
  collapsible: boolean;
  readonly?: boolean;
  open: boolean;
  extended: boolean;
  showCheckboxes: boolean;
  parentCopilotPointer?: string;
  noButton?: boolean;
  updateIsListCollapsible?: () => void;
  onTaskStateChange?: (newState: boolean) => void;
}) => {
  const taskPointer = useMakeCopilotReadable(
    props.task.title,
    props.parentCopilotPointer,
  );
  useMakeCopilotReadable(props.task.note, taskPointer);

  const { t } = useTranslation();

  // eslint-disable-next-line
  const markdownPattern =
    /[#*_\[\]()>+-]|!\[.*]\(.*\)|\[.*]\(.*\)|`.*`|\n>|<!--|---|\*\*.*\*\*|__.*__|\*.*\*|_.*_|~.*~/gm;

  const [openNote, setOpen] = React.useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClick = () => {
    setOpen(!openNote);
    if (props.onTaskStateChange && props.updateIsListCollapsible) {
      props.onTaskStateChange(!openNote);
      props.updateIsListCollapsible();
    }
  };

  function hasMarkdown(taskNote: string) {
    return markdownPattern.test(taskNote);
  }

  const { id, note, checkListItems } = props.task;
  const showExpandIcon =
    (note && props.collapsible) ||
    (checkListItems && checkListItems.length > 0 && props.collapsible);

  const TaskCheckBoxComponent = props.readonly
    ? ReadonlyTaskCheckBox
    : TaskCheckBox;
  const ChecklistItemCheckBoxComponent = props.readonly
    ? ReadonlyChecklistItemCheckBox
    : ChecklistItemCheckBox;

  return (
    <>
      <Box data-testid="task-list-item">
        {props.noButton ? (
          <ListHoverItem key={id}>
            <ItemContent
              showCheckboxes={props.showCheckboxes}
              TaskCheckBoxComponent={TaskCheckBoxComponent}
              task={props.task}
              showExpandIcon={showExpandIcon}
              openNote={openNote}
            />
          </ListHoverItem>
        ) : (
          <ListItemButton
            style={{
              backgroundColor: props.task.isTitleMarked
                ? "orange"
                : undefined
            }}
            disabled={props.noButton}
            selected={true}
            tabIndex={-1}
            onClick={handleClick}
          >
            <ItemContent
              showCheckboxes={props.showCheckboxes}
              TaskCheckBoxComponent={TaskCheckBoxComponent}
              task={props.task}
              showExpandIcon={showExpandIcon}
              openNote={openNote}
            />
          </ListItemButton>
        )}
        {note || (checkListItems && checkListItems.length > 0) ? (
          props.collapsible ? (
            <ListItem
              data-testid="task-note"
              key={id + "note"}
              disablePadding
              sx={{ ml: 4, width: "93%" }}
            >
              <Collapse
                in={openNote}
                timeout="auto"
                unmountOnExit
                sx={{ width: "100%" }}
              >
                <ListItemText>
                  {props.extended ? (
                    <>
                      {checkListItems && checkListItems.length > 0 ? (
                        <CardElement headline={t("view_project.subtasks")}>
                          {checkListItems
                            ?.sort(
                              (a, b) =>
                                new Date(a.createdDateTime).getTime() -
                                new Date(b.createdDateTime).getTime(),
                            )
                            .map((checkListItem) => (
                              <div key={checkListItem.id}>
                                <ChecklistItemCheckBoxComponent
                                  checklistItem={checkListItem}
                                  showCheckboxes={props.showCheckboxes}
                                />
                              </div>
                            ))}
                        </CardElement>
                      ) : null}
                      {note ? (
                        <CardElement headline={t("view_project.notes")}>
                          {hasMarkdown(note) ? (
                            <div
                              style={{
                                paddingLeft: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              <ReactMarkdown
                                data-testid="task-note-markup"
                                remarkPlugins={[remarkGfm]}
                              >
                                {note}
                              </ReactMarkdown>
                            </div>
                          ) : (
                            <div
                              style={{
                                paddingLeft: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              <Typography
                                data-testid="task-note-without-markup"
                                variant="inherit"
                              >
                                {note}
                              </Typography>
                            </div>
                          )}
                        </CardElement>
                      ) : null}
                    </>
                  ) : null}
                </ListItemText>
              </Collapse>
            </ListItem>
          ) : null
        ) : null}
      </Box>
    </>
  );
};

export default TaskListItem;
//Todo: Red ist der task title
