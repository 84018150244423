import React, { useEffect, useState } from "react";

interface VersionInfoData {
  version: string;
}

const VersionInfo: React.FC = () => {
  const [versionInfo, setVersionInfo] = useState<VersionInfoData | null>(null);

  useEffect(() => {
    fetch("/version.json")
      .then((response) => response.json())
      .then((data) => setVersionInfo(data))
      .catch((error) => console.error("Error fetching version info:", error));
  }, []);

  if (!versionInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Version Information</h1>
      <pre>{JSON.stringify(versionInfo, null, 2)}</pre>
    </div>
  );
};

export default VersionInfo;
