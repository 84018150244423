import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import styled from "@emotion/styled";

import { useMsal } from "@azure/msal-react";

import { loginRequest } from "../AuthModule";
import { useNavigate } from "react-router-dom";
import AuthenticatedTemplateWrapper from "../Components/AuthenticatedTemplateWrapper";
import UnauthenticatedTemplateWrapper from "../Components/UnauthenticatedTemplateWrapper";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export default function Login() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (instance.getActiveAccount()) {
    navigate("/landingPage");
  }

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <div>
      <MainContainer>
        <UnauthenticatedTemplateWrapper>
          <Button
            variant="contained"
            id="login-button"
            onClick={handleLoginRedirect}
          >
            {t("header.log_in")}
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Text in a modal
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography>
            </Box>
          </Modal>
        </UnauthenticatedTemplateWrapper>
      </MainContainer>
    </div>
  );
}
