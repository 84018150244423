import {
  Chip,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import ChosenDataDisplay from "./ChosenDataDisplay";
import ListIcon from "@mui/icons-material/List";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Autocomplete from "@mui/material/Autocomplete";
import CardElement from "./CardElement";
import { useTranslation } from "react-i18next";
import useUsersApi from "../Api/UsersApi";
import { User } from "../Model/User";
import * as _ from "lodash";

interface TodoList {
  id: string;
  displayName: string;
}

interface ContactsStepProps {
  selectedContact: string;
  handleContactlistChange: (
    event: React.ChangeEvent<{}>,
    value: string | null,
  ) => void;
  inputError: boolean;
  selectedList: TodoList | null;
  returnToPage: any;
  isPrivate: boolean;
  handleVisibilityChange: (isPrivate: boolean) => void;
  handleContactInform: (sendNotification: boolean) => void;
  sendNotification: boolean;
}

const ContactsStep = ({
  selectedContact,
  handleContactlistChange,
  inputError,
  selectedList,
  returnToPage,
  isPrivate,
  handleVisibilityChange,
  handleContactInform,
  sendNotification,
}: ContactsStepProps) => {
  //shows dropdown options list depending on this variable (currently if !== "")
  const [currentUserInput, setCurrentUserInput] = useState<string>(" ");
  //const [isLoading, setIsLoading] = useState(false);

  const { getUsers, getUsersFromGraph, getFilteredByDisplayNameUsers } =
    useUsersApi();
  let [users, setUsers] = useState<User[]>([]);

  const { t } = useTranslation();

  function onInputChange(event: any, newInputValue: string) {
    setCurrentUserInput(newInputValue);
    if (
      newInputValue.endsWith(",") ||
      newInputValue.endsWith(";") ||
      newInputValue.endsWith(" ")
    ) {
      newInputValue = newInputValue.replaceAll(",", "");
      newInputValue = newInputValue.replaceAll(";", "");
      newInputValue = newInputValue.replaceAll(" ", "");
      if (
        !selectedContact.includes(newInputValue) &&
        newInputValue.length > 0
      ) {
        event.target.blur();
        event.target.focus();
      }
    }
    if (newInputValue !== "") {
      const fetchUsers = async () => {
        //const userResponse = await getUsers();
        //const userGraphResponse = await getUsersFromGraph();
        //console.log(userResponse);
        const filteredByDisplayNameUsers =
          await getFilteredByDisplayNameUsers(newInputValue);

        if (filteredByDisplayNameUsers?.status === 200) {
          let userlist = filteredByDisplayNameUsers.body.value as User[];
          setUsers(userlist.filter((u) => u.mail !== null));
        }
      };

      fetchUsers().catch((e) => console.log("fetching users failed: " + e));
    }
  }

  let debouncedOnInputChange = _.debounce(onInputChange, 150);

  const handleVisibilityCheckBox = () => {
    handleVisibilityChange(!isPrivate);
  };
  const handleContactInformChange = () => {
    handleContactInform(!sendNotification);
  };

  return (
    <>
      <CardElement headline={t("start_project.contacts_step.headline")}>
        <Autocomplete
          autoHighlight
          autoSelect
          id="tags-filled"
          data-testid="contactsAutocomplete"
          freeSolo
          //options could recommend correct autofills after Graph API / AD Connection
          options={users.map((u) => u.mail)}
          //open={currentUserInput !== ""}
          value={selectedContact}
          onChange={handleContactlistChange}
          onInputChange={debouncedOnInputChange}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          filterOptions={(options, { inputValue }) => {
            if (inputValue.length >= 3) {
              return options;
            }
            return [];
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  children: undefined,
                }}
                placeholder={t("start_project.contacts_step.placeholder")}
                error={inputError}
                helperText={
                  inputError ? t("start_project.contacts_step.helper_text") : ""
                }
                autoFocus
              />
            );
          }}
        />
        <FormControlLabel
          data-testid="isPrivate"
          control={<Checkbox />}
          checked={isPrivate}
          onChange={handleVisibilityCheckBox}
          label={
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              {t("start_project.contacts_step.private_checkbox_label")}
              <Tooltip
                placement="right"
                title={t(
                  "start_project.contacts_step.private_checkbox_tooltip",
                )}
              >
                <HelpOutlineIcon
                  style={{ color: "gray", marginLeft: 4 }}
                  fontSize="small"
                />
              </Tooltip>
            </Typography>
          }
        />
        <FormControlLabel
          data-testid="sendNotification"
          control={<Checkbox />}
          checked={sendNotification}
          onChange={handleContactInformChange}
          label={
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              {t("start_project.contacts_step.notification_checkbox_label")}
              <Tooltip
                placement="right"
                title={t(
                  "start_project.contacts_step.notification_checkbox_tooltip",
                )}
              >
                <HelpOutlineIcon
                  style={{ color: "gray", marginLeft: 4 }}
                  fontSize="small"
                />
              </Tooltip>
            </Typography>
          }
        />
      </CardElement>
      {
        <ChosenDataDisplay
          onClick={() => returnToPage(0)}
          icon={<ListIcon />}
          primaryText={t("start_project.contacts_step.selected_list")}
          secondaryText={selectedList?.displayName}
        />
      }
    </>
  );
};

export default ContactsStep;
