import React, { useState } from "react";
import { Typography } from "@mui/material";
import AssigneeProjectView from "../views/AssigneeProjectView";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";

const ViewProject = () => {
  const [projectId, setProjectId] = useState<string | null>(null);
  const [projectName, setProjectName] = useState<string | null>(null);
  const [organisatorId, setOrganisatorId] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  React.useEffect(() => {
    // Überprüfen Sie zuerst, ob die ID bereits im SessionStorage gespeichert ist
    // const idFromStorage = sessionStorage.getItem('id');

    // if (idFromStorage) {
    //     setProjectId(idFromStorage);
    //     return;
    // }

    const queryParams = new URLSearchParams(location.search);
    let sessionId = queryParams.get("id");

    if (sessionId) {
      sessionStorage.setItem("id", sessionId);
      setProjectId(sessionId);

      // Entfernen Sie die ID aus der URL
      // const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
      // window.history.replaceState({}, document.title, url);
    } else {
      setProjectName(queryParams.get("name"));
      setOrganisatorId(queryParams.get("organisatorId"));
    }
  }, [location, navigate]);

  return (
    <>
      {projectId ? (
        <Container maxWidth="md" sx={{ mt: 3 }}>
          <AssigneeProjectView projectId={projectId} />
        </Container>
      ) : (
        <>
          {projectName && organisatorId ? (
            <Container maxWidth="md" sx={{ mt: 3 }}>
              <AssigneeProjectView
                projectName={projectName}
                organisatorId={organisatorId}
              />
            </Container>
          ) : (
            <Container>
              <Typography variant="h5">
                {t("view_project.project_number")}
              </Typography>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default ViewProject;
