import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../AuthModule";

const useTemplateApi = () => {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.apiTemplates.scopes.write,
  });

  const getTemplates = () =>
    execute("GET", `${protectedResources.apiTemplates.endpoint}`);
  const createTemplate = (
    projectId: string,
    masterSlideName: string,
    generateImages: boolean,
    saveToDb: boolean
  ) => {
    let url = `${protectedResources.apiTemplates.endpoint}?projectId=${projectId}&generateImages=${generateImages}&saveToDb=${saveToDb}`;

    if (masterSlideName) {
      url += `&masterSlideName=${masterSlideName}`;
    }

    return execute("POST", url);
  };

  return { getTemplates, createTemplate };
};

export default useTemplateApi;
