import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Playground from "../Components/Playground";
import { Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import CardComponent from "../Components/CardComponent";
import CardHeader from "../Components/CardHeader";
import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";

const PlaygroundPage = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  //Download
  const handleDownload = async () => {
    setAnchorEl(null);
    fetch("MarkdownTutorial.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "MarkdownTutorial.pdf";
        link.click();
      });
    });
  };

  return (
    <Box>
      <Container maxWidth="md" sx={{ mt: 3 }}>
        <CardComponent>
          <CardHeader headline={t("header.menu.playground.title")} />
          <Typography
            variant="h6"
            style={{ fontSize: "20px", marginBlockEnd: "-25px" }}
          >
            {" "}
            {t("header.menu.playground.typewriter_text")}
          </Typography>
          <Typography variant="h6" style={{ fontSize: "17px" }}>
            <TipsAndUpdatesTwoToneIcon
              sx={{ color: "rgb(48,86,119)", marginBlockEnd: "-8px" }}
            />
            {t("header.menu.playground.description")}
          </Typography>
          <Typography
            variant="h6"
            style={{
              fontSize: "17px",
              marginTop: "-3px",
              marginLeft: "30.3px",
            }}
          >
            {t("header.menu.playground.description_danify")}
          </Typography>
          <Playground />
          <Typography variant="h6" style={{ fontSize: "10px" }}>
            {t("header.menu.playground.outro")}
            <a
              style={{ textDecoration: "none", color: "rgb(48,86,119)" }}
              href=""
              onClick={handleDownload}
            >
              {" "}
              {t("header.menu.playground.download_text")}
            </a>
          </Typography>
        </CardComponent>
      </Container>
    </Box>
  );
};

export default PlaygroundPage;
