import supportedLangs from "./langs.json";

export const customLanguages = [
  "English", // English
  "Español", // Spanish
  "Français", // French
  "Deutsch", // German
  "中文", // Chinese (Simplified)
  "日本語", // Japanese
  "Русский", // Russian
  "العربية", // Arabic
  "Português", // Portuguese
  "Italiano", // Italian
  "한국어", // Korean
  "Nederlands", // Dutch
  "Svenska", // Swedish
  "Türkçe", // Turkish
  "Polski", // Polish
  "עברית", // Hebrew
  "Ελληνικά", // Greek
  "Dansk", // Danish
  "Suomi", // Finnish
  "Norsk", // Norwegian
  "Magyar", // Hungarian
  "Čeština", // Czech
  "Română", // Romanian
  "ไทย", // Thai
  "Українська", // Ukrainian
  "Bahasa Indonesia", // Indonesian
  "Tiếng Việt", // Vietnamese
  "हिन्दी", // Hindi
  "தமிழ்", // Tamil
  "తెలుగు", // Telugu
  "ਪੰਜਾਬੀ", // Punjabi
  "বাংলা", // Bengali
  "മലയാളം", // Malayalam
  "Kiswahili", // Swahili
  "Tagalog", // Tagalog
  "Bahasa Malaysia", // Malay
  "ქართული", // Georgian
  "ಕನ್ನಡ", // Kannada
  "Узбек", // Uzbek
  "ગુજરાતી", // Gujarati
  "فارسی", // Persian (Farsi)
  "සිංහල", // Sinhala
  "ລາວ", // Lao
  "ភាសាខ្មែរ", // Khmer
].filter((l) => !supportedLangs.some((sl) => sl.original === l));
