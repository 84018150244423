import {
  getMockOrganisatorMail,
  isLocalAndAuthorizationDisabled,
} from "../Utils/environment";
import { useMsal } from "@azure/msal-react";

function useOrganisatorMail() {
  let organisatorMail: string | undefined;

  const { instance } = useMsal();
  const isLocalAndAuthIsDisabled = isLocalAndAuthorizationDisabled();

  if (isLocalAndAuthIsDisabled) {
    organisatorMail = getMockOrganisatorMail();
  } else {
    const currentAccount = instance.getActiveAccount();
    if (!currentAccount) {
      organisatorMail = undefined;
    }
    organisatorMail = currentAccount?.username as string;
  }

  return organisatorMail;
}

export default useOrganisatorMail;
