import React, { useState } from "react";
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Collapse,
  Divider,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useMsal } from "@azure/msal-react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CardButton from "./CardButton";
import { loginRequest } from "../AuthModule";
import AuthenticatedTemplateWrapper from "../Components/AuthenticatedTemplateWrapper";
import UnauthenticatedTemplateWrapper from "../Components/UnauthenticatedTemplateWrapper";
import { isLocalAndAuthorizationDisabled } from "../Utils/environment";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Cancel, Check, ExpandLess, ExpandMore } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import supportedLangs from "../Utils/localization/langs.json";
import Button from "@mui/material/Button";
import useTranslationApi from "../Api/TranslationApi";
import translationEn from "../Utils/localization/en.json";
import { customLanguages } from "../Utils/localization/custom_languages";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import WebOutlinedIcon from "@mui/icons-material/WebOutlined";
import { useNavigate } from "react-router-dom";
import navigateOrReload  from "../Utils/navigationUtils";

type userMenuProps = {
  isOpen: boolean;
  handleOnClick: () => void;
};

const UserMenu = ({ isOpen, handleOnClick }: userMenuProps) => {
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [openLanguageMenu, setOpenLanguageMenu] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const { createTranslation } = useTranslationApi();
  document.body.dir = i18n.dir();

  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    handleOnClick();
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleOnClick();
  };

  let [isLoading, setIsLoading] = useState(false);
  let [addingNewLanguage, setAddingNewLanguage] = useState(false);
  let [newLanguage, setNewLanguage] = useState("");

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance
      .logoutRedirect({
        account: instance.getActiveAccount(),
      })
      .then();
  };

  const changeLanguage = (language: string) => {
    if (i18next.language === "custom") {
      localStorage.removeItem("customLanguageName");
      localStorage.removeItem("customTranslationData");
    }

    i18next.changeLanguage(language).then();
    document.body.dir = i18n.dir();
    handleClose();
  };

  const cachingCustomTranslation = (translation: any, language: string) => {
    const customTranslation = {
      translation,
      version: process.env.REACT_APP_BUILD_VERSION,
    };
    localStorage.setItem("customLanguageName", language);
    localStorage.setItem(
      "customTranslationData",
      JSON.stringify(customTranslation),
    );
  };

  const isLocal = isLocalAndAuthorizationDisabled();
  const localUserName = isLocal ? "Local User" : "Unknown";

  const acceptLanguage = () => {
    let lang = newLanguage;
    const toastLoading = toast.loading(
      t("header.menu.user_menu.custom_language_loading"),
    );
    setIsLoading(true);
    createTranslation(lang, translationEn)
      .then((value) => {
        toast.dismiss(toastLoading);

        if (value!.status === 401 || value!.status === 403) {
          toast.error(t("header.menu.user_menu.custom_language_auth_error"), {
            autoClose: false,
          });
          return;
        } else if (value!.status === 400) {
          toast.error(t("header.menu.user_menu.custom_language_error"));
          console.error(`Translation error: ${value!.body.error}`);
          return;
        }
        toast.success(t("header.menu.user_menu.custom_language_success"), {
          toastId: "success1",
        });
        i18next.addResourceBundle(
          "custom",
          "translation",
          value!.body,
          false,
          true,
        );
        changeLanguage("custom");
        cachingCustomTranslation(value!.body, lang);
      })
      .catch((error) => {
        toast.dismiss(toastLoading);
        toast.error(t("header.menu.user_menu.custom_language_error"), {
          toastId: "error1",
        });
        console.error(error);
      })
      .finally(() => {
        setAddingNewLanguage(false);
        setIsLoading(false);
        setNewLanguage(lang);
      });
    handleClose();
  };

  return (
    <Stack direction="column" sx={{ ml: 5, mr: 5 }}>
      <ToastContainer position="top-right" containerId="container1" />
      <Backdrop open={isLoading}>
        <CircularProgress title={"SomeText"} />
      </Backdrop>
      <UnauthenticatedTemplateWrapper>
        <CardButton
          title={t("header.menu.user_menu.log_in")}
          icon={<AccountCircleOutlinedIcon sx={{ fontSize: 30 }} />}
          onClick={() => handleLoginRedirect()}
          testId="header.menu.user_menu"
        />
      </UnauthenticatedTemplateWrapper>

      <AuthenticatedTemplateWrapper>
        <Box
          aria-controls={menuOpen ? "long-menu" : undefined}
          aria-expanded={menuOpen ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <CardButton
            title={activeAccount ? activeAccount.name : localUserName}
            description={t("header.menu.manage_users")}
            icon={<AccountCircleOutlinedIcon sx={{ fontSize: 30 }} />}
            testId="header.menu.user_menu"
            onClick={() => {}}
          />
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          sx={{
            mt: 1,
          }}
          PaperProps={{
            style: {
              paddingLeft: 10,
              paddingRight: 10,
              margin: 0,
              boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
              borderRadius: 10,
              minWidth: "250px",
            },
          }}
        >
          <MenuList>
            <MenuItem sx={{ mb: 2 }}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                data-testid="userInformationListItem"
                primary={activeAccount ? activeAccount.name : localUserName}
                secondary={
                  activeAccount ? activeAccount.username : localUserName
                }
              />
            </MenuItem>
            <MenuItem
              onClick={() => setOpenLanguageMenu(!openLanguageMenu)}
              data-testid="header.menu.user_menu.language_option"
            >
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("header.menu.user_menu.language_option")}
              />
              {openLanguageMenu ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={openLanguageMenu} timeout="auto" unmountOnExit>
              <MenuList>
                {supportedLangs.map((lang) => (
                  <MenuItem
                    key={lang.short}
                    selected={i18next.language === lang.short}
                    onClick={() => changeLanguage(lang.short)}
                    data-testid={`menu-item-${lang.short}`}
                  >
                    <ListItemText>{t(lang.original)}</ListItemText>
                  </MenuItem>
                ))}
                <Divider />

                {"customLanguageName" in localStorage && (
                  <MenuItem
                    disableRipple
                    key={localStorage.getItem("customLanguageName")}
                    selected={true}
                  >
                    <ListItemText>
                      {localStorage.getItem("customLanguageName")}
                    </ListItemText>
                  </MenuItem>
                )}
                <MenuItem disableRipple>
                  {addingNewLanguage ? (
                    <ListItemText>
                      <Autocomplete
                        placeholder={newLanguage}
                        onKeyDown={(e) => e.stopPropagation()}
                        disablePortal
                        id="lang-ac"
                        inputValue={newLanguage}
                        onInputChange={(event, newInputValue) => {
                          setNewLanguage(newInputValue);
                        }}
                        options={customLanguages}
                        freeSolo={true}
                        filterOptions={(options, { inputValue }) => {
                          // Only filter options when there are at least three characters
                          if (inputValue.length >= 3) {
                            return options.filter((option) =>
                              option
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()),
                            );
                          }
                          // If less than three characters, return empty options
                          return [];
                        }}
                        renderInput={(params) => (
                          <TextField
                            autoFocus={true}
                            label={t("header.menu.user_menu.custom_language")}
                            value={newLanguage}
                            {...params}
                            InputLabelProps={{
                              children: t(
                                "header.menu.user_menu.custom_language",
                              ),
                            }}
                          />
                        )}
                      />
                      <Button disabled={!newLanguage} onClick={acceptLanguage}>
                        <Check />
                      </Button>
                      <Button onClick={() => setAddingNewLanguage(false)}>
                        <Cancel />
                      </Button>
                    </ListItemText>
                  ) : (
                    <ListItemText onClick={() => setAddingNewLanguage(true)}>
                      <ListItemIcon></ListItemIcon>
                      {t("header.menu.user_menu.custom_language")}
                    </ListItemText>
                  )}
                </MenuItem>
              </MenuList>
            </Collapse>
            <Divider sx={{ mb: 1 }} />
            <MenuItem
              data-testid={"header.menu.user_menu.import_option"}
              onClick={() => {
                handleClose();
                navigateOrReload(navigate, "/csvImport");
              }}
            >
              <ListItemIcon>
                <UploadFileOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t("header.menu.import")} />
            </MenuItem>
            <MenuItem
              data-testid={"header.menu.user_menu.my_presentations_option"}
              onClick={() => {
                handleClose();
                navigateOrReload(navigate, "/showMyPresentations");
              }}
            >
              <ListItemIcon>
                <WebOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("header.menu.my_presentations_description")}
              />
            </MenuItem>
          </MenuList>
          <Divider sx={{ mb: 1 }} />
          <MenuItem onClick={handleLogoutRedirect}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t("header.menu.user_menu.log_out")} />
          </MenuItem>
        </Menu>
      </AuthenticatedTemplateWrapper>
    </Stack>
  );
};

export default UserMenu;
