import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthenticatedTemplate, MsalProvider } from "@azure/msal-react";

import "./App.css";
import LandingPage from "./pages/LandingPage";

import StartProject from "./pages/StartProject";
import Header from "./Components/Header";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import CSVImport from "./pages/CSVImport";
import { isLocalAndAuthorizationDisabled } from "./Utils/environment";
import ViewProject from "./pages/ViewProject";
import NotFoundPage from "./pages/NotFoundPage";
import ViewMyProjects from "./pages/ViewMyProjects";
import "./Utils/localization/config";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { CopilotProvider } from "./packages/copilotkit/core/components/copilot-provider";
import withRoot from "./Utils/localization/withRoot";
import PlaygroundPage from "./pages/PlaygroundPage";
import FeatureRequest from "./pages/FeatureRequest";
import { VERSION } from "./version";
import ViewMyPresentations from "./pages/ViewMyPresentations";
import PlayButton from "./Components/PlayButton";
import TutorialVideoPage from "./pages/TutorialVideoPage";
import VersionInfo from "./Utils/versionInfo";
import Websocket from "./Websocket/Websocket";

const Pages = () => {
  return (
    <Routes>
      <Route path="/startProject" element={<StartProject />} />
      <Route path="/assigneeProjectView" element={<ViewProject />} />
      <Route path="/csvImport" element={<CSVImport />} />
      <Route path="/showMyProjects" element={<ViewMyProjects />} />
      <Route path="/featureRequest" element={<FeatureRequest />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/PlaygroundPage" element={<PlaygroundPage />} />
      <Route path="/showMyPresentations" element={<ViewMyPresentations />} />
      <Route path="/tutorial-video" element={<TutorialVideoPage />} />
      <Route path="/version" element={<VersionInfo />} />
    </Routes>
  );
};

console.log("Danify Version: " + VERSION);
console.log("process.env.NODE_ENV: " + process.env.NODE_ENV);
console.log(
  "process.env.REACT_APP_BACKEND_API: " + process.env.REACT_APP_BACKEND_API,
);
console.log(
  "process.env.REACT_APP_CSV_FUNCTION: " + process.env.REACT_APP_CSV_FUNCTION,
);
console.log("process.env.REACT_APP_ENV: " + process.env.REACT_APP_ENV);
console.log(
  "process.env.REACT_APP_AUTH_DISABLED: " + process.env.REACT_APP_AUTH_DISABLED,
);
console.log(
  "process.env.REACT_APP_APPLICATION_ID_URI: " +
    process.env.REACT_APP_APPLICATION_ID_URI,
);
console.log(
  "process.env.REACT_APP_BUILD_VERSION: " + process.env.REACT_APP_BUILD_VERSION,
);

//@ts-ignore next
function App({ instance }) {
  initializeIcons();
  const MsalWrapper: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) =>
    isLocalAndAuthorizationDisabled() ? (
      <>{children}</>
    ) : (
      <MsalProvider instance={instance}>{children}</MsalProvider>
    );

  return (
    <CopilotProvider>
      <MsalWrapper>
        <ThemeProvider theme={theme}>
          <Header />
          <CssBaseline />
          <Pages />
          <PlayButton />
          <AuthenticatedTemplate>
            <Websocket />
          </AuthenticatedTemplate>
        </ThemeProvider>
      </MsalWrapper>
    </CopilotProvider>
  );
}

export default withRoot(App);
