import React, { useState } from "react";
import { IconButton } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckIcon from "@mui/icons-material/Check";
import ChosenDataDisplay from "./ChosenDataDisplay";
import { useTranslation } from "react-i18next";

function UrlCopyField({ url }: { url: string }) {
  const [copySuccess, setCopySuccess] = useState(false);

  const { t } = useTranslation();

  const handleCopyClick = () => {
    navigator.clipboard.writeText(url);
    setCopySuccess(true);
  };

  return (
    <>
      <ChosenDataDisplay
        primaryText={url}
        secondaryText={t(
          "start_project.project_creation_dialog.direct_link_text",
        )}
        icon={
          <IconButton onClick={handleCopyClick} sx={{ color: "black" }}>
            {copySuccess ? <CheckIcon /> : <FileCopyIcon />}
          </IconButton>
        }
      />
    </>
  );
}

export default UrlCopyField;
