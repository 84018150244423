import React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogContentText,
  DialogActions
} from "@mui/material";

import { useTranslation } from "react-i18next";

interface ConfirmationDialogProps {
  openUpdateDialog: boolean;
  handleUpdateDialogClose: () => void;
  actionOnConfirm: () => void;
  testId?: string;
  children?: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.openUpdateDialog}
      onClose={props.handleUpdateDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid={props.testId}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.children ? (
            props.children
          ) : (
            <>
              <p>
                <b>
                  {t("confirmation_dialog.title")}
                </b>
              </p>
              <p>{t("confirmation_dialog.body")}</p>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleUpdateDialogClose}>
          {props.cancelButtonText ? props.cancelButtonText : t("confirmation_dialog.backText")}
        </Button>
        <Button variant="outlined" onClick={props.actionOnConfirm} autoFocus>
          {props.confirmButtonText ? props.confirmButtonText : t("confirmation_dialog.confirmText")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
