import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Container } from "@mui/material";
import Login from "./Login";
import AuthenticatedTemplateWrapper from "../Components/AuthenticatedTemplateWrapper";
import UnauthenticatedTemplateWrapper from "../Components/UnauthenticatedTemplateWrapper";
import { useTranslation } from "react-i18next";
import { CopilotProvider } from "../packages/copilotkit/core/components/copilot-provider";
import { MainCopilotSidebarUIProvider } from "../packages/copilotkit/ui/sidebar/main-copilot-sidebar-ui-provider";

const LandingPage: React.FC = () => {
  const { t } = useTranslation();

  const numberRandomTexts = t("homepage.texts.length");
  const [randomTextIndex, setRandomTextIndex] = useState<number>(0);

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * numberRandomTexts);
    setRandomTextIndex(randomNumber);
    sessionStorage.setItem("randomTextIndex", randomNumber.toString());
    document.title = "Danify";
  }, [numberRandomTexts]);

  return (
    <>
      <AuthenticatedTemplateWrapper>
        <CopilotProvider>
          <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
          <MainCopilotSidebarUIProvider
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          >
            <Container maxWidth="md" sx={{ mt: 15 }}>
              <Stack
                sx={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                spacing={5}
              >
                <Box
                  sx={
                    sidebarOpen
                      ? { margin: "0 0 0 -50vh", width: "50%" }
                      : { width: "100%" }
                  }
                >
                  <Typography
                    variant="h2"
                    gutterBottom
                    data-testid="homepage-headline"
                  >
                    {t("homepage.headline")}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {t("homepage.texts." + randomTextIndex, {
                      returnObjects: true,
                    })}
                  </Typography>
                </Box>
              </Stack>
            </Container>
          </MainCopilotSidebarUIProvider>
        </CopilotProvider>
      </AuthenticatedTemplateWrapper>

      <UnauthenticatedTemplateWrapper>
        <Login />
      </UnauthenticatedTemplateWrapper>
    </>
  );
};

export default LandingPage;
