import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

interface ButtonStackProps {
  activeStep: number;
  onBackClicked: () => void;
  onNextClicked: () => void;
  onSubmitClicked: () => void;
  isLastStep: boolean;
  canProjectBeCreated: boolean;
  isLoading: boolean;
}

const NavigationButtons = ({
  activeStep,
  onBackClicked,
  onNextClicked,
  onSubmitClicked,
  isLastStep,
  canProjectBeCreated,
  isLoading,
}: ButtonStackProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row">
      <Button
        color="inherit"
        onClick={() => onBackClicked()}
        disabled={activeStep === 0}
        data-testid="backButton"
      >
        {t("navigation_buttons.back")}
      </Button>
      {isLastStep ? (
        <Button
          color="inherit"
          onClick={() => onSubmitClicked()}
          disabled={!canProjectBeCreated}
          data-testid="submitButton"
        >
          {t("navigation_buttons.start_project")}
        </Button>
      ) : (
        <Button
          color="inherit"
          onClick={() => onNextClicked()}
          disabled={isLoading}
          data-testid="nextButton"
        >
          {t("navigation_buttons.next")}
        </Button>
      )}
    </Stack>
  );
};

export default NavigationButtons;
