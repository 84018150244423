import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import SavePopup from "../Components/SavePopup";
import Button from "@mui/material/Button";
import SaveTasklistDialog from "./SaveTasklistDialog";
import { useTranslation } from "react-i18next";
interface SaveComponentProps {
  isSaveButtonDisabled: boolean;
  saveTaskList: () => void;
  isLoading: boolean;
  showSaveMessage: boolean;
  successfulSavingStatus: boolean;
  setShowSaveMessage: React.Dispatch<React.SetStateAction<boolean>>;
  checkTasklistNameAvailability: any;
}

const SaveComponent = ({
  isSaveButtonDisabled,
  saveTaskList,
  isLoading,
  showSaveMessage,
  setShowSaveMessage,
  successfulSavingStatus,
  checkTasklistNameAvailability,
}: SaveComponentProps) => {
  const [showOverrideDialog, setShowOverrideDialog] = React.useState(false);

  const { t } = useTranslation();

  async function handleClick() {
    if (await checkTasklistNameAvailability()) {
      setShowOverrideDialog(true);
    } else {
      saveTaskList();
    }
  }

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Button
          color="inherit"
          onClick={() => {
            setShowSaveMessage(false);
            handleClick();
          }}
          disabled={isSaveButtonDisabled}
          sx={{
            border: "1px solid",
            borderColor: "inherit",
          }}
        >
          {t("import.save")}
        </Button>
      </Grid>
      {showOverrideDialog && (
        <SaveTasklistDialog
          showDialog={showOverrideDialog}
          setShowOverrideDialog={setShowOverrideDialog}
          saveTaskList={saveTaskList}
        />
      )}
      {isLoading && <CircularProgress />}
      <Grid item xs={6}>
        {showSaveMessage && <SavePopup status={successfulSavingStatus} />}
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};

export default SaveComponent;
