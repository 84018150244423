// TODO: Alles aus der Komponente entfernen, was nicht bei der Projektanlage benötigt wird. Diese Komponente zeigt
// einfach nur die Aufgaben und diese sind sortierbar.

import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import {
  Alert,
  Box,
  Divider,
  Grow,
  List,
  Stack,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Task } from "../Utils/Types/Task";
import { useMakeCopilotReadable } from "../packages/copilotkit/core/hooks/use-make-copilot-readable";
import AuthenticatedTemplateWrapper from "./AuthenticatedTemplateWrapper";
import TaskListItem from "./TaskListItem";
import UnauthenticatedTemplateWrapper from "./UnauthenticatedTemplateWrapper";
import { Draggable } from "react-drag-reorder";
import "./TaskListStep.css";

type TaskListNtProps = {
  tasks: Task[];
  tasksCollapsible: boolean;
  extended: boolean;
  readonly: boolean;
  showCkeckboxes: boolean;
  title?: string;
  visibility?: string;
  classes: {
    task?: string | ((task: Task) => string);
  };
  handleSwapItems: Parameters<typeof Draggable>[0]["onPosChange"];
};

export const TaskListPreview = (props: Readonly<TaskListNtProps>) => {
  const copilotPointer = useMakeCopilotReadable(props.title ?? "");

  const [tasksWithState, setTaskCollapseState] = useState(
    props.tasks.map((t) => ({ task: t, open: false })),
  );

  useEffect(() => {
    setTaskCollapseState((p) => {
      return props.tasks.map((task) => ({
        task,
        open: p.find((oldTask) => oldTask.task.id === task.id)?.open ?? false,
      }));
    });
  }, [props.tasks]);

  const [collapseState, setCollapseState] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    setTaskCollapseState((prevState) =>
      prevState.map((t) => ({ ...t, open: collapseState })),
    );
  }, [collapseState]);

  const taskDivContainerRefs = useRef<(HTMLDivElement | null)[]>(
    new Array(tasksWithState.length).fill(null),
  );
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [overIndex, setOverIndex] = useState<number | null>(null);
  useEffect(() => {
    taskDivContainerRefs.current = new Array(tasksWithState.length).fill(null);
  }, [tasksWithState.length]);
  const theme = useTheme();
  return (
    <Stack data-testid="task-list">
      {props.tasksCollapsible && (
        <Stack
          justifyContent={"flex-end"}
          style={{ position: "absolute", right: 0, top: "-36px" }}
          direction={"row"}
        >
          <Button
            style={{ paddingBottom: "1px" }}
            onClick={() => setCollapseState(!collapseState)}
            title={collapseState ? "Einklappen" : "Ausklappen"}
          >
            {collapseState ? (
              <>
                <UnfoldLess /> {t("view_project.collapse")}{" "}
              </>
            ) : (
              <>
                <UnfoldMore /> {t("view_project.expand")}
              </>
            )}
          </Button>
        </Stack>
      )}
      <Grow in={true}>
        <List sx={{ position: "relative" }}>
          {tasksWithState.length > 0 ? (
            tasksWithState.map((t, index) => {
              return (
                <Box
                  component={"div"}
                  ref={(obj: HTMLDivElement | null) =>
                    (taskDivContainerRefs.current[index] = obj)
                  }
                  key={t.task.id}
                  className={`${
                    typeof props.classes.task === "string"
                      ? props.classes.task
                      : typeof props.classes.task !== "undefined"
                        ? props.classes.task(t.task)
                        : undefined
                  }`}
                  draggable
                  onClick={(e) => e.preventDefault()}
                  onDragStart={(e) => {
                    e.dataTransfer.setData("text/plain", t.task.id);
                    e.dataTransfer.dropEffect = "move";
                    setDraggedIndex(index);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.dataTransfer.dropEffect = "move";
                    if (draggedIndex !== index) {
                      setOverIndex(index);
                    } else {
                      setOverIndex(null);
                    }
                  }}
                  onDrop={(e) => {
                    if (
                      props.handleSwapItems &&
                      t.task.id !== e.dataTransfer.getData("text/plain")
                    ) {
                      const oldIndex = tasksWithState.findIndex(
                        (task) =>
                          task.task.id === e.dataTransfer.getData("text/plain"),
                      );
                      props.handleSwapItems(
                        oldIndex,
                        tasksWithState.indexOf(t),
                      );
                    }
                    setDraggedIndex(null);
                    setOverIndex(null);
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      overflow: "hidden",
                      width: "100%",
                      height:
                        draggedIndex !== null &&
                        overIndex === index &&
                        draggedIndex > index
                          ? 20
                          : 0,
                    }}
                  ></Box>
                  <AuthenticatedTemplateWrapper>
                    <TaskListItem
                      noButton
                      showCheckboxes={props.showCkeckboxes}
                      collapsible={props.tasksCollapsible}
                      readonly={props.readonly}
                      task={t.task}
                      open={t.open}
                      extended={props.extended}
                      parentCopilotPointer={copilotPointer}
                    />
                  </AuthenticatedTemplateWrapper>
                  <UnauthenticatedTemplateWrapper>
                    <TaskListItem
                      noButton
                      showCheckboxes={props.showCkeckboxes}
                      collapsible={props.tasksCollapsible}
                      readonly={true}
                      task={t.task}
                      open={t.open}
                      extended={props.extended}
                      parentCopilotPointer={copilotPointer}
                    />
                  </UnauthenticatedTemplateWrapper>
                  <Divider />
                  <Box
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      overflow: "hidden",
                      width: "100%",
                      height:
                        draggedIndex !== null &&
                        overIndex === index &&
                        draggedIndex < index
                          ? 20
                          : 0,
                    }}
                  ></Box>
                </Box>
              );
            })
          ) : (
            <Alert severity="info">
              {t("start_project.task_list_step.alert")}
            </Alert>
          )}
        </List>
      </Grow>
    </Stack>
  );
};
