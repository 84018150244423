import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources, translationEndpoint } from "../AuthModule";

const useTranslationApi = () => {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.apiProject.scopes.write,
  });

  const createTranslation = (language: string, data: any) => {
    return execute("POST", `${translationEndpoint}`, {
      language: language,
      data: data,
    });
  };

  return { createTranslation };
};

export default useTranslationApi;
