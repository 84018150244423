import './chat-components.css';
import { Box, Grow, List, ListItem, Stack, Button } from "@mui/material";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import React, { type Dispatch, type FC, type SetStateAction } from "react";
import { useTranslation } from "react-i18next";

export interface EmptyScreenProps {
  setInput: Dispatch<SetStateAction<string>>;
}

export const MainDefaultEmptyScreen: FC<EmptyScreenProps> = (props) => {
  const { t } = useTranslation();
  const exampleMessages = [
    {
      heading: t("copilot.landingpage.example_heading1"),
      message: t("copilot.landingpage.example_message1"),
    },
    {
      heading: t("copilot.landingpage.example_heading2"),
      message: t("copilot.landingpage.example_message2"),
    },
    {
      heading: t("copilot.landingpage.example_heading3"),
      message: t("copilot.landingpage.example_message3"),
    },
  ];
  return (
    <div className="mx-auto max-w-2xl px-4">
      <div
        className="rounded-lg border bg-background p-8 copilot-empty-screen"
        data-testid="copilotSidebar"
      >
        <h1
          className="mb-2 text-lg font-semibold"
        >
          {t("copilot.sidebar.headline")}
        </h1>
        <p
          className="leading-normal text-muted-foreground"
        >
          {t("copilot.sidebar.description")}
        </p>
        <Stack>
          <Grow in={true}>
            <List>
              {exampleMessages.map((message, index) => (
                <React.Fragment key={index}>
                  <Box
                    sx={{ width: "100%", marginLeft: "10px" }}
                  >
                    <ListItem disablePadding>
                      <Button onClick={() => props.setInput(message.message)}>
                        <ChevronRightOutlinedIcon className="mr-2 text-muted-foreground" />
                        {message.heading}
                      </Button>
                    </ListItem>
                  </Box>
                </React.Fragment>
              ))}
            </List>
          </Grow>
        </Stack>
      </div>
    </div>
  );
};
