import React from "react";
import { ThemeProvider, CssBaseline, createTheme } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import i18next from "./config";

const emotionCache = createCache({ key: "rtl" });

const theme = createTheme({
  direction: i18next.dir(),
});

function withRoot<P>(Component: React.ComponentType<P>): React.FC<P> {
  return (props) => {
    return (
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Component {...props}> </Component>
        </ThemeProvider>
      </CacheProvider>
    );
  };
}

export default withRoot;
