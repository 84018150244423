import {
  Alert,
  Box,
  CircularProgress,
  Grow,
  List,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import UpdateIcon from "@mui/icons-material/Update";
import React, { useEffect, useState } from "react";
import { protectedResources } from "../AuthModule";
import TaskList from "../Components/TaskList";
import { Task } from "../Utils/Types/Task";
import Button from "@mui/material/Button";
import ShareIcon from "@mui/icons-material/Share";
import CardComponent from "../Components/CardComponent";
import CardElement from "../Components/CardElement";
import ShareDialog from "../Components/ShareDialog";
import CardHeader from "../Components/CardHeader";
import ConfirmationDialog from "../Components/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import AuthenticatedTemplateWrapper from "../Components/AuthenticatedTemplateWrapper";
import { CopilotSidebarUIProvider } from "../packages/copilotkit/ui/sidebar/copilot-sidebar-ui-provider";
import { CopilotProvider } from "../packages/copilotkit/core/components/copilot-provider";
import { useMsal } from "@azure/msal-react";
import UnauthenticatedTemplateWrapper from "../Components/UnauthenticatedTemplateWrapper";
import UnauthenticatedTaskList from "../Components/UnauthenticatedTaskList";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import PresentationMode from "../Components/PresentationMode";
import useOrganisatorId from "../hooks/useOrganisatorId";
import useOrganisatorMail from "../hooks/useOrganisatorMail";
import { IPublicClientApplication } from "@azure/msal-browser";
import { toast } from "react-toastify";

import "./AssigneeProjectView.css";
import { set } from "lodash";

interface ViewProjectViewProps {
  projectId?: string;
  projectName?: string;
  organisatorId?: string;
  disableBackground?: boolean;
  taskListId?: string;
}

interface TodoList {
  id: string;
  displayName: string;
  microsoftTaskListId: string | null;
  tasks: Task[];
}

interface ProjectData {
  id: string;
  name: string;
  organisatorId: string;
  tasklists: [TodoList];
  visibility: string;
  assigneeEmail: string;
  isCopied: boolean;
  isHideFinishedTasks: boolean;
}

interface CopyData {
  name: string;
  visibility: string;
  taskLists: TodoList[];
  emailNotificationsEnabled: boolean;
  isHideFinishedTasks: boolean;
  host: string;
  emails: string[];
}

async function copy_project(
  instance: IPublicClientApplication,
  copy_data: CopyData,
  copyErrorMessage: string,
) {
  let token = (
    await instance.acquireTokenSilent({
      scopes: protectedResources.apiProject.scopes.write,
    })
  ).accessToken;

  const url = protectedResources.apiProject.endpoint + "/copy_project";

  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    body: JSON.stringify(copy_data),
  })
    .then((response) => {
      if (!response.ok) {
        toast.error(
          response.status == 409
            ? copyErrorMessage
            : "Network response was not ok",
        );
      } else {
        return response.json();
      }
    })
    .then((response) => {
      window.location.href = `/assigneeProjectView?id=${response.id}`;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

async function update_project(
  instance: IPublicClientApplication,
  project_id: string | undefined,
) {
  let token = (
    await instance.acquireTokenSilent({
      scopes: protectedResources.apiProject.scopes.write,
    })
  ).accessToken;

  const url =
    protectedResources.apiProject.endpoint + "/update_project/" + project_id;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return await response.json();
}

const AssigneeProjectView = (props: ViewProjectViewProps) => {
  const host = window.location.protocol + "//" + window.location.host;
  const { instance } = useMsal();
  //DATA
  const [project, setProject] = useState<ProjectData | null>(null);

  //UI
  const [loading, setLoading] = useState<Boolean>(true);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [openPresentationMode, setOpenPresentationMode] = useState(false);

  const [httpStatusCode, setHttpStatusCode] = useState<number>(-1);
  const { t } = useTranslation();

  // Copy shared project
  const userId = useOrganisatorId();
  const canCopySharedProject =
    userId !== project?.organisatorId && userId !== undefined;

  const userMail = useOrganisatorMail();
  const [copying, setCopying] = useState<boolean>(false);

  // Update project
  const [updating, setUpdating] = useState<boolean>(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const canUpdate =
    !project?.isCopied && project?.tasklists[0].microsoftTaskListId !== null;
  const updateButtonTooltip = project?.isCopied
    ? t("view_project.update.not_allowed_for_copied_projects")
    : !canUpdate
      ? t("view_project.update.deprecated_project_for_update")
      : "";
  const readonly =
    userId !== project?.organisatorId && userMail !== project?.assigneeEmail;

  const [hasSubContent, setHasSubContent] = useState<boolean>(false);

  async function handleCopyClick() {
    setCopying(true);
    setLoading(true);

    if (!project) {
      setCopying(false);
      setLoading(false);
      throw new Error("project is undefined");
    }

    // emailNotificationsEnabled is always false, because the user initiates the copy process
    // and is always an assignee for the project. So he shouldn't receive a mail
    const creationData: CopyData = {
      name: project.name,
      visibility: project.visibility,
      taskLists: project.tasklists,
      isHideFinishedTasks: project.isHideFinishedTasks,
      emailNotificationsEnabled: false,
      host: host,
      emails: [userMail],
    };

    copy_project(
      instance,
      creationData,
      t("view_project.duplicate_project_name_error"),
    ).then(() => {
      setCopying(false);
      setLoading(false);
    });
  }

  async function startUpdateProject() {
    setUpdating(true);
    setLoading(true);

    try {
      const response = await update_project(instance, project?.id);
      setProject(response);
    } catch (error) {
      console.log("Error: ", error);
      toast.error(t("view_project.update.update_error"));
    } finally {
      setUpdating(false);
      setLoading(false);
      setOpenUpdateDialog(false);
    }
  }

  async function handleUpdateClick() {
    setOpenUpdateDialog(true);
  }

  function handleUpdateDialogClose() {
    setOpenUpdateDialog(false);
  }

  useEffect(() => {
    setLoading(true);
    if (props.projectId !== undefined || props.projectName !== undefined) {
      const fetchData = async () => {
        const headers = new Headers();
        if (instance.getActiveAccount()) {
          let token = (
            await instance.acquireTokenSilent({
              scopes: protectedResources.apiProject.scopes.write,
            })
          ).accessToken;

          headers.append("Authorization", `Bearer ${token}`);
        }

        let url: string;

        if (props.projectId !== undefined) {
          url = `${protectedResources.apiProject.endpoint}/${props.projectId}`;
        } else {
          url =
            protectedResources.apiProject.endpoint +
            `?projectName=${props.projectName ? encodeURIComponent(props.projectName) : ""}` +
            `&organisatorId=${props.organisatorId}`;
        }

        const response = await fetch(url, { headers: headers });

        if (response === undefined) {
          return;
        }
        const statusCode = response.status.valueOf();
        setHttpStatusCode(statusCode);
        if (statusCode === 200) {
          const projectData = await response.json();
          setProject(projectData);
        }

        setLoading(false);
      };
      fetchData();
    }
  }, [props.projectId, instance]);

  // Should the collapse/expand icon be shown
  useEffect(() => {
    if (!project) {
      return;
    }

    const tasksWithContent = project.tasklists[0].tasks.filter(
      (t) => t.note !== "" || t.checkListItems.length > 0,
    );
    setHasSubContent(tasksWithContent.length > 0 ? true : false);
  }, [project]);

  let loading_message: string = t("view_project.project_loading");
  if (copying) {
    loading_message = t("view_project.copy_project");
  } else if (updating) {
    loading_message = t("view_project.update.updating_project");
  }

  return (
    <CopilotProvider>
      <CopilotSidebarUIProvider
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      >
        <Box sx={{ width: "100%" }}>
          <CardComponent
            disableBackground={props.disableBackground}
            maxWidth={props.disableBackground ? false : "md"}
          >
            <CardHeader headline={t("view_project.headline")} />

            {loading ? (
              <Stack spacing={2} sx={{ alignItems: "center", p: 5 }}>
                {" "}
                {/*displayed while fetch is still executing*/}
                <CircularProgress />
                <Typography variant="h5">{loading_message}</Typography>
              </Stack>
            ) : httpStatusCode === 200 ? (
              <Stack spacing={3}>
                <Stack className="projectHeader" direction="row-reverse">
                  <Box className="buttonBox">
                    {/* Displayed, when the project was shared from another account */}
                    {canCopySharedProject && (
                      <Button
                        startIcon={<FileCopyIcon />}
                        onClick={() =>
                          handleCopyClick().catch(() => {
                            toast.error(t("view_project.copy_project_error"));
                          })
                        }
                        sx={{ p: 0 }}
                      >
                        {t("view_project.copy_project")}
                      </Button>
                    )}
                    {userId === project?.organisatorId && (
                      <Tooltip title={updateButtonTooltip}>
                        <span>
                          <Button
                            startIcon={<UpdateIcon />}
                            onClick={() => handleUpdateClick()}
                            disabled={!canUpdate}
                            sx={{ p: 0, ml: 2 }}
                            data-testid="updateProjectButton"
                          >
                            {t("view_project.update.update_project")}
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                    <Button
                      startIcon={<SlideshowOutlinedIcon />}
                      onClick={() => setOpenPresentationMode(true)}
                      sx={{ p: 0, ml: 2 }}
                    >
                      {t("view_project.present.presentation_mode_button")}
                    </Button>
                    <Button
                      startIcon={<ShareIcon />}
                      onClick={() => setOpenShareDialog(true)}
                      sx={{ p: 0, ml: 2 }}
                    >
                      {t("view_project.share")}
                    </Button>
                  </Box>
                  <Box className="projectNameWrapper">
                    <Typography className="projectName" variant="h5">
                      {project ? project.name : ""}{" "}
                    </Typography>
                  </Box>
                  <ConfirmationDialog
                    openUpdateDialog={openUpdateDialog}
                    handleUpdateDialogClose={handleUpdateDialogClose}
                    actionOnConfirm={startUpdateProject}
                    testId="updateDialog"
                    confirmButtonText={t("view_project.update.update_project")}
                  >
                    <p>
                      <b>
                        {t(
                          "my_projects.deletion_confirmations_dialog_view.title",
                        )}
                      </b>
                    </p>
                    <b>
                      {t("view_project.update.update_dialog_confirmation_text")}
                    </b>
                    <p>
                      {t(
                        "my_projects.deletion_confirmations_dialog_view.text2",
                      )}
                    </p>
                  </ConfirmationDialog>
                </Stack>
                <CardElement headline={t("view_project.tasks")}>
                  {project !== undefined && project !== null ? (
                    <Grow in={true}>
                      <Stack>
                        <List disablePadding>
                          <AuthenticatedTemplateWrapper>
                            <TaskList
                              showCkeckboxes={true}
                              readonly={readonly}
                              tasksCollapsible={hasSubContent}
                              tasks={project.tasklists[0].tasks}
                              extended={true}
                              title={project.tasklists[0].displayName}
                              visibility={project.visibility}
                              isHideFinishedTasks={project.isHideFinishedTasks}
                              copyTask={handleCopyClick}
                              canCopySharedProject={canCopySharedProject}
                            />
                          </AuthenticatedTemplateWrapper>
                          <UnauthenticatedTemplateWrapper>
                            <UnauthenticatedTaskList
                              showCkeckboxes={true}
                              readonly={false}
                              tasksCollapsible={hasSubContent}
                              tasks={project.tasklists[0].tasks}
                              extended={true}
                              title={project.tasklists[0].displayName}
                              visibility={project.visibility}
                            />
                          </UnauthenticatedTemplateWrapper>
                        </List>
                      </Stack>
                    </Grow>
                  ) : (
                    <></>
                  )}
                </CardElement>
              </Stack>
            ) : httpStatusCode === 404 ? (
              <Alert severity="info">
                {t("view_project.project_not_found")}
              </Alert>
            ) : httpStatusCode === 401 ? (
              <Alert severity="info">{t("view_project.unauthorized")}</Alert>
            ) : (
              <></>
            )}
            {project !== undefined && project !== null ? (
              <PresentationMode
                open={openPresentationMode}
                onClose={() => setOpenPresentationMode(false)}
                tasks={project.tasklists[0].tasks.sort(
                  (a, b) =>
                    new Date(a.createdDateTime).getTime() -
                    new Date(b.createdDateTime).getTime(),
                )}
                projectName={project.name}
              ></PresentationMode>
            ) : (
              <></>
            )}
            <ShareDialog
              open={openShareDialog}
              onClose={() => setOpenShareDialog(false)}
              url={`${host}/assigneeProjectView
                ?name=${project ? encodeURIComponent(project.name) : ""}
                &organisatorId=${project ? project.organisatorId : ""}
                &taskListId=${project ? project.id : ""}`}
            ></ShareDialog>
          </CardComponent>
        </Box>
      </CopilotSidebarUIProvider>
    </CopilotProvider>
  );
};

export default AssigneeProjectView;
