import React from "react";
import { ChatMessage } from "./chat-message";
import { Message } from "../../core/types/ai-function";

export interface ChatList {
  messages: Message[];
}

export interface ChatListProps {
  messages: Message[];
  currentStep: number;
}

/**
 * this component is used to display the chat messages in the chat list it will display all messages
 */
export function ChatList({ messages }: ChatList) {
  const displayedMessages = messages.filter(
    (message) => message.role !== "system",
  );

  if (displayedMessages.length === 0) {
    return null;
  }

  return (
    <>
      {displayedMessages.map((message, index) => (
        <div key={index}>
          <ChatMessage message={message} />
          {index < displayedMessages.length - 1 && (
            <hr className="my-4 md:my-4" />
          )}
        </div>
      ))}
    </>
  );
}

/**
 * this component is used to display the last chat messages in the chat list dont use it for the main chat list
 * it will display the last 2 messages in the chat list
 */
export function LastChatList({ messages, currentStep }: ChatListProps) {
  const getDisplayedMessages = () => {
    const startIndex = currentStep * 2;
    const endIndex = startIndex + 2;

    return messages.slice(startIndex, endIndex);
  };

  const displayedMessages = getDisplayedMessages();

  if (displayedMessages.length === 0) {
    return null;
  }

  return (
    <>
      {displayedMessages.map((message, index) => (
        <div key={index}>
          <ChatMessage message={message} />
          {index < displayedMessages.length - 1 && (
            <hr className="my-4 md:my-4" />
          )}
        </div>
      ))}
    </>
  );
}
