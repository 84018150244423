import "./chat-components.css";
import React from "react";
import { PromptForm } from "./prompt-form";
import { UseChatHelpers } from "../../core/types/ai-function";

export interface ChatPanelProps
  extends Pick<
    UseChatHelpers,
    | "append"
    | "isLoading"
    | "reload"
    | "messages"
    | "stop"
    | "input"
    | "setInput"
  > {
  id?: string;
  styles?: "classic" | "for_dialog" | "for_feature_request";
  defaultMessage?: string;
  isDefaultMessageSendInstantly?: boolean;
  setIsDefaultMessageSendInstantly?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setMessage?: (value: string) => void;
  setCounter?: React.Dispatch<React.SetStateAction<number>>;
  onRespond?: (step: number, success: boolean) => void;
  messageCount?: number;
  setInputsSendCount?: React.Dispatch<React.SetStateAction<number>>;
  readOnly?: boolean;
}

export function ChatPanel({
  id,
  isLoading,
  stop,
  append,
  reload,
  input,
  setInput,
  messages,
  styles,
  defaultMessage,
  isDefaultMessageSendInstantly,
  setIsDefaultMessageSendInstantly,
  setMessage,
  setCounter,
  onRespond,
  messageCount,
  setInputsSendCount,
  readOnly,
}: ChatPanelProps) {
  return (
    <div
      className={
        styles == undefined || styles == "classic"
          ? "inset-x-0 bottom-0 bg-gradient-to-b from-muted/10 from-10% to-muted/30 to-50% mt-4 mb-8 copilot-main-chat-panel"
          : "bg-gradient-to-b from-muted/10 from-10% to-muted/30 to-50% mt-4 mb-8 copilot-main-chat-panel"
      }
    >
      <div className="mx-auto sm:max-w-2xl sm:px-4">
        <div className="space-y-4 border-2 bg-background px-4 py-2 shadow-lg sm:rounded-xl md:py-4">
          <PromptForm
            onSubmit={async (value: any) => {
              await append({
                id,
                content: value,
                role: "user",
              });
            }}
            input={input}
            setInput={setInput}
            isLoading={isLoading}
            styles={styles}
            message={defaultMessage}
            isDefaultMessageSendInstantly={isDefaultMessageSendInstantly}
            setIsDefaultMessageSendInstantly={setIsDefaultMessageSendInstantly}
            setMessage={setMessage}
            setCounter={setCounter}
            messageCount={messageCount}
            onRespond={onRespond}
            setInputsSendCount={setInputsSendCount}
            readOnly={readOnly}
          />
        </div>
      </div>
    </div>
  );
}
