import { Backdrop, Button, Grow, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { alpha } from "@mui/material";

interface CardButtonProps {
  title?: string;
  description?: string;
  icon?: JSX.Element;
  onClick: () => void;
  color?: string; // Hinzufügen von Farbprop
  testId?: string;
}

const CardButton = (props: CardButtonProps) => {
  const [animate, setAnimate] = useState<boolean>(false);

  return (
    <Button
      variant="text"
      onMouseOver={() => setAnimate(true)}
      onMouseLeave={() => setAnimate(false)}
      sx={{
        m: 0,
        p: 0,
        width: "110px",
        height: "90px",
        textTransform: "none",
        borderRadius: 3,
      }}
      onClick={props.onClick}
      data-testid={props.testId}
    >
      <Grow in={!animate || props.description === undefined}>
        <Stack
          direction="column"
          sx={{
            p: 1,
            zIndex: 0,
            alignItems: "center",
          }}
        >
          {props.icon ? props.icon : <></>}
          <Typography
            variant="caption"
            sx={{ color: props.color ?? "inherit" }}
          >
            {props.title ?? ""}
          </Typography>
        </Stack>
      </Grow>
      {props.description ? (
        <Grow in={animate}>
          <Backdrop
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.1),
              opacity: 0.4,
              borderRadius: 3,
            }}
            open
          >
            <Typography variant="caption">{props.description ?? ""}</Typography>
          </Backdrop>
        </Grow>
      ) : (
        <></>
      )}
    </Button>
  );
};

export default CardButton;
