import React, { type ReactNode, useCallback } from "react";
import { CopilotSidebarContext } from "./sidebar-context";
import { useTranslation } from "react-i18next";
import AuthenticatedTemplateWrapper from "../../../../Components/AuthenticatedTemplateWrapper";
import { MainCopilotSidebar } from "./main-copilot-sidebar";
import Button from "@mui/material/Button";

export interface CopilotSidebarUIProviderLandingpageProps {
  children: ReactNode;
  sidebarOpen: boolean;
  setSidebarOpen: (
    newState: boolean | ((prevState: boolean) => boolean),
  ) => void;
}

export function MainCopilotSidebarUIProvider({
  children,
  setSidebarOpen,
  sidebarOpen,
}: CopilotSidebarUIProviderLandingpageProps) {
  const { t } = useTranslation();

  const toggleSidebar = useCallback(() => {
    setSidebarOpen((prevState) => !prevState);
  }, []);

  return (
    <CopilotSidebarContext.Provider
      value={{ isSidebarOpen: sidebarOpen, toggleSidebar }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          margin: "1px 0 0 20px",
        }}
      >
        <AuthenticatedTemplateWrapper>
          <Button
            style={{
              color: "rgba(33,87,122,255)",
              margin: "15px 10px 0 0",
              alignSelf: "flex-end",
            }}
            onClick={toggleSidebar}
          >
            {t("copilot.sidebar.open_button")}
          </Button>
        </AuthenticatedTemplateWrapper>
        <div
          style={{
            position: "static",
            width: sidebarOpen ? "90vw" : "100%",
            transform: sidebarOpen ? "translateX(0)" : "translateX(0)",
            transition: "transform 0.3s ease-in-out, width 0.5s",
          }}
        >
          {children}
        </div>
        <AuthenticatedTemplateWrapper>
          <div
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              width: "40%",
              backgroundColor: "white",
              zIndex: 1,
              transform: sidebarOpen ? "translateX(0%)" : "translateX(100%)",
              transition: "transform 0.3s ease-in-out",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
              overflowX: "hidden",
            }}
          >
            <MainCopilotSidebar
              setSidebarOpen={toggleSidebar}
              sidebarOpen={sidebarOpen}
            />
          </div>
        </AuthenticatedTemplateWrapper>
      </div>
    </CopilotSidebarContext.Provider>
  );
}
