import React, { useEffect, useState } from "react";
import { protectedResources } from "../AuthModule";
import Login from "./Login";
import { Container, Grow, Stack, TextField } from "@mui/material";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import AuthenticatedTemplateWrapper from "../Components/AuthenticatedTemplateWrapper";
import UnauthenticatedTemplateWrapper from "../Components/UnauthenticatedTemplateWrapper";
import ImportSaveComponent from "../Components/ImportSaveComponent";
import ImportDropzoneComponent from "../Components/ImportDropzoneComponent";
import { Accept } from "react-dropzone";
import CardComponent from "../Components/CardComponent";
import CardElement from "../Components/CardElement";
import CardHeader from "../Components/CardHeader";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

interface FileUploadRequest {
  file: any;
  Tasklist: string;
}

interface TodoList {
  id: string;
  displayName: string;
}

const CSVImport: React.FC = () => {
  useEffect(() => {
    document.title = t("header.menu.import");
  }, [i18next.language]);

  //UI
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [successfulSavingStatus, isSavedSuccessfully] = useState(false);

  //DATA
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [tasklistName, setTasklistName] = useState<string>("");

  const [isTasknameFieldDisabled, setIsTasknameFieldDisabled] =
    useState<boolean>(true);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.apiTasklists.scopes.read,
  });

  useEffect(() => {
    setIsSaveButtonDisabled(tasklistName === "");
  }, [tasklistName]);

  function prepareUpload(acceptedFiles: any) {
    setUploadedFile(acceptedFiles[0]);
    let recommendedTasklistName = acceptedFiles[0].name.substring(
      0,
      acceptedFiles[0].name.lastIndexOf("."),
    );
    setTasklistName(recommendedTasklistName);
    setIsTasknameFieldDisabled(false);
  }
  async function saveTasklist() {
    setIsLoading(true); // Start the loading indicator
    let request: FileUploadRequest = {
      file: uploadedFile,
      Tasklist: tasklistName,
    };
    try {
      await execute(
        "POST",
        protectedResources.functionCSVImport.endpoint,
        null,
        request,
      ).then((response) => {
        console.log("Response:", response);
        let savingStatus = response?.status === 200;

        isSavedSuccessfully(savingStatus);
      });
    } catch (error) {
      isSavedSuccessfully(false);
      console.log("Error: " + error);
    }
    setShowSaveMessage(true);
    setIsLoading(false);
  }

  // only accept .csv files
  let onlyCSV: Accept = {
    "text/html": [".csv"],
  };

  async function checkTasklistNameAvailability(): Promise<boolean> {
    return await execute("GET", protectedResources.apiTasklists.endpoint).then(
      (response) => {
        if (response?.body !== undefined) {
          return response.body.some(
            (tasklist: TodoList) => tasklist.displayName === tasklistName,
          );
        } else {
          return false;
        }
      },
    );
  }

  return (
    <>
      <AuthenticatedTemplateWrapper>
        <Container maxWidth="md" sx={{ mt: 3 }}>
          <Grow in={true}>
            <Stack spacing={2}>
              <CardComponent>
                <CardHeader
                  data-testid="myImportViewCard"
                  headline={t("import.headline")}
                />
                {/* Dropzone */}
                <CardElement headline={t("import.import_dropzone.headline")}>
                  <ImportDropzoneComponent
                    accept={onlyCSV}
                    onDropAccepted={(acceptedFiles) => {
                      prepareUpload(acceptedFiles);
                      setShowSaveMessage(false);
                    }}
                    uploadedFile={uploadedFile}
                    setUploadedFile={setUploadedFile}
                  />
                </CardElement>
                <CardElement headline={t("import.task_list_name")}>
                  <TextField
                    type="text"
                    placeholder={t("import.suggested_task_list_name")}
                    sx={{ width: "100%" }}
                    value={tasklistName}
                    onChange={(e) => {
                      setTasklistName(e.target.value);
                    }}
                    disabled={isTasknameFieldDisabled}
                  ></TextField>
                </CardElement>
                <CardElement headline={t("import.upload_task_list")}>
                  <ImportSaveComponent
                    isSaveButtonDisabled={isSaveButtonDisabled}
                    saveTaskList={saveTasklist}
                    isLoading={isLoading}
                    showSaveMessage={showSaveMessage}
                    successfulSavingStatus={successfulSavingStatus}
                    setShowSaveMessage={setShowSaveMessage}
                    checkTasklistNameAvailability={
                      checkTasklistNameAvailability
                    }
                  />
                </CardElement>
              </CardComponent>
            </Stack>
          </Grow>
        </Container>
      </AuthenticatedTemplateWrapper>
      <UnauthenticatedTemplateWrapper>
        <Login />
      </UnauthenticatedTemplateWrapper>
    </>
  );
};

export default CSVImport;
