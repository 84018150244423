import './chat-components.css';
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import React from 'react';
import { MemoizedReactMarkdown } from "./markdown";
import AssistantIcon from "@mui/icons-material/Assistant";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import { CodeBlock } from "./codeblock";
import { Message } from "../../core/types/ai-function";
import { FeedbackButtons } from './feedback_buttons';

export interface ChatMessageProps {
  message: Message;
}

export function ChatMessage({ message, ...props }: ChatMessageProps) {

  // as of 2024-07-01, feedback buttons should only be shown in non-production environments
  // if tracing is disabled in the backend, the traceId will be undefined and the feedback buttons will not be shown
  // nevertheless, the feature flag REACT_APP_ENV is included to avoid the frontend breaking in case the backend behaviour is changed
  const showFeedbackButtons = (message: Message): boolean => {
    return (message.role === "assistant" || message.role === "function")
      && process.env.REACT_APP_ENV !== "production"
      && message.traceId !== undefined;
  };

  return (
    <div
      {...props}
      className={"copilot-chat-message"}
    >
      <div
        className={"copilot-chat-message-icon"}
      >
        {message.role === "user" ? (
          <AccountCircleSharpIcon />
        ) : (
          <AssistantIcon />
        )}
      </div>
      <div className="content-wrapper">
        <div className="message-container">
          <MemoizedReactMarkdown
            className="prose break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 text-sm"
            remarkPlugins={[remarkGfm, remarkMath]}
            components={{
              p({ children }) {
                return <p className="mb-2 last:mb-0">{children}</p>;
              },
              code({ children, className, inline, ...props }) {
                if (children.length !== 0) {
                  if (children[0] === "▍") {
                    return (
                      <span className="mt-1 animate-pulse cursor-default">▍</span>
                    );
                  }

                  children[0] = (children[0] as string).replace("`▍`", "▍");
                }

                const match = /language-(\w+)/.exec(className ?? "");

                if (inline ?? false) {
                  return (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                }

                return (
                  <CodeBlock
                    key={Math.random()}
                    language={match?.[1] ?? ""}
                    value={String(children).replace(/\n$/, "")}
                    {...props}
                  />
                );
              },
            }}
          >
            {message.content}
          </MemoizedReactMarkdown>
        </div>
        {/* Add Upvote and Downvote Buttons if traceId is not undefined */}
        {showFeedbackButtons(message) && (<FeedbackButtons message={message} />)}
      </div>
    </div>
  );
}
