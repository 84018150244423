import React from "react";
import { ListItem } from "@mui/material";

/**
 * This component is used to create a hover effect on a list item. A possible
 * scenario is the usage in a drag and drop list, instead of ListItemButton,
 * because drag and drop is not working stable with ListItemButton.
 */
const ListHoverItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <ListItem
      sx={{
        "&:hover": {
          backgroundColor: "#E5EBEF",
        },
        "backgroundColor": "#EEF2F5",
        "cursor": "pointer",
        "fontStyle": "normal"
      }}
    >
      {children}
    </ListItem>
  );
};

export default ListHoverItem;
