import { Checkbox, ListItemText } from "@mui/material";
import React from "react";
import { ChecklistItem } from "../Utils/Types/Task";

const ReadonlyTaskCheckBox = (props: {
  checklistItem: ChecklistItem;
  showCheckboxes: boolean;
}) => {
  return (
    <ListItemText>
      {props.showCheckboxes &&
        (props.checklistItem.isChecked ? (
          <Checkbox disabled defaultChecked />
        ) : (
          <Checkbox disabled />
        ))}
      {props.checklistItem.displayName}
    </ListItemText>
  );
};

export default ReadonlyTaskCheckBox;
