import React from "react";
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Paper from "@mui/material/Paper";

interface ChosenDataDisplayProps {
  onClick?: () => void;
  icon?: JSX.Element;
  primaryText?: string;
  secondaryText?: string;
}

const ChosenDataDisplay = (props: ChosenDataDisplayProps) => {
  return (
    <>
      <Paper variant="outlined" square>
        <ListItem
          secondaryAction={
            props.onClick !== undefined ? (
              <IconButton size="small" onClick={props.onClick}>
                <Close fontSize="inherit" />
              </IconButton>
            ) : (
              <></>
            )
          }
        >
          {props.icon != null ? (
            <ListItemAvatar>{props.icon}</ListItemAvatar>
          ) : (
            <></>
          )}
          <ListItemText
            primaryTypographyProps={{
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
            primary={props.primaryText}
            secondary={props.secondaryText}
          ></ListItemText>
        </ListItem>
      </Paper>
    </>
  );
};

export default ChosenDataDisplay;
