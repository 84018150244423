import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Stack,
} from "@mui/material";

import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardButton from "./CardButton";
import CodeIcon from "@mui/icons-material/Code";
import { isLocalAndAuthorizationDisabled } from "../Utils/environment";
import { useTranslation } from "react-i18next";
import { backendScope } from "../AuthModule";
import { VERSION } from "../version";

function getSwaggerUrl(): string {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  if (!apiUrl) {
    throw new Error(
      "REACT_APP_BACKEND_API is not defined in the environment variables.",
    );
  }
  const baseUrl = apiUrl.replace("/api", "");
  return `${baseUrl}/apidocs`;
}

const DevInfoDialog = () => {
  const { instance } = useMsal();
  const swaggerUrl = getSwaggerUrl();
  const apiUrl = process.env.REACT_APP_BACKEND_API as string;
  const isDevelopment = process.env.REACT_APP_ENV !== "production";
  const fullCommitHash = process.env.REACT_APP_SOURCE_VERSION;
  const commitUrl =
    "https://dev.azure.com/matdan/Danify/_git/DanifyFrontend/commit/" +
    fullCommitHash +
    "?refName=refs%2fheads%2fdevelop";

  // Zustand für die Anzeige des Dialogs hinzufügen
  const [openDevInfoDialog, setOpenDevInfoDialog] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const isLocalAndAuthIsDisabled = isLocalAndAuthorizationDisabled();

  const { t } = useTranslation();

  // Diese Funktion öffnet den Dialog und ruft das Token ab
  const displayDevInfoDialog = async () => {
    if (isLocalAndAuthIsDisabled) {
      setToken("No token required when running locally");
      setUserId("No user id required when running locally");
    } else {
      const currentAccount = instance.getActiveAccount();
      if (!currentAccount) {
        throw new Error("No active account!");
      }
      const response = await instance.acquireTokenSilent({
        scopes: [backendScope],
        account: currentAccount,
      });
      const token = `Bearer ${response.accessToken}`;
      setToken(token);
      setUserId(currentAccount.idTokenClaims?.oid as string);
    }

    setOpenDevInfoDialog(true);
  };

  // Diese Funktion schließt den Dialog
  const handleCloseDevInfoDialog = () => {
    setOpenDevInfoDialog(false);
  };

  // Diese Funktion kopiert den Text in die Zwischenablage
  const copyToClipboard = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast.success(t("header.menu.copy_success"));
      })
      .catch(() => {
        toast.error(t("header.menu.copy_error"));
      });
  };

  // Diese Funktion kürzt den Token
  const HighlightedShortenedValue = (
    value: string,
    startLength: number,
    endLength: number = 0,
  ) => {
    if (value.length <= startLength) {
      return <span>{value}</span>;
    }
    const start = value.substr(0, startLength);
    const end = endLength > 0 ? value.substr(-endLength) : "";
    var shortened = start + (endLength > 0 ? "..." + end : "");

    return shortened === value ? (
      <span>{value}</span>
    ) : (
      <span>
        <span style={{ color: "black" }}>{start}</span>
        <span style={{ color: "gray" }}>...</span>
        <span style={{ color: "black" }}>{end}</span>
      </span>
    );
  };

  
  return (
    <>
      <Stack
        direction="row"
        sx={{
          marginLeft: "auto", // Stellt diesen Stack rechtsbündig ein
        }}
      >
        {isDevelopment && (
          <CardButton
            title={t("header.menu.dev_info")}
            color="grey"
            icon={<CodeIcon sx={{ fontSize: 30, color: "grey" }} />}
            description={t("header.menu.dev_info_description")}
            onClick={displayDevInfoDialog}
          />
        )}
      </Stack>
          
      <Dialog
        open={openDevInfoDialog}
        onClose={handleCloseDevInfoDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>DevInfo</DialogTitle>

        <DialogContent>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Version</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => copyToClipboard(commitUrl)}
                    >
                      <FileCopyOutlinedIcon fontSize="small" />
                    </IconButton>
                    <a href={commitUrl}>{VERSION}</a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bearer</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => copyToClipboard(token)}
                    >
                      <FileCopyOutlinedIcon fontSize="small" />
                    </IconButton>
                    {HighlightedShortenedValue(token, 38, 5)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>UserID/OID</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => copyToClipboard(userId)}
                    >
                      <FileCopyOutlinedIcon fontSize="small" />
                    </IconButton>
                    {HighlightedShortenedValue(userId, 38, 5)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Swagger</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => copyToClipboard(swaggerUrl)}
                    >
                      <FileCopyOutlinedIcon fontSize="small" />
                    </IconButton>

                    <a href={swaggerUrl}>{swaggerUrl}</a>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Api</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => copyToClipboard(apiUrl)}
                    >
                      <FileCopyOutlinedIcon fontSize="small" />
                    </IconButton>
                    <a href={apiUrl}>{apiUrl}</a>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Environment</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() =>
                        copyToClipboard(process.env.REACT_APP_ENV as string)
                      }
                    >
                      <FileCopyOutlinedIcon fontSize="small" />
                    </IconButton>
                    {process.env.REACT_APP_ENV}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DevInfoDialog;
