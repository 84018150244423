import React, { Dispatch, FC, SetStateAction } from "react";
import { Accept, DropzoneOptions, useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { useTranslation } from "react-i18next";

type Props = {
  accept: Accept;
  onDropAccepted?: DropzoneOptions["onDropAccepted"];
  uploadedFile: any;
  setUploadedFile: Dispatch<SetStateAction<any>>;
};

const ImportDropzoneComponent: FC<Props> = ({
  accept,
  onDropAccepted,
  uploadedFile,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDropAccepted,
  });

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        border: "1px dashed",
        borderRadius: 5,
        display: "flex",
        justifyContent: "center",
        p: 5,
      }}
      {...getRootProps()}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FileOpenIcon
          sx={{ width: "80px", height: "80px", marginRight: "20px" }}
        />
        <section>
          <Typography variant="h6" style={{ fontSize: "18px" }}>
            {t("import.import_dropzone.dropzone_text")}
          </Typography>
          <div>
            <input {...getInputProps()} />
          </div>
          {uploadedFile && (
            <Typography
              variant="h6"
              style={{ fontSize: "18px", paddingTop: 10 }}
            >
              {t("import.import_dropzone.uploaded_file_text")}{" "}
              {uploadedFile.name}
            </Typography>
          )}
        </section>
      </Box>
    </Box>
  );
};

export default ImportDropzoneComponent;
