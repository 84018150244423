import useFetchWithMsal from "../hooks/useFetchWithMsal";
import {
  feedbackEndpoint, protectedResources
} from "../AuthModule";

type FeedbackPayload = {
  trace_id: string;
  value?: number;
  name?: string;
  comment?: string;
};


export const usePostFeedback = () => {

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.apiProject.scopes.write,
  });

  const postFeedback = (
    trace_id: string,
    name: string | undefined,
    value: number,
    comment: string | undefined,
  ) => {

    let payload: FeedbackPayload = {
      trace_id: trace_id,
      value: value,
    };

    if (comment !== undefined) {
      payload['comment'] = comment;
    }

    if ((name === undefined) && (comment !== undefined)) {
      name = "user-feedback-comment";
    } else if (name === undefined) {
      name = "user-feedback-value";
    }
    payload['name'] = name;

    return execute("POST", `${feedbackEndpoint}`, JSON.stringify(payload));
  };

  return postFeedback;
}
