import React, { useEffect, useState } from "react";
import { Button, Stack, Typography, Zoom } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChosenDataDisplay from "./ChosenDataDisplay";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import UrlCopyField from "./UrlCopyField";
import Container from "@mui/material/Container";
import CardComponent from "./CardComponent";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useOrganisatorId from "../hooks/useOrganisatorId";

interface ProjectCreatedProps {
  projectName: string;
  host: string;
  restartPage: () => void;
}

function ProjectCreationDialog({
  projectName,
  host,
  restartPage,
}: ProjectCreatedProps) {
  const { t } = useTranslation();
  const [organisatorId, setOrganisatorId] = useState<string | null | undefined>(
    null,
  );
  const userId = useOrganisatorId();

  useEffect(() => {
    setOrganisatorId(userId);
  }, [userId]);

  return (
    <Zoom in={true} style={{ transitionDelay: "500ms" }}>
      <Container maxWidth="sm" sx={{ mt: 3 }}>
        <CardComponent>
          <Stack sx={{ alignItems: "center" }} spacing={3}>
            <CheckCircleIcon
              sx={{ width: "120px", height: "120px" }}
              color="primary"
            />
            <Typography variant="h5">
              {t("start_project.project_creation_dialog.headline")}
            </Typography>
            <Stack sx={{ width: "100%" }} spacing={1}>
              <Link
                style={{ textDecoration: "none" }}
                to={`/assigneeProjectView?name=${encodeURIComponent(projectName)}&organisatorId=${organisatorId}`}
                data-testid={"link-to-created-project"}
              >
                <ChosenDataDisplay
                  primaryText={projectName}
                  secondaryText={t(
                    "start_project.project_creation_dialog.project_name",
                  )}
                  icon={<ChecklistOutlinedIcon />}
                />
              </Link>
              <UrlCopyField
                url={
                  host +
                  `/assigneeProjectView?name=${encodeURIComponent(projectName)}&organisatorId=${organisatorId}`
                }
              />
            </Stack>
            <Button variant="outlined" onClick={() => restartPage()}>
              {t("start_project.project_creation_dialog.start_another_project")}
            </Button>
          </Stack>
        </CardComponent>
      </Container>
    </Zoom>
  );
}

export default ProjectCreationDialog;
