import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  PublicClientApplication,
  EventType,
  AccountInfo,
} from "@azure/msal-browser";

import "./index.css";
import { MSAL_CONFIG } from "./AuthModule";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";

const msalInstance = new PublicClientApplication(MSAL_CONFIG);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    console.log("LOGIN SUCCESS");
    console.log(event.payload);

    const accountInfo = event.payload as AccountInfo;
    if (accountInfo) {
      msalInstance.setActiveAccount(accountInfo);
    }
  }
});

const synchronizeLanguageVersion = () => {
  const storedTranslationData = localStorage.getItem("customTranslationData");
  const appBuildVersion = process.env.REACT_APP_BUILD_VERSION;

  if (storedTranslationData) {
    const translationData = JSON.parse(storedTranslationData);

    if (translationData.version !== appBuildVersion) {
      localStorage.removeItem("customTranslationData");
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    }
  }
};
synchronizeLanguageVersion();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <BrowserRouter>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <App instance={msalInstance} />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
