import { Box, Checkbox, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { Task } from "../Utils/Types/Task";
import useTasksApi from "../Api/TasksApi";

const TaskCheckBox = (props: { task: Task; showCheckboxes: boolean }) => {
  const { completeTask, openTask } = useTasksApi();

  const handleCheck = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    const task = props.task;
    if (task.status === "Completed") {
      task.status = "NotStarted";
      await openTask(props.task.id);
    } else {
      task.status = "Completed";
      await completeTask(props.task.id);
    }
  };

  return (
    <>
      <ListItemIcon>
        <Box>
          {props.showCheckboxes &&
            (props.task.status === "Completed" ? (
              <Checkbox
                data-testid={`checkbox-${props.task.title}`}
                defaultChecked
                disableRipple
                onClick={(e) => handleCheck(e)}
              />
            ) : (
              <Checkbox disableRipple onClick={(e) => handleCheck(e)} data-testid={`checkbox-${props.task.title}`} />
            ))}
        </Box>
      </ListItemIcon>
      <ListItemText data-testid={`taskTitle-${props.task.title}`}>{props.task.title}</ListItemText>
    </>
  );
};

export default TaskCheckBox;
