import useFetchWithMsal from "../hooks/useFetchWithMsal";
import {
  chatEndpoint,
  copilotEndpoint,
  featureRequestChatEndpoint, protectedResources
} from "../AuthModule";

const useChatApi = () => {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.apiProject.scopes.write,
  });

  const checkStatusCode = (statusCode: number) => {
    // Check if the status code is in the 200 range
    if (statusCode < 200 || statusCode >= 300) {
      throw new Error("Failed to fetch");
    }
  }

  const createChat = async (messages: any, functions: any, sessionId: string) => {
    const response = await execute("POST", `${chatEndpoint}`, {
      messages: JSON.stringify(messages),
      functions: JSON.stringify(functions),
      session_id: sessionId,
    });
    if (response){
      checkStatusCode(response.status);
    }
    return response;
  };

  const createChatLandingpage = async (messages: Array<string>, functions: Function, sessionId: string) => {
    const response = await execute("POST", `${copilotEndpoint}`, {
      messages: JSON.stringify(messages),
      functions: JSON.stringify(functions),
      session_id: sessionId,
    });
    if(response){
      checkStatusCode(response.status);
    }
    return response;
  };

  const createFeatureRequestChat = async (
    messages: any,
    sessionId: any,
    functions: any,
    shouldCreateWorkItem: boolean
  ) => {
    const result = await execute("POST", `${featureRequestChatEndpoint}`, {
      messages: JSON.stringify(messages),
      session_id: sessionId,
      functions: JSON.stringify(functions),
      should_create_work_item: JSON.stringify(shouldCreateWorkItem),
    });
    if(result){
      checkStatusCode(result.status);
    }
    return result;
  };

  return { createChat, createChatLandingpage, createFeatureRequestChat };
};

export default useChatApi;
