import { alpha, Box, Stack } from "@mui/material";

import React, { ReactNode } from "react";

interface CardComponentProps {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  disableBackground?: boolean;
  children: ReactNode;
  testid?: string;
}

const CardComponent: React.FC<CardComponentProps> = (props) => {
  return (
    <Box
      data-testid={props.testid}
      sx={{
        boxShadow: props.disableBackground
          ? ""
          : "0px 0px 30px rgba(0, 0, 0, 0.1)",
        backgroundColor: props.disableBackground
          ? "none"
          : (theme) => alpha(theme.palette.background.paper, 1),
        borderRadius: props.disableBackground ? 0 : 3,
        p: props.disableBackground ? 0 : 7,
      }}
    >
      <Stack spacing={5}>{props.children}</Stack>
    </Box>
  );
};

export default CardComponent;
