import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Task } from "../Utils/Types/Task";
import {
  alpha,
  AppBar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Slide,
  Stack,
  Toolbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CircleIcon from "@mui/icons-material/Circle";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";

interface PresentationModeProps {
  open: boolean;
  tasks: Task[];
  onClose: () => void;
  projectName: string;
}

const PresentationMode: React.FC<PresentationModeProps> = ({
  open,
  tasks,
  onClose,
  projectName,
}) => {
  const [currentTaskIndex, setCurrentTaskIndex] = useState<number>(-1);
  const [fullscreenMode, setFullscreenMode] = useState<boolean>(false);
  const [slideIn, setSlideIn] = useState<boolean>(true);
  const [slideDirection, setSlideDirection] = useState<"right" | "left">(
    "right",
  );
  const { t } = useTranslation();
  const containerRef = React.useRef<HTMLElement>(null);
  const currentTask = tasks[currentTaskIndex];
  const markdownPattern =
    /[#*_\[\]()>+-]|!\[.*]\(.*\)|\[.*]\(.*\)|`.*`|\n>|<!--|---|\*\*.*\*\*|__.*__|\*.*\*|_.*_|~.*~/gm;

  useEffect(() => {
    const handleFullscreenChange = () => {
      setFullscreenMode(document.fullscreenElement !== null);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);
  const handleNextTask = () => {
    setSlideDirection("right");
    setSlideIn(false);
    setTimeout(() => {
      setCurrentTaskIndex((prevIndex) =>
        prevIndex < tasks.length - 1 ? prevIndex + 1 : prevIndex,
      );
      setSlideDirection("left");
      setSlideIn(true);
    }, 300);
  };

  const handlePreviousTask = () => {
    setSlideDirection("left");
    setSlideIn(false);
    setTimeout(() => {
      setCurrentTaskIndex((prevIndex) =>
        prevIndex > -1 ? prevIndex - 1 : prevIndex,
      );
      setSlideDirection("right");
      setSlideIn(true);
    }, 300);
  };

  const handleOnClose = () => {
    onClose();
    document.exitFullscreen();
    setCurrentTaskIndex(-1);
    setFullscreenMode(false);
  };

  const handleFullscreen = () => {
    setFullscreenMode(document.fullscreenElement !== null);
    if (fullscreenMode) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const handleTaskClick = (index: number) => {
    setSlideDirection("right");
    setSlideIn(false);
    setTimeout(() => {
      setCurrentTaskIndex(index);
      setSlideDirection("left");
      setSlideIn(true);
    }, 300);
  };

  const handleAgendaClick = () => {
    setSlideDirection("left");
    setSlideIn(false);
    setTimeout(() => {
      setCurrentTaskIndex(-1);
      setSlideDirection("right");
      setSlideIn(true);
    }, 300);
  };

  function hasMarkdown(taskNote: string) {
    return markdownPattern.test(taskNote);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleOnClose}
      PaperProps={{
        sx: {
          position: "absolute",
          backgroundColor: "#e5e5e5",
          alignItems: "center",
          zIndex: 1000,
        },
      }}
    >
      <AppBar
        sx={{
          position: "relative",
        }}
      >
        <Toolbar>
          {projectName}
          <Stack
            spacing={1}
            direction="row"
            sx={{ position: "absolute", right: 15 }}
          >
            <IconButton onClick={handleFullscreen}>
              {fullscreenMode ? (
                <FullscreenExitIcon style={{ color: "white" }} />
              ) : (
                <FullscreenIcon style={{ color: "white" }} />
              )}
            </IconButton>
            <IconButton onClick={handleOnClose}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "70%",
          height: "70%",
          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
          backgroundColor: (theme) => alpha(theme.palette.background.paper, 1),
          borderRadius: 3,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
        }}
        ref={containerRef}
      >
        <Slide
          in={slideIn}
          direction={slideDirection}
          container={containerRef.current}
        >
          <DialogTitle
            sx={{
              paddingLeft: "50px",
              paddingTop: "50px",
              fontFamily: "heading",
            }}
          >
            {currentTaskIndex === -1 ? (
              <Typography variant="h3" sx={{ color: "#173c55" }}>
                {t("view_project.present.agenda")}
              </Typography>
            ) : (
              <Typography variant="h3" sx={{ color: "#173c55" }}>
                {currentTask.title}
              </Typography>
            )}
          </DialogTitle>
        </Slide>
        <Slide
          in={slideIn}
          direction={slideDirection}
          container={containerRef.current}
        >
          <DialogContent
            sx={{
              paddingLeft: "100px",
              paddingRight: "100px",
              fontFamily: "heading",
              overflowY: "auto",
              marginBottom: "80px",
              flexGrow: 1,
            }}
          >
            {currentTaskIndex === -1 ? (
              <List>
                {tasks.map((task, index) => (
                  <ListItem key={index} divider={true}>
                    <ListItemButton
                      onClick={() => {
                        handleTaskClick(index);
                      }}
                    >
                      <ListItemIcon>
                        <CircleIcon fontSize={"small"} color={"primary"} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="h6">{task.title}</Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            ) : (
              <List>
                {currentTask?.checkListItems?.length > 0 && (
                  <>
                    <ListSubheader>
                      {t("view_project.present.subtasks")}
                    </ListSubheader>
                    {currentTask.checkListItems.map((item, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              {item.displayName}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </>
                )}
                {currentTask?.checkListItems?.length > 0 &&
                  currentTask.note && <Divider />}
                {currentTask.note && (
                  <>
                    {hasMarkdown(currentTask.note) ? (
                      <ListItem>
                        <ListItemText
                          primaryTypographyProps={{ variant: "h6" }}
                          primary={
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                              {currentTask.note}
                            </ReactMarkdown>
                          }
                        />
                      </ListItem>
                    ) : (
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              {currentTask.note}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                  </>
                )}
              </List>
            )}
          </DialogContent>
        </Slide>
        <Box
          sx={{
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {currentTaskIndex !== -1 && (
            <Button
              onClick={handleAgendaClick}
              sx={{
                position: "absolute",
                bottom: "10px",
                left: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ViewAgendaIcon />
              {t("view_project.present.agenda")}
            </Button>
          )}
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ position: "absolute", bottom: "10px" }}
          >
            <Button
              onClick={handlePreviousTask}
              disabled={currentTaskIndex === -1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100px",
              }}
            >
              <ArrowBackIcon />
              {t("view_project.present.previous")}
            </Button>
            <Typography>
              {`${currentTaskIndex + 2} ${t("view_project.present.of")} ${tasks.length + 1}`}
            </Typography>
            <Button
              onClick={handleNextTask}
              disabled={currentTaskIndex === tasks.length - 1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100px",
              }}
            >
              <ArrowForwardIcon />
              {t("view_project.present.next")}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PresentationMode;
