import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { t } from "i18next";

function Playground() {
  const [text, setText] = useState("");
  const [placeholderLength, setPlaceholderLength] = useState(0);

  const typingSpeed = 40; // determines speed of typewriter animation

  const placeholder: string = t("header.menu.playground.placeholder");

  let currentPlaceholder: string =
    placeholder.slice(0, placeholderLength) + "|";

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderLength((placeholderLength) => placeholderLength + 1);
    }, typingSpeed);
    return () => clearInterval(interval);
  }, []);

  const convertEmails = (inputText: string): string => {
    const emailRegex = /\(([^)]*@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,})\)/g;
    return inputText.replace(emailRegex, (_, match) => `(mailto:${match})`);
  };

  const convertedText = convertEmails(text);

  return (
    <div
      className="PlaygroundDiv"
      style={{ border: "5px solid rgb(210,210,210)", borderRadius: "10px" }}
    >
      <div className="DisplayDiv">
        <div
          className="display"
          style={{
            textAlign: "left",
            resize: "none",
            width: "100%",
            height: "280px",
            overflow: "auto",
            paddingLeft: "7px",
            backgroundColor: "rgb(229,229,229)",
          }}
        >
          {convertedText ? (
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {convertedText}
            </ReactMarkdown>
          ) : (
            <h2
              style={{ color: "#999", fontStyle: "italic", marginTop: "3px" }}
            >
              {t("header.menu.playground.empty_div")}
            </h2>
          )}
        </div>
      </div>
      <div className="TextInputDiv" style={{ borderRadius: "10px" }}>
        <div
          style={{
            width: "100%",
            height: "5.5px",
            backgroundColor: "rgb(210,210,210)",
          }}
        ></div>
        <textarea
          name="text"
          style={{
            textAlign: "left",
            resize: "none",
            width: "100%",
            height: "200px",
            border: "none",
            outline: "none",
          }}
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder={currentPlaceholder}
        />
      </div>
    </div>
  );
}

export default Playground;
