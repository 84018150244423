import React from "react";
import { Typography } from "@mui/material";

const NotFoundPage: React.FC = () => {
  return (
    <div className="notfound-container">
      <img alt="404 page not found" src="/dan-404.jpeg" />
    </div>
  );
};

export default NotFoundPage;
