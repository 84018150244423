import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import translationDe from "./de.json";
import translationEn from "./en.json";
import translationZhHans from "./zh_hans.json";
import translationVnm from "./vnm.json";
import translationUkr from "./ukr.json";
import translationAr from "./ar.json";
import translationPl from "./pl.json";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      de: {
        translation: translationDe,
      },
      en: {
        translation: translationEn,
      },
      vnm: {
        translation: translationVnm,
      },
      ukr: {
        translation: translationUkr,
      },
      zh_hans: {
        translation: translationZhHans,
      },
      ar: {
        translation: translationAr,
      },
      pl: {
        translation: translationPl,
      },
      custom: {
        translation: getCustomTranslation(),
      },
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
    fallbackLng: "en", // Sprache, die verwendet wird, wenn eine Übersetzung nicht gefunden wird
    debug: true,
  });

function getCustomTranslation(): any {
  try {
    const cachedTranslation = localStorage.getItem("customTranslationData");

    if (cachedTranslation) {
      return JSON.parse(cachedTranslation).translation;
    }
  } catch (error) {
    console.error("Error while parsing cached translation:", error);
  }

  return translationEn;
}

export default i18next;
