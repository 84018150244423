import {
  getMockOrganistorId,
  isLocalAndAuthorizationDisabled,
} from "../Utils/environment";
import { useMsal } from "@azure/msal-react";

function useOrganisatorId() {
  let organisatorId: string | undefined = undefined;

  const { instance } = useMsal();
  const isLocalAndAuthIsDisabled = isLocalAndAuthorizationDisabled();

  if (isLocalAndAuthIsDisabled) {
    organisatorId = getMockOrganistorId();
  } else {
    const currentAccount = instance.getActiveAccount();
    if (!currentAccount) {
      return organisatorId;
    } else {
      organisatorId = currentAccount.idTokenClaims?.oid as string;
    }
  }

  return organisatorId;
}

export default useOrganisatorId;
