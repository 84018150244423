import './chat-components.css';
import { ChatList } from "./chat-list";
import {
  DefaultEmptyScreen,
  type EmptyScreenProps,
} from "./default-empty-screen";
import React, { type FC } from "react";
import {
  UseCopilotChatOptions,
  useCopilotChat,
} from "../../core/hooks/use-copilot-chat";
import { ChatPanel } from "./chat-panel";
import { ChatScrollAnchor } from "./chat-scroll-anchor";

interface ChatComponentInjectionsProps {
  EmptyScreen?: FC<EmptyScreenProps>;
}

interface CopilotChatProps
  extends UseCopilotChatOptions,
    ChatComponentInjectionsProps {}

export function CopilotChat({
  id,
  initialMessages,
  makeSystemMessage,
  EmptyScreen = DefaultEmptyScreen,
}: CopilotChatProps) {
  const { visibleMessages, append, reload, stop, isLoading, input, setInput } =
    useCopilotChat({ id, initialMessages, makeSystemMessage });

  return (
    <div
      className="w-full h-full flex flex-col overflow-hidden box-border items-start"
    >
      <div className="pt-5 px-5 overflow-y-auto overflow-x-hidden w-full flex-grow">
        {visibleMessages.length > 0 ? (
          <div className="pl-0 pr-6 copilot-chat-list">
            <ChatList messages={visibleMessages} />
            <ChatScrollAnchor trackVisibility={isLoading} />
          </div>
        ) : (
          <EmptyScreen setInput={setInput} />
        )}
      </div>

      <div
        className="flex-shrink-0 w-full copilot-chat-panel"
      >
        <ChatPanel
          id={id}
          isLoading={isLoading}
          stop={stop}
          append={append}
          reload={reload}
          messages={visibleMessages}
          input={input}
          setInput={setInput}
          styles={"classic"}
        />
      </div>
    </div>
  );
}
