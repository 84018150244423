import React from "react";
import "./EnviromentButton.css";
import { useTranslation } from "react-i18next";

export default function EnviromentButton() {
  const isNotProduction = process.env.REACT_APP_ENV !== "production";
  const envShortName = process.env.REACT_APP_ENV_SHORTNAME || "DEV";
  const { i18n } = useTranslation();

  return (
    <>
      {isNotProduction && (
        <div className={`app ${i18n.dir()}`}>
          <p>{envShortName}</p>
        </div>
      )}
    </>
  );
}
