import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import CardComponent from "../Components/CardComponent";
import CardHeader from "../Components/CardHeader";
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Alert,
  CircularProgress,
  Stack,
  Zoom,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { FormControl } from "@mui/material";
import { protectedResources } from "../AuthModule";
import { DialogCopilotChat } from "../packages/copilotkit/ui/chat-components/dialog-copilot-chat";
import "./dailog_copilot.css";
import StepperComponent from "../Components/StepperComponent";
import { DialogDefaultEmptyScreen } from "../packages/copilotkit/ui/chat-components/dialog-default-empty-screen";
import NavigationButtons from "../Components/NavigationButtons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Message } from "../packages/copilotkit/core/hooks/use-feature-request-copilot-chat";

const FeatureRequest = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const [readOnly, setReadOnly] = useState(false);
  const [newSessionId, setNewSessionId] = useState<string>();
  const [stepResponses, setStepResponses] = useState<boolean[]>([]);
  const [inputsSentCount, setInputsSentCount] = useState(0);
  const [allowEffect, setAllowEffect] = useState(true);
  const questionSteps: any[] = ["", "", "", "", ""];

  //UI
  const [inputErrorEmail, setInputErrorEmail] = useState<boolean>(false);
  const [inputErrorSubject, setInputErrorSubject] = useState<boolean>(false);
  const [inputErrorMessage, setInputErrorMessage] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isDefaultMessageSendInstantly, setIsDefaultMessageSendInstantly] =
    useState<boolean>(true);
  const isDev = process.env.REACT_APP_ENV !== "production";
  const [isLastMessage, setIsLastMessage] = useState<boolean>(false);
  const [taskUrl, setTaskUrl] = useState<string>("");

  // Button handlers
  const handleEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEmail(event.target.value);
    const emailRegex = /\S+@\S+\.\S+/;
    if (
      event.target.value.trim() === "" ||
      !emailRegex.test(event.target.value)
    ) {
      setInputErrorEmail(true);
    } else {
      setInputErrorEmail(false);
    }
  };

  const handleApiResponse = (
    step: number,
    success: boolean,
    responseMessage?: Message,
  ) => {
    if (success) {
      const updatedResponses = [...stepResponses];
      updatedResponses[step] = true;
      setStepResponses(updatedResponses);
    }
    if (responseMessage?.isFinished) {
      setIsLastMessage(true);

      if (responseMessage?.name) {
        setTaskUrl(responseMessage.name);
      }
    }
  };

  function generateRandomSessionId() {
    return Math.random().toString(36).substring(2, 15);
  }

  const handleSubjectChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setSubject(event.target.value);
    if (event.target.value.trim() === "") {
      setInputErrorSubject(true);
    } else {
      setInputErrorSubject(false);
    }
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setMessage(event.target.value);
    if (event.target.value.trim() === "") {
      setInputErrorMessage(true);
    } else {
      setInputErrorMessage(false);
    }
  };

  const submitData = async () => {
    if (
      inputErrorEmail ||
      inputErrorSubject ||
      inputErrorMessage ||
      email === "" ||
      subject === "" ||
      message === ""
    ) {
      setErrorMessage(t("header.menu.feature.error_message"));
    } else {
      try {
        const emailData = {
          address: email,
          subject: subject,
          message: message,
        };
        setLoading(true);
        console.log(`Sending email with data: ${JSON.stringify(emailData)}`);
        const response = await fetch(
          `${protectedResources.apiTasks.endpoint}/email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(emailData),
          },
        );
        if (!response.ok) {
          const result = await response.json();
          console.log(`Error sending email: ${result.error}`);
          setErrorMessage(result.error);
        } else {
          console.log("Email sent successfully");
          setNewSessionId(generateRandomSessionId());
          setSubmitted(true);
        }
      } catch (error) {
        console.error("Error sending email:", error);
        setErrorMessage("Error sending email");
      } finally {
        setLoading(false);
      }
    }
  };

  const resetFocus = () => {
    if (document?.activeElement) {
      (document.activeElement as HTMLInputElement).blur();
    }
  };

  const handleNavigationClick = (navigationStep: number) => {
    setMessageCount(navigationStep);
  };

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  function shouldCreateWorkItem() {
    return !(email == "e2etest@test.de");
  }

  const handleOnBackClicked = async () => {
    await timeout(300);
    if (messageCount > 0) {
      setAllowEffect(false);
      setMessageCount(messageCount - 1);
    }
    setReadOnly(true);
    resetFocus();
    setTimeout(() => setAllowEffect(true), 100);
  };

  const handleOnNextClicked = () => {
    if (
      messageCount < inputsSentCount - 1 &&
      ((messageCount === 0 && inputsSentCount >= 2) ||
        (messageCount > 0 && inputsSentCount >= 1))
    ) {
      setAllowEffect(false);
      setMessageCount(messageCount + 1);

      if (messageCount === inputsSentCount - 2) {
        setReadOnly(false);
      }
    }
    setTimeout(() => setAllowEffect(true), 100);
  };

  const getStepContent = () => {
    if (!isLastMessage) {
      return (
        <CardComponent>
          <DialogCopilotChat
            messageCount={messageCount}
            styles={"for_feature_request"}
            sessionId={newSessionId}
            initialMessages={[]}
            EmptyScreen={DialogDefaultEmptyScreen}
            defaultMessage={message}
            isDefaultMessageSendInstantly={isDefaultMessageSendInstantly}
            setIsDefaultMessageSendInstantly={setIsDefaultMessageSendInstantly}
            setMessage={setMessage}
            onRespond={handleApiResponse}
            setInputsSentCount={setInputsSentCount}
            readOnly={readOnly}
            shouldCreateWorkItem={shouldCreateWorkItem()}
          />
        </CardComponent>
      );
    } else {
      return (
        <CardComponent>
          <DialogCopilotChat
            messageCount={messageCount}
            styles={"for_feature_request"}
            sessionId={newSessionId}
            initialMessages={[]}
            EmptyScreen={DialogDefaultEmptyScreen}
            defaultMessage={message}
            setMessage={setMessage}
            onRespond={handleApiResponse}
            setInputsSentCount={setInputsSentCount}
            readOnly={readOnly}
            shouldCreateWorkItem={shouldCreateWorkItem()}
          ></DialogCopilotChat>
        </CardComponent>
      );
    }
  };

  useEffect(() => {
    if (allowEffect) {
      if (
        stepResponses[messageCount] &&
        inputsSentCount >= (messageCount === 0 ? 2 : 1)
      ) {
        setMessageCount((prevCount) => prevCount + 1);
      }
    }
  }, [stepResponses, inputsSentCount, messageCount]);

  return (
    <Box>
      <Container maxWidth="md" sx={{ mt: 3 }}>
        <CardComponent>
          {!submitted ? (
            <CardHeader headline={t("header.menu.feature.title")} />
          ) : (
            <CardHeader headline={t("header.menu.feature.submitted_title")} />
          )}
          {!submitted && !isLastMessage && (
            <Typography
              variant="h6"
              style={{ fontSize: "19px", margin: "2vh 5vh -5vh 0" }}
            >
              {" "}
              {t("header.menu.feature.description")}
            </Typography>
          )}
          {submitted && !isLastMessage && (
            <Typography
              data-testid="feature-request-submitted"
              variant="h6"
              style={{ fontSize: "19px", margin: "2vh 5vh -5vh 0" }}
            >
              {" "}
              {isDev
                ? t("header.menu.feature.ai_description")
                : t("header.menu.feature.success_message_without_ai")}
            </Typography>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              flex: "1",
            }}
          >
            {!submitted && (
              <div>
                <Abstand height="9" />
                <FormControl
                  style={{
                    padding: "0 5vh 0 5vh",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <TextField
                    id="input-with-icon-textfield"
                    label={t("header.menu.feature.email_input")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    value={email}
                    onChange={handleEmailChange}
                    error={inputErrorEmail}
                    helperText={
                      inputErrorEmail
                        ? t("header.menu.feature.helper_text")
                        : ""
                    }
                  />
                  <Abstand height="3" />
                  <TextField
                    id="standard-basic"
                    label={t("header.menu.feature.subject")}
                    variant="standard"
                    value={subject}
                    onChange={handleSubjectChange}
                    error={inputErrorSubject}
                    helperText={
                      inputErrorSubject
                        ? t("start_project.task_list_step.helper_text")
                        : ""
                    }
                  />
                  <Abstand height="5" />
                  <TextField
                    id="outlined-multiline-static"
                    label={t("header.menu.feature.message")}
                    multiline
                    rows={4}
                    value={message}
                    onChange={handleMessageChange}
                    error={inputErrorMessage}
                    helperText={
                      inputErrorMessage
                        ? t("start_project.task_list_step.helper_text")
                        : ""
                    }
                  />
                </FormControl>
              </div>
            )}
            {!isLastMessage ? <Abstand height={"2"} /> : <></>}
            {!submitted && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1vh",
                }}
              >
                {errorMessage && (
                  <Alert severity="error" onClose={() => setErrorMessage("")}>
                    {errorMessage}
                  </Alert>
                )}
                <Button
                  variant="outlined"
                  style={{
                    margin: "0 5vh 0 0",
                    borderColor: "lightgrey",
                  }}
                  onClick={submitData}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    t("header.menu.feature.send")
                  )}
                </Button>
              </div>
            )}
            <div>
              {!isLastMessage ? <Abstand></Abstand> : <></>}
              {submitted && !isLastMessage && isDev && (
                <div>
                  <StepperComponent
                    activeStep={messageCount}
                    navigationSteps={questionSteps}
                    onNavigationClick={handleNavigationClick}
                    onBackClicked={handleOnBackClicked}
                    onSubmitClicked={() => {}}
                    onNextClicked={handleOnNextClicked}
                    isLastStep={isLastMessage}
                  ></StepperComponent>
                  <Abstand></Abstand>
                  {getStepContent()}
                  <Abstand></Abstand>
                  <NavigationButtons
                    activeStep={messageCount}
                    onBackClicked={handleOnBackClicked}
                    onNextClicked={handleOnNextClicked}
                    onSubmitClicked={() => {}}
                    isLastStep={false}
                    canProjectBeCreated={!(messageCount < inputsSentCount - 1)}
                    isLoading={!(messageCount < inputsSentCount - 1)}
                  />
                </div>
              )}
              {isLastMessage && (
                <Zoom in={true} style={{ transitionDelay: "500ms" }}>
                  <Container maxWidth="sm" sx={{ mt: 3 }}>
                    <CardComponent>
                      <Stack sx={{ alignItems: "center" }} spacing={3}>
                        <CheckCircleIcon
                          sx={{ width: "120px", height: "120px" }}
                          color="primary"
                        />
                        <Typography variant="h5">
                          {t("header.menu.feature.success_message")}
                        </Typography>
                        {taskUrl ? (
                          <div id={"feature-created-message"}>
                            {t("header.menu.feature.created_task")}:
                            <a href={taskUrl} target={"_blank"}>
                              {new URL(taskUrl).searchParams.get("workitem")}
                            </a>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </CardComponent>
                  </Container>
                </Zoom>
              )}
            </div>
          </div>
        </CardComponent>
      </Container>
    </Box>
  );
};

interface AbstandProps {
  height?: string;
}
function Abstand({ height = "5" }: AbstandProps) {
  const abstandStyle = {
    height: height + "vh",
  };
  return <div style={abstandStyle}></div>;
}

export default FeatureRequest;
