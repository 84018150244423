import { nanoid } from "nanoid";
import {
  CompletionCreateParams,
  CreateChatCompletionRequestMessage,
} from "openai/resources/chat";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import useSWR from "swr";
import useChatApi from "../../../../Api/ChatApi";
import { t } from "i18next";

export namespace ChatCompletionMessage {
  export interface FunctionCall {
    arguments: string;
    name: string;
  }
}

export type Message = {
  id: string;
  createdAt?: Date;
  content: string;
  role: "system" | "user" | "assistant" | "function";
  name?: string;
  function_call?: string | ChatCompletionMessage.FunctionCall;
  traceId?: string;
};

export type FunctionCallHandler = (
  chatMessages: Message[],
  functionCall: ChatCompletionMessage.FunctionCall,
) => Promise<ChatRequest | void>;

type RequestOptions = {
  headers?: Record<string, string> | Headers;
  body?: object;
};

type ChatRequest = {
  messages: Message[];
  options?: RequestOptions;
  functions?: Array<CompletionCreateParams.Function>;
  function_call?: CreateChatCompletionRequestMessage.FunctionCall;
};

export type UseChatOptions = {
  api?: string;
  id?: string;
  initialMessages?: Message[];
  initialInput?: string;
  sessionId?: string;
  isDefaultMessageSendInstantly?: boolean;
  setIsDefaultMessageSendInstantly?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  readOnly?: boolean;

  experimental_onFunctionCall?: FunctionCallHandler;
  onResponse?: (response: Response) => void | Promise<void>;
  onFinish?: (message: Message) => void;
  onError?: (error: Error) => void;

  credentials?: RequestCredentials;
  headers?: Record<string, string> | Headers;
  body?: object;
  sendExtraMessageFields?: boolean;
  styles?: "classic" | "for_dialog" | "for_feature_request";
};

export type CreateMessage = Omit<Message, "id"> & {
  id?: Message["id"];
};

export type ChatRequestOptions = {
  options?: RequestOptions;
  functions?: Array<CompletionCreateParams.Function>;
  function_call?: CreateChatCompletionRequestMessage.FunctionCall;
};

export type UseChatHelpers = {
  messages: Message[];
  error: undefined | Error;
  input: string;
  metadata?: Object;
  isLoading: boolean;
  data?: any;

  append: (
    message: Message | CreateMessage,
    chatRequestOptions?: ChatRequestOptions,
  ) => Promise<string | null | undefined>;
  reload: (
    chatRequestOptions?: ChatRequestOptions,
  ) => Promise<string | null | undefined>;
  stop: () => void;
  setMessages: (messages: Message[]) => void;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  handleInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    chatRequestOptions?: ChatRequestOptions,
  ) => void;
};

export enum StreamStringPrefixes {
  "text" = 0,
  "function_call" = 1,
  "data" = 2,
}

var getStreamStringTypeAndValue = (line: string) => {
  const firstSeperatorIndex = line.indexOf(":");
  const prefix = line.slice(0, firstSeperatorIndex);
  const type = Object.keys(StreamStringPrefixes).find(
    (key: string) =>
      Object.keys(StreamStringPrefixes).indexOf(key) === Number(prefix),
  );
  const val = line.slice(firstSeperatorIndex + 1);
  let parsedVal = val;
  if (!val) {
    return { type, value: "" };
  }
  try {
    parsedVal = JSON.parse(val);
  } catch (e) {
    console.error("Failed to parse JSON value:", val);
  }
  return { type, value: parsedVal };
};

function createChunkDecoder(complex?: boolean) {
  const decoder = new TextDecoder();
  if (!complex) {
    return function (chunk: any) {
      if (!chunk) return "";
      return decoder.decode(chunk, { stream: true });
    };
  }
  return function (chunk: any) {
    const decoded = decoder.decode(chunk, { stream: true }).split("\n");
    return decoded.map(getStreamStringTypeAndValue).filter(Boolean);
  };
}

export function useChat({
  api = "/api/chat",
  id,
  initialMessages = [],
  initialInput = "",
  sendExtraMessageFields,
  experimental_onFunctionCall,
  onResponse,
  onFinish,
  onError,
  credentials,
  headers,
  body,
}: UseChatOptions = {}): UseChatHelpers {
  const { createChat } = useChatApi();
  const hookId = useId();
  const chatId = id || hookId;
  const { data: messages, mutate } = useSWR([api, chatId], null, {
    fallbackData: initialMessages,
  });
  const { data: isLoading = false, mutate: mutateLoading } = useSWR(
    [chatId, "loading"],
    null,
  );
  const { data: streamData, mutate: mutateStreamData } = useSWR(
    [chatId, "streamData"],
    null,
  );

  const sessionIdKey = "ai-function-session-id";
  // Generate or retrieve a unique sessionId
  const [sessionId, setSessionId] = useState(() => {
    // Check if a sessionId exists in sessionStorage and there are messages apart from the initial system message
    const storedChatId = sessionStorage.getItem(sessionIdKey);
    if (storedChatId && messages.length > 1) {
      return storedChatId;
    }
    // Generate a new sessionId if none exists
    const newSessionId = nanoid();
    sessionStorage.setItem(sessionIdKey, newSessionId);
    return newSessionId;
  });

  const messagesRef = useRef(messages || []);
  useEffect(() => {
    messagesRef.current = messages || [];
  }, [messages]);
  const abortControllerRef = useRef(null) as any;
  const extraMetadataRef = useRef({
    credentials,
    headers,
    body,
  });
  useEffect(() => {
    extraMetadataRef.current = {
      credentials,
      headers,
      body,
    };
  }, [credentials, headers, body]);
  const [error, setError] = useState();
  const triggerRequest = useCallback(
    async (chatRequest: any) => {
      try {
        mutateLoading(true);
        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        while (true) {
          const messagesAndDataOrJustMessage = await getStreamedResponse(
            chatRequest,
            mutate,
            extraMetadataRef,
            messagesRef,
            onFinish,
            sendExtraMessageFields,
            createChat,
            sessionId,
          );
          if ("messages" in messagesAndDataOrJustMessage) {
            let hasFollowingResponse = false;
            for (const message of messagesAndDataOrJustMessage.messages as Message[]) {
              if (
                message.function_call === void 0 ||
                typeof message.function_call === "string"
              ) {
                continue;
              }
              hasFollowingResponse = true;
              if (experimental_onFunctionCall) {
                const functionCall = message.function_call;
                const functionCallResponse = await experimental_onFunctionCall(
                  messagesRef.current,
                  functionCall,
                );
                if (functionCallResponse === void 0) break;
                chatRequest = functionCallResponse;
              }
            }
            if (!hasFollowingResponse) {
              break;
            }
          } else {
            const streamedResponseMessage = messagesAndDataOrJustMessage;
            if (
              streamedResponseMessage.function_call === void 0 ||
              typeof streamedResponseMessage.function_call === "string"
            ) {
              break;
            }
            if (experimental_onFunctionCall) {
              const functionCall = streamedResponseMessage.function_call;
              const functionCallResponse = await experimental_onFunctionCall(
                messagesRef.current,
                functionCall,
              );
              if (functionCallResponse === void 0) break;
              chatRequest = functionCallResponse;
            }
          }
        }
        abortControllerRef.current = null;
      } catch (err: Error | any) {
        if (err.name === "AbortError") {
          abortControllerRef.current = null;
          return null;
        }
        if (onError && err instanceof Error) {
          onError(err);
        }
        setError(err);
      } finally {
        mutateLoading(false);
      }
    },
    [
      mutate,
      mutateLoading,
      api,
      extraMetadataRef,
      onResponse,
      onFinish,
      onError,
      setError,
      mutateStreamData,
      streamData,
      sendExtraMessageFields,
      experimental_onFunctionCall,
      messagesRef.current,
      abortControllerRef.current,
    ],
  );
  const append = useCallback(
    async (message: any, { options, functions, function_call }: any = {}) => {
      if (!message.id) {
        message.id = nanoid();
      }
      const chatRequest = {
        messages: messagesRef.current.concat(message),
        options,
        ...(functions !== void 0 && { functions }),
        ...(function_call !== void 0 && { function_call }),
      };
      return triggerRequest(chatRequest);
    },
    [triggerRequest],
  );
  const reload = useCallback(
    async ({ options, functions, function_call }: any = {}) => {
      if (messagesRef.current.length === 0) return null;
      const lastMessage = messagesRef.current[messagesRef.current.length - 1];
      if (lastMessage.role === "assistant") {
        const chatRequest2 = {
          messages: messagesRef.current.slice(0, -1),
          options,
          ...(functions !== void 0 && { functions }),
          ...(function_call !== void 0 && { function_call }),
        };
        return triggerRequest(chatRequest2);
      }
      const chatRequest = {
        messages: messagesRef.current,
        options,
        ...(functions !== void 0 && { functions }),
        ...(function_call !== void 0 && { function_call }),
      };
      return triggerRequest(chatRequest);
    },
    [triggerRequest],
  );
  const stop = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  }, []);
  const setMessages = useCallback(
    (messages2: any) => {
      mutate(messages2, false);
      messagesRef.current = messages2;
    },
    [mutate],
  );
  const [input, setInput] = useState(initialInput);

  const handleSubmit = useCallback(
    (
      e: FormEvent<HTMLFormElement>,
      { options, functions, function_call }: ChatRequestOptions = {},
      metadata?: Object,
    ) => {
      if (metadata) {
        extraMetadataRef.current = {
          ...extraMetadataRef.current,
          ...metadata,
        };
      }
      e.preventDefault();
      if (!input) return;
      append(
        {
          content: input,
          role: "user",
          createdAt: new Date(),
        },
        { options, functions, function_call },
      );
      setInput("");
    },
    [input, append],
  );
  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };
  return {
    messages: messages || [],
    error,
    append,
    reload,
    stop,
    setMessages,
    input,
    setInput,
    handleInputChange,
    handleSubmit,
    isLoading,
    data: streamData,
  };
}

var getStreamedResponse = async (
  chatRequest: any,
  mutate: any,
  extraMetadataRef: any,
  messagesRef: any,
  onFinish: any,
  sendExtraMessageFields: any,
  createChat: any,
  sessionId: any,
) => {
  var _a, _b;
  const previousMessages = messagesRef.current;
  mutate(chatRequest.messages, false);

  const req = JSON.stringify({
    messages: sendExtraMessageFields
      ? (chatRequest.messages as Message[])
      : (chatRequest.messages as Message[]).map(
        ({ role, content, name, function_call }) => ({
          role,
          content,
          ...(name !== void 0 && { name }),
          ...(function_call !== void 0 && {
            function_call,
          }),
        }),
      ),
    ...extraMetadataRef.current.body,
    ...((_a = chatRequest.options) == null ? void 0 : _a.body),
    ...(chatRequest.functions !== void 0 && {
      functions: chatRequest.functions,
    }),
    ...(chatRequest.function_call !== void 0 && {
      function_call: chatRequest.function_call,
    }),
  });

  const createdAt = new Date();
  const replyId = nanoid();

  var temp = JSON.parse(req);
  const messages = temp.messages;
  const copilotkit_manually_passed_function_descriptions =
    temp.copilotkit_manually_passed_function_descriptions;

  const res = await createChat(
    messages,
    copilotkit_manually_passed_function_descriptions,
    sessionId,
  ).catch(() => {
    let responseMessage: Message = {
      id: replyId,
      createdAt,
      content: t("copilot.core.copilotError_message"),
      role: "assistant",
    };
    mutate([...chatRequest.messages, { ...responseMessage }], false);
    return responseMessage;
  });

  const parsedResponse = res.body;
  let responseMessage: Message = {
    id: replyId,
    createdAt,
    content: "",
    role: "assistant",
    traceId: parsedResponse.trace_id ?? undefined,
  };
  const reMessage = parsedResponse.choices[0].message;
  if (reMessage.function_call) {
    responseMessage["function_call"] = reMessage.function_call;
    responseMessage["content"] = t("copilot.core.finish");
  } else {
    responseMessage["content"] = reMessage.content;
  }

  mutate([...chatRequest.messages, { ...responseMessage }], false);

  if (onFinish) {
    onFinish(responseMessage);
  }
  return responseMessage;
};
