import React, { useEffect, useState } from "react";
import { protectedResources } from "../AuthModule";
import StartProjectView from "../views/StartProjectView";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import AuthenticatedTemplateWrapper from "../Components/AuthenticatedTemplateWrapper";
import UnauthenticatedTemplateWrapper from "../Components/UnauthenticatedTemplateWrapper";
import Login from "./Login";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { CircularProgress, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import CardComponent from "../Components/CardComponent";

const TaskListView = () => {
  // Login Request / Scopes
  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiTasklists.scopes.read,
  }, () => {
    return {
      isLoading: false,
      data: {
        status: 200,
        body: [{
          "displayName": "TESTLIST",
          "isOwner": "TESTOWNER",
          "isShared": "false",
          "wellknownListName": "My wellknown list",
          "id": "TESTID",
          "type": "import",
        }]
      },
      error: null,
      execute: () => {
        return Promise.resolve({
          status: 200,
          body: [{
            "displayName": "TESTLIST",
            "isOwner": "TESTOWNER",
            "isShared": "false",
            "wellknownListName": "My wellknown list",
            "id": "TESTID",
            "type": "import",
          }]
        })
      }
    }
  });

  const [todoLists, setTodoLists] = useState<any|null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!todoLists) {
      execute("GET", protectedResources.apiTasklists.endpoint).then(
        (response) => {
          if (response?.body !== undefined) {
            setTodoLists(response.body);
          }
        },
      );
    }
  }, [execute, todoLists]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {todoLists ? (
        <StartProjectView taskListData={todoLists} />
      ) : (
        <Container maxWidth="md" sx={{ mt: 3 }}>
          <CardComponent>
            <Stack spacing={2} sx={{ alignItems: "center", p: 5 }}>
              <CircularProgress />
              <Typography variant="h5">
                {t("start_project.start_project_loading")}
              </Typography>
            </Stack>
          </CardComponent>
        </Container>
      )}
    </>
  );
};

const StartProject = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("header.menu.projects");
  }, [i18next.language]);

  return (
    <>
      <AuthenticatedTemplateWrapper>
        <TaskListView />
      </AuthenticatedTemplateWrapper>
      <UnauthenticatedTemplateWrapper>
        <Login />
      </UnauthenticatedTemplateWrapper>
    </>
  );
};

export default StartProject;
