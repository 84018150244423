import CardButton from "./CardButton";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import UnauthenticatedTemplateWrapper from "./UnauthenticatedTemplateWrapper";
import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { loginRequest } from "../AuthModule";
import { useMsal } from "@azure/msal-react";

const UnauthenticatedUserMenu = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <Box sx={{ mr: 5 }}>
      <UnauthenticatedTemplateWrapper>
        <CardButton
          title={t("header.menu.user_menu.log_in")}
          icon={<AccountCircleOutlinedIcon sx={{ fontSize: 30 }} />}
          onClick={() => handleLoginRedirect()}
          testId="header.menu.user_menu"
        />
      </UnauthenticatedTemplateWrapper>
    </Box>
  );
};

export default UnauthenticatedUserMenu;
