import { useState, useCallback } from "react";

import {
  AccountInfo,
  InteractionType,
  RedirectRequest,
} from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { isLocalAndAuthorizationDisabled } from "./../Utils/environment";
import { isFeatureEnabledMockFetch } from "../Components/TaskListStep";

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */

interface ResponseData<T = any> {
  body: T;
  status: number;
}

interface FileUploadRequest {
  file: any;
  Tasklist: string;
}

type UseFetchWithMsalResult<T> = {
  isLoading: boolean,
  error: any,
  data: ResponseData<T | null>,
  execute: (method: string, endpoint: string, data?: any, file?: FileUploadRequest) => Promise<{
    body: T | null;
    status: number;
  } | undefined>
}

function useFetchWithMsal<T = any>(msalRequest: RedirectRequest, getResultIfMocked: (r: RedirectRequest) => UseFetchWithMsalResult<T>): UseFetchWithMsalResult<T>
function useFetchWithMsal<T = any>(msalRequest: RedirectRequest): UseFetchWithMsalResult<T>
function useFetchWithMsal<T = any>(msalRequest: RedirectRequest, getResultIfMocked?: (r: RedirectRequest) => UseFetchWithMsalResult<T>): UseFetchWithMsalResult<T> {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<ResponseData<T | null>>({ body: null, status: 0 });

  const msalResult = useMsalAuthentication(InteractionType.Redirect, {
    ...msalRequest,
    account: instance.getActiveAccount() as AccountInfo,
    redirectUri: "/redirect",
  });

  const isRunningLocalAndAuthorizationIsDisabled =
    isLocalAndAuthorizationDisabled();
  const { result, error: msalError } = isRunningLocalAndAuthorizationIsDisabled
    ? {
      result: null,
      error: null,
    }
    : msalResult;

  /**
   * Execute a fetch request with the given options
   * @param {string} method: GET, POST, PUT, DELETE
   * @param {String} endpoint: The endpoint to call
   * @param {Object} data: The data to send to the endpoint, if any
   * @param {FileUploadRequest} file: The data to send to the endpoint, if any
   * @returns JSON response
   */
  const execute = async (
    method: string,
    endpoint: string,
    data?: any,
    file?: FileUploadRequest,
  ) => {
    if (!isRunningLocalAndAuthorizationIsDisabled && msalError) {
      console.error("Msal error:", msalError);
      setError(msalError as any);
      return;
    }

    try {
      let response;

      const headers = new Headers();
      if (!isRunningLocalAndAuthorizationIsDisabled) {
        let token = (await instance.acquireTokenSilent(msalRequest))
          .accessToken;
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
      }

      let body: any;

      if (data) {
        headers.append("Content-Type", "application/json");
        body = JSON.stringify(data);
      }

      if (file) {
        if (file.Tasklist) {
          endpoint = endpoint + "?tasklist=" + file.Tasklist;
        }

        body = file.file;
      }

      let options = {
        method: method,
        headers: headers,
        body: body ? body : null,
      };

      setIsLoading(true);

      response = await fetch(endpoint, options);
      const status = response.status;
      const responseBody = (await response.json()) as T;
      console.log("FetchResponse", response);
      setData({ body: responseBody, status: status });

      setIsLoading(false);
      return { body: responseBody, status: status };
    } catch (e: any) {
      console.error(e)
      setError(e);
      setIsLoading(false);
      throw e;
    }
  }

  // Copied from original return => If you go this route (which is perfectly fine in general) you MUST respect the dependency array - currently you are missing dependencies here!
  const execInResult = useCallback(execute, [
    isRunningLocalAndAuthorizationIsDisabled,
    msalError,
    result,
  ]) // to avoid infinite calls when inside a `useEffect` 

  return typeof getResultIfMocked !== "undefined" && isFeatureEnabledMockFetch() ?
    getResultIfMocked(msalRequest) :
    {
      isLoading,
      error,
      data,
      execute: execInResult
    };
};


export default useFetchWithMsal;
