import React, { useState } from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CardButton from "./CardButton";
import {
  Box,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import navigateOrReload from "../Utils/navigationUtils";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

type TutorialMenuProps = {
  isOpen: boolean;
  handleOnClick: () => void;
};

const baseUrl =
  process.env.REACT_APP_ENV === "prod"
    ? "https://danifyprodappdata.blob.core.windows.net/tubes"
    : "https://danifydevappdata.blob.core.windows.net/tubes";

const dynamicTutorials = [
  {
    key: "what_is_danify_de",
    language: "(de-de)",
    url: `${baseUrl}/danify_copilot_materna.mp4`,
  },
  {
    key: "what_is_danify_en",
    language: "(en-us)",
    url: `${baseUrl}/what_is_Danify.mp4`,
  },
  {
    key: "how_to_create_a_new_project",
    language: "(en-us)",
    url: `${baseUrl}/how_to_create_a_new_project.mp4`,
  },
  {
    key: "how_to_play_the_danify_song",
    language: "(en-gb)",
    url: `${baseUrl}/how_to_play_the_danify_song.mp4`,
  },
  {
    key: "what_is_markdown",
    language: "(en-aus)",
    url: `${baseUrl}/what_is_markdown.mp4`,
  },
  {
    key: "danify_project_sharing",
    language: "(de-de)",
    url: `${baseUrl}/danify_project_teilen.mp4`,
  },
];

const TutorialMenu = (props: TutorialMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const menuOpen = Boolean(anchorEl);
  const subMenuOpen = Boolean(subMenuAnchorEl);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePageChange = (
    key: string,
    url: string,
    isVideo: boolean,
  ): void => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
    props.handleOnClick();
    if (isVideo) {
      navigate("/tutorial-video", { state: { key, url } });
    } else {
      navigate(url);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    props.handleOnClick();
  };

  const handleSubMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
    props.handleOnClick();
  };

  const handleDownload = async () => {
    setAnchorEl(null);
    props.handleOnClick();
    fetch("MarkdownTutorial.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "MarkdownTutorial.pdf";
        link.click();
      });
    });
  };

  return (
    <Stack direction="column">
      <Box
        aria-controls={menuOpen ? "main-menu" : undefined}
        aria-expanded={menuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <CardButton
          title={t("header.menu.tutorial")}
          description={t("header.menu.view_tutorials")}
          icon={<QuestionMarkIcon sx={{ fontSize: 30 }} />}
          onClick={() => {}}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={props.isOpen}
        onClose={handleClose}
        sx={{
          mt: 1,
        }}
        PaperProps={{
          style: {
            paddingLeft: 10,
            paddingRight: 10,
            margin: 0,
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
            borderRadius: 10,
            minWidth: "150px",
          },
        }}
        id="main-menu"
      >
        <MenuList>
          <MenuItem onClick={handleDownload}>
            {t("header.menu.markdown_tutorial")}
          </MenuItem>
          <MenuItem
            onClick={() =>
              handlePageChange("playground", "/PlaygroundPage", false)
            }
          >
            {t("header.menu.playground.title")}
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={handleSubMenuClick}
            aria-controls={subMenuOpen ? "sub-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={subMenuOpen ? "true" : undefined}
          >
            {t("header.menu.tubes")}
            <ArrowRightIcon />
          </MenuItem>
        </MenuList>
      </Menu>
      <Menu
        anchorEl={subMenuAnchorEl}
        open={subMenuOpen}
        onClose={handleClose}
        sx={{
          mt: 1,
          ml: 2,
        }}
        PaperProps={{
          style: {
            paddingLeft: 10,
            paddingRight: 10,
            margin: 0,
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
            borderRadius: 10,
            minWidth: "150px",
          },
        }}
        id="sub-menu"
      >
        <MenuList>
          {dynamicTutorials.map((tutorial, index) => (
            <MenuItem
              key={index}
              onClick={() => handlePageChange(tutorial.key, tutorial.url, true)}
            >
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography sx={{ marginRight: "20px" }}>
                  {t(`header.menu.tutorials.${tutorial.key}`)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {tutorial.language}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Stack>
  );
};

export default TutorialMenu;
