import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { PlayArrow, Stop } from "@mui/icons-material";

const PlayButton = () => {
  const [isPlaying, setIsPlaying ] = useState(false);
  const [audio] = useState(new Audio('./DANify-Song.mp3'));
  const playButtonCss = { position: "fixed", left: 8, bottom: 8, color: "#aaaaaa" };
  const { t } = useTranslation();
  const showDefaultError = () => {
    toast.error(t("copilot.core.copilotError_message"));
  }

  useEffect(() => {
    audio.addEventListener("ended", function(){
      setIsPlaying(false);
    });
  }, []);

  const togglePlay = () => {
    try {
      if (isPlaying) {
        setIsPlaying(false);
        audio.pause();
        audio.currentTime = 0;
      } else {
        setIsPlaying(true);
        audio.play().catch(() => { showDefaultError(); });
      }
    } catch (e) {
      showDefaultError();
    }
  }

  return (
    <IconButton id={'danify-play-btn'} sx={playButtonCss} onClick={() => {
      togglePlay();
    }}>
      { isPlaying ? <Stop id={'danify-stop-icon'} /> : <PlayArrow id={'danify-play-icon'} /> }
    </IconButton>
  );
}

export default PlayButton;