import React, { useState, useEffect } from "react";
import {
  alpha,
  Box,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import useOrganisatorId from "../hooks/useOrganisatorId";
import { useTranslation } from "react-i18next";

interface ProjectListItemProps {
  id: string;
  name: string;
  organisatorId: string;
  slides: Slide[];
  checked?: boolean;
  testId?: string;
  onChange?: (id: string, checked: boolean) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}
interface Slide {
  title: string;
  name: string;
}

const ProjectListItemCheck = (props: ProjectListItemProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(props.checked || false);
  const userId = useOrganisatorId();
  const { t } = useTranslation();

  useEffect(() => {
    setIsChecked(props.checked || false);
  }, [props.checked]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    props.onChange?.(props.id, event.target.checked);
  };

  function isAssignedToProject() {
    if (!props) {
      return false;
    }

    return props.organisatorId !== userId && userId !== undefined;
  }

  return (
    <Box>
      <ListItem
        data-testid={props.testId}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.3)",
          backgroundColor: (theme) => alpha(theme.palette.background.paper, 1),
          mb: 1,
        }}
        disablePadding
      >
        <ListItemButton onClick={props.onClick} disableRipple>
          <Box
            style={{
              width: "100%",

              display: "flex",
              flexDirection: "column",
            }}
          >
            {isAssignedToProject() && (
              <Typography
                variant="caption"
                style={{
                  fontSize: "0.5em",
                  color: "grey",
                  marginBottom: 0,
                }}
              >
                Shared
              </Typography>
            )}
            <ListItemText data-testid={props.name} primary={props.name} />
            <ListItemSecondaryAction>
              {props.organisatorId == useOrganisatorId() ? (
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              ) : (
                <Tooltip title={t("my_projects.delete_check_disabled_note")}>
                  <span>
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      disabled={true}
                    />
                  </span>
                </Tooltip>
              )}
            </ListItemSecondaryAction>
          </Box>
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default ProjectListItemCheck;
