import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

interface SaveTasklistDialogProps {
  showDialog: boolean;
  setShowOverrideDialog: any;
  saveTaskList: any;
}

const SaveTasklistDialog = ({
  showDialog,
  setShowOverrideDialog,
  saveTaskList,
}: SaveTasklistDialogProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setShowOverrideDialog(false);
  };

  return (
    <Dialog
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("import.save_text_dialog.headline")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("import.save_text_dialog.dialog_description")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("import.save_text_dialog.no")}</Button>
        <Button
          onClick={() => {
            handleClose();
            saveTaskList();
          }}
          autoFocus
        >
          {t("import.save_text_dialog.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveTasklistDialog;
