import { Step, StepButton, StepLabel, Stepper } from "@mui/material";
import React from "react";

interface StepperProps {
  activeStep: number;
  navigationSteps: { title: string }[];
  onNavigationClick: (step: number) => void;
  onBackClicked: () => void;
  onSubmitClicked: () => void;
  onNextClicked: () => void;
  isLastStep: boolean;
}

const StepperComponent = ({
  activeStep,
  navigationSteps,
  onNavigationClick,
  isLastStep = false,
}: StepperProps) => {
  return (
    <Stepper activeStep={activeStep} data-testid={"stepper-data-test-id"}>
      {navigationSteps.map((step, index) => (
        <Step key={step.title}>
          <StepButton
            disabled={isLastStep}
            data-testid={`step-index-${index}`}
            onClick={() => onNavigationClick(index)}
          >
            <StepLabel style={{ wordBreak: "break-all" }}>
              {step.title}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperComponent;
