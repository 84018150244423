import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";

interface presentationEvent {
  title: string
  base64File?: string
}

const SocketEvents = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [presentationEvent, setPresentationEvent] = useState<presentationEvent>();

  const { t } = useTranslation();

  const onConnect = () => setIsConnected(true);
  const onDisconnect = () => setIsConnected(false);
  const onPresentationEvent = (value: presentationEvent) => setPresentationEvent(value);

  const PresentationDownloadButton = () => {
    return (
      <IconButton onClick={() => {
        const downloadLink = document.createElement("a");
        downloadLink.href = `data:file/pptx;base64,${presentationEvent?.base64File}`;
        downloadLink.download = `${presentationEvent?.title}.ppt`;
        downloadLink.click();
      }}>
        <DownloadIcon/>
      </IconButton>
    )
  }

  useEffect(() => {
    console.log('Websocket connected: ' + isConnected)
  }, [isConnected]);

  useEffect(() => {
    if (presentationEvent) {
      if (presentationEvent.title && !presentationEvent.base64File) {
        toast(t('presentations.generating_finished_message_saved'))
      }
      else if (presentationEvent.title && presentationEvent.base64File) {
        toast(<>{t('presentations.generating_finished_message')} <PresentationDownloadButton/></>, {
          autoClose: false
        });
      }
      else toast(t('presentations.generating_error_message'));
    }
  }, [presentationEvent]);

  return (
    {
      onConnect,
      onDisconnect,
      onPresentationEvent
    }
  );
}

export default SocketEvents;
