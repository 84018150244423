import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import CardButton from "./CardButton";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { useTranslation } from "react-i18next";
import navigateOrReload  from "../Utils/navigationUtils";

type NavigationMenuProps = {
  onClick: () => void;
  isSubMenuOpen?: boolean;
};

const NavigationMenu = (props: NavigationMenuProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      sx={{
        width: "100vw",
        ml: 5,
      }}
    >
      <CardButton
        title={t("header.menu.homepage")}
        description={t("header.menu.homepage_description")}
        icon={<CottageOutlinedIcon sx={{ fontSize: 30 }} />}
        onClick={() => {
          if (props.isSubMenuOpen) {
            props.onClick();
          }
          navigateOrReload(navigate, "/");
        }}
      />
      <CardButton
        title={t("header.menu.projects")}
        description={t("header.menu.projects_description")}
        icon={<ChecklistOutlinedIcon sx={{ fontSize: 30 }} />}
        onClick={() => {
          if (props.isSubMenuOpen) {
            props.onClick();
          }
          navigateOrReload(navigate, "/startProject");
        }}
      />
      <CardButton
        title={t("header.menu.my_projects")}
        description={t("header.menu.my_projects_description")}
        icon={<ListAltOutlinedIcon sx={{ fontSize: 30 }} />}
        onClick={() => {
          if (props.isSubMenuOpen) {
            props.onClick();
          }
          navigateOrReload(navigate, "/showMyProjects");
        }}
      />
    </Stack>
  );
};

export default NavigationMenu;
