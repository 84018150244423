import { Task } from "../Utils/Types/Task";
import { Alert, Divider, Grow, List, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import TaskListItem from "./TaskListItem";
import Button from "@mui/material/Button";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import AuthenticatedTemplateWrapper from "./AuthenticatedTemplateWrapper";
import UnauthenticatedTemplateWrapper from "./UnauthenticatedTemplateWrapper";
import { useMakeCopilotActionable } from "../packages/copilotkit/core/hooks/use-make-copilot-actionable";
import { useMakeCopilotReadable } from "../packages/copilotkit/core/hooks/use-make-copilot-readable";
import { protectedResources } from "../AuthModule";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { Todo } from "../Utils/Types/Todo";
import { toast } from "react-toastify";

interface ProjectCreation {
  name: string;
  emails: string[];
  listType: "import" | "todo";
  tasks: Task[];
  visibility?: string;
  isHideFinishedTasks: boolean;
  emailNotificationsEnabled: boolean;
  host: string;
}

const TaskList = (props: {
  tasks: Task[];
  tasksCollapsible: boolean;
  extended: boolean;
  readonly: boolean;
  showCkeckboxes: boolean;
  title?: string;
  visibility?: string;
  isHideFinishedTasks: boolean;
  copyTask: Function;
  canCopySharedProject: boolean;
}) => {
  const copilotPointer = useMakeCopilotReadable(props.title ?? "");

  const [tasksWithState, setTaskCollapseState] = useState(
    props.tasks
      .sort((a, b) => +a.id - +b.id)
      .map((t) => ({ task: t, open: false })),
  );
  const [isListCollapsible, setIsListCollapsible] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    setTaskCollapseState((prevState) =>
      prevState.map((t) => ({ ...t, open: isListCollapsible })),
    );
  }, [isListCollapsible]);

  const updateIsListCollapsible = () => {
    // Tasks without content (i.e. notes or subtasks) cannot be expanded or collapsed anyways
    const tasksWithContent = tasksWithState.filter(
      (t) => t.task.note !== "" || t.task.checkListItems.length > 0,
    );
    const openTasksWithContent = tasksWithContent.filter((t) => t.open);
    const closedTasksWithContent = tasksWithContent.filter((t) => !t.open);

    if (tasksWithContent.length === openTasksWithContent.length) {
      setIsListCollapsible(true);
    } else if (tasksWithContent.length === closedTasksWithContent.length) {
      setIsListCollapsible(false);
    }
  };

  const handleTaskStateChange = (index: number, newState: boolean) => {
    setTaskCollapseState((prevState) => {
      const updatedTasks = [...prevState];
      updatedTasks[index].open = newState;
      return updatedTasks;
    });
  };

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.apiTasklists.scopes.read,
  });

  function copyTaskList(
    recipientEmailsArray: string[],
    newTaskListName: string,
  ) {
    if (recipientEmailsArray.length < 1) {
      copyTaskListWithDefault(newTaskListName, ["not@specif.ied"]);
    } else if (props.title === null) {
      copyTaskListWithDefault(
        `Kopie von einer unbekannten Liste`,
        recipientEmailsArray,
      );
    } else {
      copyTaskListWithDefault(`${newTaskListName}`, recipientEmailsArray);
    }
  }

  function copyTaskListWithDefault(
    newTaskListName: string,
    recipientEmailsArray: string[],
  ) {
    getTasklists().then((response: Todo[] | null) => {
      if (response === null) {
        console.info("No TaskLists exists");
        return;
      }

      var taskTitleStartIndex = props.title!.indexOf(" - ");
      var taskListTitle = props.title!.slice(
        -(taskTitleStartIndex === -1 ? 0 : taskTitleStartIndex),
      );

      var todo = response.find((t) => t.displayName === taskListTitle);

      if (todo === undefined) {
        console.info("TaskLists Name not found in own TaskLists exists");
        return;
      }
      const host = window.location.protocol + "//" + window.location.host;

      const data: ProjectCreation = {
        name: newTaskListName,
        emails: recipientEmailsArray,
        listType: todo.type,
        tasks: props.tasks,
        visibility: props.visibility,
        isHideFinishedTasks: props.isHideFinishedTasks,
        emailNotificationsEnabled: false,
        host: host,
      };

      execute(
        "POST",
        protectedResources.apiProject.endpoint + "/" + todo.id,
        data,
      ).then((response: any) => {
        if (response.status === 409) {
          console.error("error by creating new project");
        } else if (response.status === 201) {
          console.info("Tasklist successfully copied");
        }
      });
    });
  }

  async function getTasklists(): Promise<Todo[] | null> {
    return await execute("GET", protectedResources.apiTasklists.endpoint).then(
      (response: any) => {
        if (response?.body !== undefined) {
          return response.body;
        } else {
          return null;
        }
      },
    );
  }

  function togglePlayDanifySong() {
    document.getElementById("danify-play-btn")!.click();
  }

  useMakeCopilotActionable(
    {
      name: "playDanifySong",
      description: "play the Danify song",
      argumentAnnotations: [],
      implementation: async () => togglePlayDanifySong(),
    },
    [],
  );

  useMakeCopilotActionable(
    {
      name: "stopDanifySong",
      description: "stop the Danify song",
      argumentAnnotations: [],
      implementation: async () => togglePlayDanifySong(),
    },
    [],
  );

  useMakeCopilotActionable(
    {
      name: "manageCopyTaskList",
      description:
        "the function copy or imports the current task list and create a new one with the given email addresses and task list name.",
      argumentAnnotations: [
        {
          name: "emailAddresses",
          type: "array",
          items: { type: "string" },
          description:
            "The attribute is an array that consists of the email addresses with which the list is to be shared",
          required: true,
        },
        {
          name: "tasklist name",
          type: "string",
          description: "the name of the new tasklist",
          required: true,
        },
      ],
      implementation: async (
        recipientEmailsArray = [],
        newTaskListName: string,
      ) => {
        if (props.canCopySharedProject) {
          props.copyTask().catch(() => {
            toast.error(t("view_project.copy_project_error"));
          });
        } else {
          copyTaskList(recipientEmailsArray, newTaskListName);
        }
      },
    },
    [],
  );

  function manageTaskMarking(
    tasksTitleToMark: string[],
    tasksTitleToUnmark: string[],
  ) {
    setTaskCollapseState((prevState) =>
      prevState.map((t) => {
        if (tasksTitleToMark.includes(t.task.title)) {
          t.task.isTitleMarked = true;
        } else if (tasksTitleToUnmark.includes(t.task.title)) {
          t.task.isTitleMarked = false;
        }

        return { ...t, task: t.task, open: isListCollapsible };
      }),
    );
  }

  useMakeCopilotActionable(
    {
      name: "manageTasksMarking",
      description: "manage the marking of the provided tasks",
      argumentAnnotations: [
        {
          name: "tasksTitleToMark",
          type: "array",
          items: { type: "string" },
          description: "the titles of the tasks to mark",
          required: true,
        },
        {
          name: "tasksTitleToUnmark",
          type: "array",
          items: { type: "string" },
          description: "the titles of the tasks to unmark",
          required: true,
        },
      ],
      implementation: async (tasksTitleToMark = [], tasksTitleToUnmark = []) =>
        manageTaskMarking(tasksTitleToMark, tasksTitleToUnmark),
    },
    [],
  );

  type sortRule = "alphabetical" | "importance" | "deadline" | "created_at";

  function manageSortTasks(tasksSortRule: string) {
    console.log("Sorting by " + tasksSortRule);

    if (tasksSortRule === "alphabetical") {
      const newSortTasks = tasksWithState
        .sort((a, b) => a.task.title.localeCompare(b.task.title))
        .map((t) => ({ task: t.task, open: t.open }));

      setTaskCollapseState(newSortTasks);
    } else if (tasksSortRule === "importance") {
      const importanceMapping: { [key: string]: number } = {
        high: 0,
        normal: 1,
        low: 2,
      };

      const importanceMapper = (importance: string): number => {
        return importanceMapping[importance];
      };

      const newSortTasks = tasksWithState
        .sort((a, b) => {
          return (
            importanceMapper(a.task.importance.toLowerCase()) -
            importanceMapper(b.task.importance.toLowerCase())
          );
        })
        .map((t) => ({ task: t.task, open: t.open }));

      setTaskCollapseState(newSortTasks);

      console.log(newSortTasks);
    } else if (tasksSortRule === "deadline") {
      const newSortTasks = tasksWithState
        .sort((a, b) =>
          a.task.dueDateTime.dateTime < b.task.dueDateTime.dateTime ? -1 : 1,
        )
        .map((t) => ({ task: t.task, open: t.open }));

      setTaskCollapseState(newSortTasks);
    } else if (tasksSortRule === "created_at") {
      const newSortTasks = tasksWithState
        .sort((a, b) =>
          a.task.createdDateTime < b.task.createdDateTime ? -1 : 1,
        )
        .map((t) => ({ task: t.task, open: t.open }));

      setTaskCollapseState(newSortTasks);
      console.log(newSortTasks);
    } else {
      throw new Error("not Implemented");
    }
  }

  useMakeCopilotActionable(
    {
      name: "manageTasksSort",
      description: "manage the sorting of the provided tasks",
      argumentAnnotations: [
        {
          name: "tasksSortRule",
          type: "string",
          description:
            "ordering scheme for sorting. Valid options: alphabetical, importance, deadline, created_at",
          required: true,
        },
      ],
      implementation: async (tasksSortRule: sortRule = "alphabetical") =>
        manageSortTasks(tasksSortRule),
    },
    [],
  );

  function exportTaskListAsCSV(taskListName: string) {
    const headers = [
      t("csv_header.task"),
      t("csv_header.note"),
      t("csv_header.subtask"),
    ];
    const rows = tasksWithState.map((task) => {
      const note = `"${task.task.note.replace(/"/g, "'")}"`;
      const checklistItems = task.task.checkListItems
        .map((item) => item.displayName)
        .join(",");
      return [task.task.title, note, checklistItems];
    });
    const csvContent = [headers, ...rows]
      .map((row) => row.join(";"))
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${taskListName}.csv`;
    link.click();
  }

  useMakeCopilotActionable(
    {
      name: "exportTaskListAsCSVOrExcel",
      description:
        "export the current task list as a CSV file or as an Excel file",
      argumentAnnotations: [
        {
          name: "taskListName",
          type: "string",
          description: "the name of the task list to export as CSV",
          required: true,
        },
      ],
      implementation: async (taskListName: string) =>
        exportTaskListAsCSV(taskListName),
    },
    [],
  );

  return (
    <Stack data-testid="task-list">
      {props.tasksCollapsible && (
        <Stack
          justifyContent={"flex-end"}
          style={{ position: "absolute", right: 0, top: "-36px" }}
          direction={"row"}
        >
          <Button
            style={{ paddingBottom: "1px" }}
            onClick={() => setIsListCollapsible(!isListCollapsible)}
          >
            {isListCollapsible ? (
              <>
                <UnfoldLess /> {t("view_project.collapse")}
              </>
            ) : (
              <>
                <UnfoldMore /> {t("view_project.expand")}
              </>
            )}
          </Button>
        </Stack>
      )}
      <Grow in={true}>
        <List>
          {tasksWithState.length > 0 ? (
            tasksWithState.map((t, index) => (
              <React.Fragment key={index}>
                <AuthenticatedTemplateWrapper>
                  <TaskListItem
                    showCheckboxes={props.showCkeckboxes}
                    collapsible={props.tasksCollapsible}
                    readonly={props.readonly}
                    task={t.task}
                    open={t.open}
                    extended={props.extended}
                    parentCopilotPointer={copilotPointer}
                    updateIsListCollapsible={updateIsListCollapsible}
                    onTaskStateChange={(newState) =>
                      handleTaskStateChange(index, newState)
                    }
                  />
                </AuthenticatedTemplateWrapper>
                <UnauthenticatedTemplateWrapper>
                  <TaskListItem
                    showCheckboxes={props.showCkeckboxes}
                    collapsible={props.tasksCollapsible}
                    readonly={true}
                    task={t.task}
                    open={t.open}
                    extended={props.extended}
                    parentCopilotPointer={copilotPointer}
                    updateIsListCollapsible={updateIsListCollapsible}
                    onTaskStateChange={(newState) =>
                      handleTaskStateChange(index, newState)
                    }
                  />
                </UnauthenticatedTemplateWrapper>
                <Divider />
              </React.Fragment>
            ))
          ) : (
            <Alert severity="info">
              {t("start_project.task_list_step.alert")}
            </Alert>
          )}
        </List>
      </Grow>
    </Stack>
  );
};

export default TaskList;
